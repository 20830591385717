datagrid-viewer {
  /*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
  /*
bootstrap table theme
*/ }
  datagrid-viewer .mx-1 {
    margin-right: 1px !important;
    margin-left: 1px !important; }
  datagrid-viewer .my-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  datagrid-viewer .mr-1 {
    margin-right: 1px !important; }
  datagrid-viewer .ml-1 {
    margin-left: 1px !important; }
  datagrid-viewer .mb-1 {
    margin-bottom: 1px !important; }
  datagrid-viewer .mt-1 {
    margin-top: 1px !important; }
  datagrid-viewer .m-1 {
    margin: 1px !important; }
  datagrid-viewer .px-1 {
    padding-right: 1px !important;
    padding-left: 1px !important; }
  datagrid-viewer .py-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  datagrid-viewer .pr-1 {
    padding-right: 1px !important; }
  datagrid-viewer .pl-1 {
    padding-left: 1px !important; }
  datagrid-viewer .pb-1 {
    padding-bottom: 1px !important; }
  datagrid-viewer .pt-1 {
    padding-top: 1px !important; }
  datagrid-viewer .p-1 {
    padding: 1px !important; }
  datagrid-viewer .mx-2 {
    margin-right: 2px !important;
    margin-left: 2px !important; }
  datagrid-viewer .my-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  datagrid-viewer .mr-2 {
    margin-right: 2px !important; }
  datagrid-viewer .ml-2 {
    margin-left: 2px !important; }
  datagrid-viewer .mb-2 {
    margin-bottom: 2px !important; }
  datagrid-viewer .mt-2 {
    margin-top: 2px !important; }
  datagrid-viewer .m-2 {
    margin: 2px !important; }
  datagrid-viewer .px-2 {
    padding-right: 2px !important;
    padding-left: 2px !important; }
  datagrid-viewer .py-2 {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  datagrid-viewer .pr-2 {
    padding-right: 2px !important; }
  datagrid-viewer .pl-2 {
    padding-left: 2px !important; }
  datagrid-viewer .pb-2 {
    padding-bottom: 2px !important; }
  datagrid-viewer .pt-2 {
    padding-top: 2px !important; }
  datagrid-viewer .p-2 {
    padding: 2px !important; }
  datagrid-viewer .mx-3 {
    margin-right: 3px !important;
    margin-left: 3px !important; }
  datagrid-viewer .my-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  datagrid-viewer .mr-3 {
    margin-right: 3px !important; }
  datagrid-viewer .ml-3 {
    margin-left: 3px !important; }
  datagrid-viewer .mb-3 {
    margin-bottom: 3px !important; }
  datagrid-viewer .mt-3 {
    margin-top: 3px !important; }
  datagrid-viewer .m-3 {
    margin: 3px !important; }
  datagrid-viewer .px-3 {
    padding-right: 3px !important;
    padding-left: 3px !important; }
  datagrid-viewer .py-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  datagrid-viewer .pr-3 {
    padding-right: 3px !important; }
  datagrid-viewer .pl-3 {
    padding-left: 3px !important; }
  datagrid-viewer .pb-3 {
    padding-bottom: 3px !important; }
  datagrid-viewer .pt-3 {
    padding-top: 3px !important; }
  datagrid-viewer .p-3 {
    padding: 3px !important; }
  datagrid-viewer .mx-4 {
    margin-right: 4px !important;
    margin-left: 4px !important; }
  datagrid-viewer .my-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  datagrid-viewer .mr-4 {
    margin-right: 4px !important; }
  datagrid-viewer .ml-4 {
    margin-left: 4px !important; }
  datagrid-viewer .mb-4 {
    margin-bottom: 4px !important; }
  datagrid-viewer .mt-4 {
    margin-top: 4px !important; }
  datagrid-viewer .m-4 {
    margin: 4px !important; }
  datagrid-viewer .px-4 {
    padding-right: 4px !important;
    padding-left: 4px !important; }
  datagrid-viewer .py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  datagrid-viewer .pr-4 {
    padding-right: 4px !important; }
  datagrid-viewer .pl-4 {
    padding-left: 4px !important; }
  datagrid-viewer .pb-4 {
    padding-bottom: 4px !important; }
  datagrid-viewer .pt-4 {
    padding-top: 4px !important; }
  datagrid-viewer .p-4 {
    padding: 4px !important; }
  datagrid-viewer .mx-5 {
    margin-right: 5px !important;
    margin-left: 5px !important; }
  datagrid-viewer .my-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  datagrid-viewer .mr-5 {
    margin-right: 5px !important; }
  datagrid-viewer .ml-5 {
    margin-left: 5px !important; }
  datagrid-viewer .mb-5 {
    margin-bottom: 5px !important; }
  datagrid-viewer .mt-5 {
    margin-top: 5px !important; }
  datagrid-viewer .m-5 {
    margin: 5px !important; }
  datagrid-viewer .px-5 {
    padding-right: 5px !important;
    padding-left: 5px !important; }
  datagrid-viewer .py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  datagrid-viewer .pr-5 {
    padding-right: 5px !important; }
  datagrid-viewer .pl-5 {
    padding-left: 5px !important; }
  datagrid-viewer .pb-5 {
    padding-bottom: 5px !important; }
  datagrid-viewer .pt-5 {
    padding-top: 5px !important; }
  datagrid-viewer .p-5 {
    padding: 5px !important; }
  datagrid-viewer :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
  datagrid-viewer *,
  datagrid-viewer *::before,
  datagrid-viewer *::after {
    box-sizing: border-box; }
  datagrid-viewer html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  datagrid-viewer article, datagrid-viewer aside, datagrid-viewer figcaption, datagrid-viewer figure, datagrid-viewer footer, datagrid-viewer header, datagrid-viewer hgroup, datagrid-viewer main, datagrid-viewer nav, datagrid-viewer section {
    display: block; }
  datagrid-viewer body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff; }
  datagrid-viewer [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important; }
  datagrid-viewer hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible; }
  datagrid-viewer h1, datagrid-viewer h2, datagrid-viewer h3, datagrid-viewer h4, datagrid-viewer h5, datagrid-viewer h6 {
    margin-top: 0;
    margin-bottom: 0.5rem; }
  datagrid-viewer p {
    margin-top: 0;
    margin-bottom: 1rem; }
  datagrid-viewer abbr[title],
  datagrid-viewer abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none; }
  datagrid-viewer address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  datagrid-viewer ol,
  datagrid-viewer ul,
  datagrid-viewer dl {
    margin-top: 0;
    margin-bottom: 1rem; }
  datagrid-viewer ol ol,
  datagrid-viewer ul ul,
  datagrid-viewer ol ul,
  datagrid-viewer ul ol {
    margin-bottom: 0; }
  datagrid-viewer dt {
    font-weight: 700; }
  datagrid-viewer dd {
    margin-bottom: .5rem;
    margin-left: 0; }
  datagrid-viewer blockquote {
    margin: 0 0 1rem; }
  datagrid-viewer b,
  datagrid-viewer strong {
    font-weight: bolder; }
  datagrid-viewer small {
    font-size: 80%; }
  datagrid-viewer sub,
  datagrid-viewer sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  datagrid-viewer sub {
    bottom: -.25em; }
  datagrid-viewer sup {
    top: -.5em; }
  datagrid-viewer a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent; }
    datagrid-viewer a:hover {
      color: #0056b3;
      text-decoration: underline; }
  datagrid-viewer a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none; }
    datagrid-viewer a:not([href]):not([class]):hover {
      color: inherit;
      text-decoration: none; }
  datagrid-viewer pre,
  datagrid-viewer code,
  datagrid-viewer kbd,
  datagrid-viewer samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }
  datagrid-viewer pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar; }
  datagrid-viewer figure {
    margin: 0 0 1rem; }
  datagrid-viewer img {
    vertical-align: middle;
    border-style: none; }
  datagrid-viewer svg {
    overflow: hidden;
    vertical-align: middle; }
  datagrid-viewer table {
    border-collapse: collapse; }
  datagrid-viewer caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom; }
  datagrid-viewer th {
    text-align: inherit; }
  datagrid-viewer label {
    display: inline-block;
    margin-bottom: 0.5rem; }
  datagrid-viewer button {
    border-radius: 0; }
  datagrid-viewer button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  datagrid-viewer input,
  datagrid-viewer button,
  datagrid-viewer select,
  datagrid-viewer optgroup,
  datagrid-viewer textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  datagrid-viewer button,
  datagrid-viewer input {
    overflow: visible; }
  datagrid-viewer button,
  datagrid-viewer select {
    text-transform: none; }
  datagrid-viewer [role="button"] {
    cursor: pointer; }
  datagrid-viewer select {
    word-wrap: normal; }
  datagrid-viewer button,
  datagrid-viewer [type="button"],
  datagrid-viewer [type="reset"],
  datagrid-viewer [type="submit"] {
    -webkit-appearance: button; }
  datagrid-viewer button:not(:disabled),
  datagrid-viewer [type="button"]:not(:disabled),
  datagrid-viewer [type="reset"]:not(:disabled),
  datagrid-viewer [type="submit"]:not(:disabled) {
    cursor: pointer; }
  datagrid-viewer button::-moz-focus-inner,
  datagrid-viewer [type="button"]::-moz-focus-inner,
  datagrid-viewer [type="reset"]::-moz-focus-inner,
  datagrid-viewer [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  datagrid-viewer input[type="radio"],
  datagrid-viewer input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0; }
  datagrid-viewer textarea {
    overflow: auto;
    resize: vertical; }
  datagrid-viewer fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  datagrid-viewer legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }
  datagrid-viewer progress {
    vertical-align: baseline; }
  datagrid-viewer [type="number"]::-webkit-inner-spin-button,
  datagrid-viewer [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  datagrid-viewer [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  datagrid-viewer [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  datagrid-viewer ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  datagrid-viewer output {
    display: inline-block; }
  datagrid-viewer summary {
    display: list-item;
    cursor: pointer; }
  datagrid-viewer template {
    display: none; }
  datagrid-viewer [hidden] {
    display: none !important; }
  datagrid-viewer h1, datagrid-viewer h2, datagrid-viewer h3, datagrid-viewer h4, datagrid-viewer h5, datagrid-viewer h6,
  datagrid-viewer .h1, datagrid-viewer .h2, datagrid-viewer .h3, datagrid-viewer .h4, datagrid-viewer .h5, datagrid-viewer .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2; }
  datagrid-viewer h1, datagrid-viewer .h1 {
    font-size: 2.5rem; }
  datagrid-viewer h2, datagrid-viewer .h2 {
    font-size: 2rem; }
  datagrid-viewer h3, datagrid-viewer .h3 {
    font-size: 1.75rem; }
  datagrid-viewer h4, datagrid-viewer .h4 {
    font-size: 1.5rem; }
  datagrid-viewer h5, datagrid-viewer .h5 {
    font-size: 1.25rem; }
  datagrid-viewer h6, datagrid-viewer .h6 {
    font-size: 1rem; }
  datagrid-viewer .lead {
    font-size: 1.25rem;
    font-weight: 300; }
  datagrid-viewer .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2; }
  datagrid-viewer .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2; }
  datagrid-viewer .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2; }
  datagrid-viewer .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2; }
  datagrid-viewer hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  datagrid-viewer small,
  datagrid-viewer .small {
    font-size: 80%;
    font-weight: 400; }
  datagrid-viewer mark,
  datagrid-viewer .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  datagrid-viewer .list-unstyled {
    padding-left: 0;
    list-style: none; }
  datagrid-viewer .list-inline {
    padding-left: 0;
    list-style: none; }
  datagrid-viewer .list-inline-item {
    display: inline-block; }
    datagrid-viewer .list-inline-item:not(:last-child) {
      margin-right: 0.5rem; }
  datagrid-viewer .initialism {
    font-size: 90%;
    text-transform: uppercase; }
  datagrid-viewer .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem; }
  datagrid-viewer .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d; }
    datagrid-viewer .blockquote-footer::before {
      content: "\2014\00A0"; }
  datagrid-viewer .img-fluid {
    max-width: 100%;
    height: auto; }
  datagrid-viewer .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto; }
  datagrid-viewer .figure {
    display: inline-block; }
  datagrid-viewer .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }
  datagrid-viewer .figure-caption {
    font-size: 90%;
    color: #6c757d; }
  datagrid-viewer code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word; }
    a > datagrid-viewer code {
      color: inherit; }
  datagrid-viewer kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem; }
    datagrid-viewer kbd kbd {
      padding: 0;
      font-size: 100%;
      font-weight: 700; }
  datagrid-viewer pre {
    display: block;
    font-size: 87.5%;
    color: #212529; }
    datagrid-viewer pre code {
      font-size: inherit;
      color: inherit;
      word-break: normal; }
  datagrid-viewer .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll; }
  datagrid-viewer .container,
  datagrid-viewer .container-fluid,
  datagrid-viewer .container-sm,
  datagrid-viewer .container-md,
  datagrid-viewer .container-lg,
  datagrid-viewer .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    datagrid-viewer .container, datagrid-viewer .container-sm {
      max-width: 540px; } }
  @media (min-width: 768px) {
    datagrid-viewer .container, datagrid-viewer .container-sm, datagrid-viewer .container-md {
      max-width: 720px; } }
  @media (min-width: 992px) {
    datagrid-viewer .container, datagrid-viewer .container-sm, datagrid-viewer .container-md, datagrid-viewer .container-lg {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    datagrid-viewer .container, datagrid-viewer .container-sm, datagrid-viewer .container-md, datagrid-viewer .container-lg, datagrid-viewer .container-xl {
      max-width: 1140px; } }
  datagrid-viewer .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  datagrid-viewer .no-gutters {
    margin-right: 0;
    margin-left: 0; }
    datagrid-viewer .no-gutters > .col,
    datagrid-viewer .no-gutters > [class*="col-"] {
      padding-right: 0;
      padding-left: 0; }
  datagrid-viewer .col-1, datagrid-viewer .col-2, datagrid-viewer .col-3, datagrid-viewer .col-4, datagrid-viewer .col-5, datagrid-viewer .col-6, datagrid-viewer .col-7, datagrid-viewer .col-8, datagrid-viewer .col-9, datagrid-viewer .col-10, datagrid-viewer .col-11, datagrid-viewer .col-12, datagrid-viewer .col,
  datagrid-viewer .col-auto, datagrid-viewer .col-sm-1, datagrid-viewer .col-sm-2, datagrid-viewer .col-sm-3, datagrid-viewer .col-sm-4, datagrid-viewer .col-sm-5, datagrid-viewer .col-sm-6, datagrid-viewer .col-sm-7, datagrid-viewer .col-sm-8, datagrid-viewer .col-sm-9, datagrid-viewer .col-sm-10, datagrid-viewer .col-sm-11, datagrid-viewer .col-sm-12, datagrid-viewer .col-sm,
  datagrid-viewer .col-sm-auto, datagrid-viewer .col-md-1, datagrid-viewer .col-md-2, datagrid-viewer .col-md-3, datagrid-viewer .col-md-4, datagrid-viewer .col-md-5, datagrid-viewer .col-md-6, datagrid-viewer .col-md-7, datagrid-viewer .col-md-8, datagrid-viewer .col-md-9, datagrid-viewer .col-md-10, datagrid-viewer .col-md-11, datagrid-viewer .col-md-12, datagrid-viewer .col-md,
  datagrid-viewer .col-md-auto, datagrid-viewer .col-lg-1, datagrid-viewer .col-lg-2, datagrid-viewer .col-lg-3, datagrid-viewer .col-lg-4, datagrid-viewer .col-lg-5, datagrid-viewer .col-lg-6, datagrid-viewer .col-lg-7, datagrid-viewer .col-lg-8, datagrid-viewer .col-lg-9, datagrid-viewer .col-lg-10, datagrid-viewer .col-lg-11, datagrid-viewer .col-lg-12, datagrid-viewer .col-lg,
  datagrid-viewer .col-lg-auto, datagrid-viewer .col-xl-1, datagrid-viewer .col-xl-2, datagrid-viewer .col-xl-3, datagrid-viewer .col-xl-4, datagrid-viewer .col-xl-5, datagrid-viewer .col-xl-6, datagrid-viewer .col-xl-7, datagrid-viewer .col-xl-8, datagrid-viewer .col-xl-9, datagrid-viewer .col-xl-10, datagrid-viewer .col-xl-11, datagrid-viewer .col-xl-12, datagrid-viewer .col-xl,
  datagrid-viewer .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  datagrid-viewer .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  datagrid-viewer .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  datagrid-viewer .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  datagrid-viewer .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  datagrid-viewer .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  datagrid-viewer .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  datagrid-viewer .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  datagrid-viewer .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  datagrid-viewer .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  datagrid-viewer .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  datagrid-viewer .col-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  datagrid-viewer .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  datagrid-viewer .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  datagrid-viewer .col-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  datagrid-viewer .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  datagrid-viewer .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  datagrid-viewer .col-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  datagrid-viewer .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  datagrid-viewer .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  datagrid-viewer .col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  datagrid-viewer .order-first {
    order: -1; }
  datagrid-viewer .order-last {
    order: 13; }
  datagrid-viewer .order-0 {
    order: 0; }
  datagrid-viewer .order-1 {
    order: 1; }
  datagrid-viewer .order-2 {
    order: 2; }
  datagrid-viewer .order-3 {
    order: 3; }
  datagrid-viewer .order-4 {
    order: 4; }
  datagrid-viewer .order-5 {
    order: 5; }
  datagrid-viewer .order-6 {
    order: 6; }
  datagrid-viewer .order-7 {
    order: 7; }
  datagrid-viewer .order-8 {
    order: 8; }
  datagrid-viewer .order-9 {
    order: 9; }
  datagrid-viewer .order-10 {
    order: 10; }
  datagrid-viewer .order-11 {
    order: 11; }
  datagrid-viewer .order-12 {
    order: 12; }
  datagrid-viewer .offset-1 {
    margin-left: 8.33333%; }
  datagrid-viewer .offset-2 {
    margin-left: 16.66667%; }
  datagrid-viewer .offset-3 {
    margin-left: 25%; }
  datagrid-viewer .offset-4 {
    margin-left: 33.33333%; }
  datagrid-viewer .offset-5 {
    margin-left: 41.66667%; }
  datagrid-viewer .offset-6 {
    margin-left: 50%; }
  datagrid-viewer .offset-7 {
    margin-left: 58.33333%; }
  datagrid-viewer .offset-8 {
    margin-left: 66.66667%; }
  datagrid-viewer .offset-9 {
    margin-left: 75%; }
  datagrid-viewer .offset-10 {
    margin-left: 83.33333%; }
  datagrid-viewer .offset-11 {
    margin-left: 91.66667%; }
  @media (min-width: 576px) {
    datagrid-viewer .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    datagrid-viewer .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    datagrid-viewer .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    datagrid-viewer .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    datagrid-viewer .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    datagrid-viewer .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    datagrid-viewer .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    datagrid-viewer .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    datagrid-viewer .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    datagrid-viewer .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    datagrid-viewer .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    datagrid-viewer .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    datagrid-viewer .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    datagrid-viewer .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    datagrid-viewer .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    datagrid-viewer .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    datagrid-viewer .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    datagrid-viewer .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    datagrid-viewer .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    datagrid-viewer .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    datagrid-viewer .order-sm-first {
      order: -1; }
    datagrid-viewer .order-sm-last {
      order: 13; }
    datagrid-viewer .order-sm-0 {
      order: 0; }
    datagrid-viewer .order-sm-1 {
      order: 1; }
    datagrid-viewer .order-sm-2 {
      order: 2; }
    datagrid-viewer .order-sm-3 {
      order: 3; }
    datagrid-viewer .order-sm-4 {
      order: 4; }
    datagrid-viewer .order-sm-5 {
      order: 5; }
    datagrid-viewer .order-sm-6 {
      order: 6; }
    datagrid-viewer .order-sm-7 {
      order: 7; }
    datagrid-viewer .order-sm-8 {
      order: 8; }
    datagrid-viewer .order-sm-9 {
      order: 9; }
    datagrid-viewer .order-sm-10 {
      order: 10; }
    datagrid-viewer .order-sm-11 {
      order: 11; }
    datagrid-viewer .order-sm-12 {
      order: 12; }
    datagrid-viewer .offset-sm-0 {
      margin-left: 0; }
    datagrid-viewer .offset-sm-1 {
      margin-left: 8.33333%; }
    datagrid-viewer .offset-sm-2 {
      margin-left: 16.66667%; }
    datagrid-viewer .offset-sm-3 {
      margin-left: 25%; }
    datagrid-viewer .offset-sm-4 {
      margin-left: 33.33333%; }
    datagrid-viewer .offset-sm-5 {
      margin-left: 41.66667%; }
    datagrid-viewer .offset-sm-6 {
      margin-left: 50%; }
    datagrid-viewer .offset-sm-7 {
      margin-left: 58.33333%; }
    datagrid-viewer .offset-sm-8 {
      margin-left: 66.66667%; }
    datagrid-viewer .offset-sm-9 {
      margin-left: 75%; }
    datagrid-viewer .offset-sm-10 {
      margin-left: 83.33333%; }
    datagrid-viewer .offset-sm-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 768px) {
    datagrid-viewer .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    datagrid-viewer .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    datagrid-viewer .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    datagrid-viewer .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    datagrid-viewer .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    datagrid-viewer .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    datagrid-viewer .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    datagrid-viewer .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    datagrid-viewer .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    datagrid-viewer .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    datagrid-viewer .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    datagrid-viewer .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    datagrid-viewer .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    datagrid-viewer .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    datagrid-viewer .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    datagrid-viewer .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    datagrid-viewer .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    datagrid-viewer .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    datagrid-viewer .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    datagrid-viewer .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    datagrid-viewer .order-md-first {
      order: -1; }
    datagrid-viewer .order-md-last {
      order: 13; }
    datagrid-viewer .order-md-0 {
      order: 0; }
    datagrid-viewer .order-md-1 {
      order: 1; }
    datagrid-viewer .order-md-2 {
      order: 2; }
    datagrid-viewer .order-md-3 {
      order: 3; }
    datagrid-viewer .order-md-4 {
      order: 4; }
    datagrid-viewer .order-md-5 {
      order: 5; }
    datagrid-viewer .order-md-6 {
      order: 6; }
    datagrid-viewer .order-md-7 {
      order: 7; }
    datagrid-viewer .order-md-8 {
      order: 8; }
    datagrid-viewer .order-md-9 {
      order: 9; }
    datagrid-viewer .order-md-10 {
      order: 10; }
    datagrid-viewer .order-md-11 {
      order: 11; }
    datagrid-viewer .order-md-12 {
      order: 12; }
    datagrid-viewer .offset-md-0 {
      margin-left: 0; }
    datagrid-viewer .offset-md-1 {
      margin-left: 8.33333%; }
    datagrid-viewer .offset-md-2 {
      margin-left: 16.66667%; }
    datagrid-viewer .offset-md-3 {
      margin-left: 25%; }
    datagrid-viewer .offset-md-4 {
      margin-left: 33.33333%; }
    datagrid-viewer .offset-md-5 {
      margin-left: 41.66667%; }
    datagrid-viewer .offset-md-6 {
      margin-left: 50%; }
    datagrid-viewer .offset-md-7 {
      margin-left: 58.33333%; }
    datagrid-viewer .offset-md-8 {
      margin-left: 66.66667%; }
    datagrid-viewer .offset-md-9 {
      margin-left: 75%; }
    datagrid-viewer .offset-md-10 {
      margin-left: 83.33333%; }
    datagrid-viewer .offset-md-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 992px) {
    datagrid-viewer .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    datagrid-viewer .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    datagrid-viewer .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    datagrid-viewer .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    datagrid-viewer .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    datagrid-viewer .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    datagrid-viewer .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    datagrid-viewer .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    datagrid-viewer .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    datagrid-viewer .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    datagrid-viewer .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    datagrid-viewer .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    datagrid-viewer .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    datagrid-viewer .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    datagrid-viewer .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    datagrid-viewer .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    datagrid-viewer .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    datagrid-viewer .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    datagrid-viewer .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    datagrid-viewer .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    datagrid-viewer .order-lg-first {
      order: -1; }
    datagrid-viewer .order-lg-last {
      order: 13; }
    datagrid-viewer .order-lg-0 {
      order: 0; }
    datagrid-viewer .order-lg-1 {
      order: 1; }
    datagrid-viewer .order-lg-2 {
      order: 2; }
    datagrid-viewer .order-lg-3 {
      order: 3; }
    datagrid-viewer .order-lg-4 {
      order: 4; }
    datagrid-viewer .order-lg-5 {
      order: 5; }
    datagrid-viewer .order-lg-6 {
      order: 6; }
    datagrid-viewer .order-lg-7 {
      order: 7; }
    datagrid-viewer .order-lg-8 {
      order: 8; }
    datagrid-viewer .order-lg-9 {
      order: 9; }
    datagrid-viewer .order-lg-10 {
      order: 10; }
    datagrid-viewer .order-lg-11 {
      order: 11; }
    datagrid-viewer .order-lg-12 {
      order: 12; }
    datagrid-viewer .offset-lg-0 {
      margin-left: 0; }
    datagrid-viewer .offset-lg-1 {
      margin-left: 8.33333%; }
    datagrid-viewer .offset-lg-2 {
      margin-left: 16.66667%; }
    datagrid-viewer .offset-lg-3 {
      margin-left: 25%; }
    datagrid-viewer .offset-lg-4 {
      margin-left: 33.33333%; }
    datagrid-viewer .offset-lg-5 {
      margin-left: 41.66667%; }
    datagrid-viewer .offset-lg-6 {
      margin-left: 50%; }
    datagrid-viewer .offset-lg-7 {
      margin-left: 58.33333%; }
    datagrid-viewer .offset-lg-8 {
      margin-left: 66.66667%; }
    datagrid-viewer .offset-lg-9 {
      margin-left: 75%; }
    datagrid-viewer .offset-lg-10 {
      margin-left: 83.33333%; }
    datagrid-viewer .offset-lg-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 1200px) {
    datagrid-viewer .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    datagrid-viewer .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    datagrid-viewer .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    datagrid-viewer .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    datagrid-viewer .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    datagrid-viewer .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    datagrid-viewer .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    datagrid-viewer .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    datagrid-viewer .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    datagrid-viewer .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    datagrid-viewer .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    datagrid-viewer .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    datagrid-viewer .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    datagrid-viewer .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    datagrid-viewer .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    datagrid-viewer .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    datagrid-viewer .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    datagrid-viewer .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    datagrid-viewer .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    datagrid-viewer .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    datagrid-viewer .order-xl-first {
      order: -1; }
    datagrid-viewer .order-xl-last {
      order: 13; }
    datagrid-viewer .order-xl-0 {
      order: 0; }
    datagrid-viewer .order-xl-1 {
      order: 1; }
    datagrid-viewer .order-xl-2 {
      order: 2; }
    datagrid-viewer .order-xl-3 {
      order: 3; }
    datagrid-viewer .order-xl-4 {
      order: 4; }
    datagrid-viewer .order-xl-5 {
      order: 5; }
    datagrid-viewer .order-xl-6 {
      order: 6; }
    datagrid-viewer .order-xl-7 {
      order: 7; }
    datagrid-viewer .order-xl-8 {
      order: 8; }
    datagrid-viewer .order-xl-9 {
      order: 9; }
    datagrid-viewer .order-xl-10 {
      order: 10; }
    datagrid-viewer .order-xl-11 {
      order: 11; }
    datagrid-viewer .order-xl-12 {
      order: 12; }
    datagrid-viewer .offset-xl-0 {
      margin-left: 0; }
    datagrid-viewer .offset-xl-1 {
      margin-left: 8.33333%; }
    datagrid-viewer .offset-xl-2 {
      margin-left: 16.66667%; }
    datagrid-viewer .offset-xl-3 {
      margin-left: 25%; }
    datagrid-viewer .offset-xl-4 {
      margin-left: 33.33333%; }
    datagrid-viewer .offset-xl-5 {
      margin-left: 41.66667%; }
    datagrid-viewer .offset-xl-6 {
      margin-left: 50%; }
    datagrid-viewer .offset-xl-7 {
      margin-left: 58.33333%; }
    datagrid-viewer .offset-xl-8 {
      margin-left: 66.66667%; }
    datagrid-viewer .offset-xl-9 {
      margin-left: 75%; }
    datagrid-viewer .offset-xl-10 {
      margin-left: 83.33333%; }
    datagrid-viewer .offset-xl-11 {
      margin-left: 91.66667%; } }
  datagrid-viewer .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529; }
    datagrid-viewer .table th,
    datagrid-viewer .table td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6; }
    datagrid-viewer .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6; }
    datagrid-viewer .table tbody + tbody {
      border-top: 2px solid #dee2e6; }
  datagrid-viewer .table-sm th,
  datagrid-viewer .table-sm td {
    padding: 0.3rem; }
  datagrid-viewer .table-bordered {
    border: 1px solid #dee2e6; }
    datagrid-viewer .table-bordered th,
    datagrid-viewer .table-bordered td {
      border: 1px solid #dee2e6; }
    datagrid-viewer .table-bordered thead th,
    datagrid-viewer .table-bordered thead td {
      border-bottom-width: 2px; }
  datagrid-viewer .table-borderless th,
  datagrid-viewer .table-borderless td,
  datagrid-viewer .table-borderless thead th,
  datagrid-viewer .table-borderless tbody + tbody {
    border: 0; }
  datagrid-viewer .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }
  datagrid-viewer .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075); }
  datagrid-viewer .table-primary,
  datagrid-viewer .table-primary > th,
  datagrid-viewer .table-primary > td {
    background-color: #b8daff; }
  datagrid-viewer .table-primary th,
  datagrid-viewer .table-primary td,
  datagrid-viewer .table-primary thead th,
  datagrid-viewer .table-primary tbody + tbody {
    border-color: #7abaff; }
  datagrid-viewer .table-hover .table-primary:hover {
    background-color: #9fcdff; }
    datagrid-viewer .table-hover .table-primary:hover > td,
    datagrid-viewer .table-hover .table-primary:hover > th {
      background-color: #9fcdff; }
  datagrid-viewer .table-secondary,
  datagrid-viewer .table-secondary > th,
  datagrid-viewer .table-secondary > td {
    background-color: #d6d8db; }
  datagrid-viewer .table-secondary th,
  datagrid-viewer .table-secondary td,
  datagrid-viewer .table-secondary thead th,
  datagrid-viewer .table-secondary tbody + tbody {
    border-color: #b3b7bb; }
  datagrid-viewer .table-hover .table-secondary:hover {
    background-color: #c8cbcf; }
    datagrid-viewer .table-hover .table-secondary:hover > td,
    datagrid-viewer .table-hover .table-secondary:hover > th {
      background-color: #c8cbcf; }
  datagrid-viewer .table-success,
  datagrid-viewer .table-success > th,
  datagrid-viewer .table-success > td {
    background-color: #c3e6cb; }
  datagrid-viewer .table-success th,
  datagrid-viewer .table-success td,
  datagrid-viewer .table-success thead th,
  datagrid-viewer .table-success tbody + tbody {
    border-color: #8fd19e; }
  datagrid-viewer .table-hover .table-success:hover {
    background-color: #b1dfbb; }
    datagrid-viewer .table-hover .table-success:hover > td,
    datagrid-viewer .table-hover .table-success:hover > th {
      background-color: #b1dfbb; }
  datagrid-viewer .table-info,
  datagrid-viewer .table-info > th,
  datagrid-viewer .table-info > td {
    background-color: #bee5eb; }
  datagrid-viewer .table-info th,
  datagrid-viewer .table-info td,
  datagrid-viewer .table-info thead th,
  datagrid-viewer .table-info tbody + tbody {
    border-color: #86cfda; }
  datagrid-viewer .table-hover .table-info:hover {
    background-color: #abdde5; }
    datagrid-viewer .table-hover .table-info:hover > td,
    datagrid-viewer .table-hover .table-info:hover > th {
      background-color: #abdde5; }
  datagrid-viewer .table-warning,
  datagrid-viewer .table-warning > th,
  datagrid-viewer .table-warning > td {
    background-color: #ffeeba; }
  datagrid-viewer .table-warning th,
  datagrid-viewer .table-warning td,
  datagrid-viewer .table-warning thead th,
  datagrid-viewer .table-warning tbody + tbody {
    border-color: #ffdf7e; }
  datagrid-viewer .table-hover .table-warning:hover {
    background-color: #ffe8a1; }
    datagrid-viewer .table-hover .table-warning:hover > td,
    datagrid-viewer .table-hover .table-warning:hover > th {
      background-color: #ffe8a1; }
  datagrid-viewer .table-danger,
  datagrid-viewer .table-danger > th,
  datagrid-viewer .table-danger > td {
    background-color: #f5c6cb; }
  datagrid-viewer .table-danger th,
  datagrid-viewer .table-danger td,
  datagrid-viewer .table-danger thead th,
  datagrid-viewer .table-danger tbody + tbody {
    border-color: #ed969e; }
  datagrid-viewer .table-hover .table-danger:hover {
    background-color: #f1b0b7; }
    datagrid-viewer .table-hover .table-danger:hover > td,
    datagrid-viewer .table-hover .table-danger:hover > th {
      background-color: #f1b0b7; }
  datagrid-viewer .table-light,
  datagrid-viewer .table-light > th,
  datagrid-viewer .table-light > td {
    background-color: #fdfdfe; }
  datagrid-viewer .table-light th,
  datagrid-viewer .table-light td,
  datagrid-viewer .table-light thead th,
  datagrid-viewer .table-light tbody + tbody {
    border-color: #fbfcfc; }
  datagrid-viewer .table-hover .table-light:hover {
    background-color: #ececf6; }
    datagrid-viewer .table-hover .table-light:hover > td,
    datagrid-viewer .table-hover .table-light:hover > th {
      background-color: #ececf6; }
  datagrid-viewer .table-dark,
  datagrid-viewer .table-dark > th,
  datagrid-viewer .table-dark > td {
    background-color: #c6c8ca; }
  datagrid-viewer .table-dark th,
  datagrid-viewer .table-dark td,
  datagrid-viewer .table-dark thead th,
  datagrid-viewer .table-dark tbody + tbody {
    border-color: #95999c; }
  datagrid-viewer .table-hover .table-dark:hover {
    background-color: #b9bbbe; }
    datagrid-viewer .table-hover .table-dark:hover > td,
    datagrid-viewer .table-hover .table-dark:hover > th {
      background-color: #b9bbbe; }
  datagrid-viewer .table-active,
  datagrid-viewer .table-active > th,
  datagrid-viewer .table-active > td {
    background-color: rgba(0, 0, 0, 0.075); }
  datagrid-viewer .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075); }
    datagrid-viewer .table-hover .table-active:hover > td,
    datagrid-viewer .table-hover .table-active:hover > th {
      background-color: rgba(0, 0, 0, 0.075); }
  datagrid-viewer .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55; }
  datagrid-viewer .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  datagrid-viewer .table-dark {
    color: #fff;
    background-color: #343a40; }
    datagrid-viewer .table-dark th,
    datagrid-viewer .table-dark td,
    datagrid-viewer .table-dark thead th {
      border-color: #454d55; }
    datagrid-viewer .table-dark.table-bordered {
      border: 0; }
    datagrid-viewer .table-dark.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05); }
    datagrid-viewer .table-dark.table-hover tbody tr:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.075); }
  @media (max-width: 575.98px) {
    datagrid-viewer .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      datagrid-viewer .table-responsive-sm > .table-bordered {
        border: 0; } }
  @media (max-width: 767.98px) {
    datagrid-viewer .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      datagrid-viewer .table-responsive-md > .table-bordered {
        border: 0; } }
  @media (max-width: 991.98px) {
    datagrid-viewer .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      datagrid-viewer .table-responsive-lg > .table-bordered {
        border: 0; } }
  @media (max-width: 1199.98px) {
    datagrid-viewer .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      datagrid-viewer .table-responsive-xl > .table-bordered {
        border: 0; } }
  datagrid-viewer .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    datagrid-viewer .table-responsive > .table-bordered {
      border: 0; }
  datagrid-viewer .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .form-control {
        transition: none; } }
    datagrid-viewer .form-control::-ms-expand {
      background-color: transparent;
      border: 0; }
    datagrid-viewer .form-control:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #495057; }
    datagrid-viewer .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    datagrid-viewer .form-control::placeholder {
      color: #6c757d;
      opacity: 1; }
    datagrid-viewer .form-control:disabled, datagrid-viewer .form-control[readonly] {
      background-color: #e9ecef;
      opacity: 1; }
  datagrid-viewer input[type="date"].form-control,
  datagrid-viewer input[type="time"].form-control,
  datagrid-viewer input[type="datetime-local"].form-control,
  datagrid-viewer input[type="month"].form-control {
    appearance: none; }
  datagrid-viewer select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff; }
  datagrid-viewer .form-control-file,
  datagrid-viewer .form-control-range {
    display: block;
    width: 100%; }
  datagrid-viewer .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }
  datagrid-viewer .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5; }
  datagrid-viewer .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5; }
  datagrid-viewer .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
    datagrid-viewer .form-control-plaintext.form-control-sm, datagrid-viewer .form-control-plaintext.form-control-lg {
      padding-right: 0;
      padding-left: 0; }
  datagrid-viewer .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  datagrid-viewer .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  datagrid-viewer select.form-control[size], datagrid-viewer select.form-control[multiple] {
    height: auto; }
  datagrid-viewer textarea.form-control {
    height: auto; }
  datagrid-viewer .form-group {
    margin-bottom: 1rem; }
  datagrid-viewer .form-text {
    display: block;
    margin-top: 0.25rem; }
  datagrid-viewer .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
    datagrid-viewer .form-row > .col,
    datagrid-viewer .form-row > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px; }
  datagrid-viewer .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }
  datagrid-viewer .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
    datagrid-viewer .form-check-input[disabled] ~ .form-check-label,
    datagrid-viewer .form-check-input:disabled ~ .form-check-label {
      color: #6c757d; }
  datagrid-viewer .form-check-label {
    margin-bottom: 0; }
  datagrid-viewer .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
    datagrid-viewer .form-check-inline .form-check-input {
      position: static;
      margin-top: 0;
      margin-right: 0.3125rem;
      margin-left: 0; }
  datagrid-viewer .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745; }
  datagrid-viewer .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem; }
  .was-validated datagrid-viewer:valid ~ .valid-feedback,
  .was-validated datagrid-viewer:valid ~ .valid-tooltip, datagrid-viewer.is-valid ~ .valid-feedback,
  datagrid-viewer.is-valid ~ .valid-tooltip {
    display: block; }
  .was-validated datagrid-viewer .form-control:valid, datagrid-viewer .form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated datagrid-viewer .form-control:valid:focus, datagrid-viewer .form-control.is-valid:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated datagrid-viewer textarea.form-control:valid, datagrid-viewer textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .was-validated datagrid-viewer .custom-select:valid, datagrid-viewer .custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated datagrid-viewer .custom-select:valid:focus, datagrid-viewer .custom-select.is-valid:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated datagrid-viewer .form-check-input:valid ~ .form-check-label, datagrid-viewer .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }
  .was-validated datagrid-viewer .form-check-input:valid ~ .valid-feedback,
  .was-validated datagrid-viewer .form-check-input:valid ~ .valid-tooltip, datagrid-viewer .form-check-input.is-valid ~ .valid-feedback,
  datagrid-viewer .form-check-input.is-valid ~ .valid-tooltip {
    display: block; }
  .was-validated datagrid-viewer .custom-control-input:valid ~ .custom-control-label, datagrid-viewer .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745; }
    .was-validated datagrid-viewer .custom-control-input:valid ~ .custom-control-label::before, datagrid-viewer .custom-control-input.is-valid ~ .custom-control-label::before {
      border-color: #28a745; }
  .was-validated datagrid-viewer .custom-control-input:valid:checked ~ .custom-control-label::before, datagrid-viewer .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57; }
  .was-validated datagrid-viewer .custom-control-input:valid:focus ~ .custom-control-label::before, datagrid-viewer .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated datagrid-viewer .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, datagrid-viewer .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #28a745; }
  .was-validated datagrid-viewer .custom-file-input:valid ~ .custom-file-label, datagrid-viewer .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745; }
  .was-validated datagrid-viewer .custom-file-input:valid:focus ~ .custom-file-label, datagrid-viewer .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  datagrid-viewer .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545; }
  datagrid-viewer .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem; }
  .was-validated datagrid-viewer:invalid ~ .invalid-feedback,
  .was-validated datagrid-viewer:invalid ~ .invalid-tooltip, datagrid-viewer.is-invalid ~ .invalid-feedback,
  datagrid-viewer.is-invalid ~ .invalid-tooltip {
    display: block; }
  .was-validated datagrid-viewer .form-control:invalid, datagrid-viewer .form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated datagrid-viewer .form-control:invalid:focus, datagrid-viewer .form-control.is-invalid:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated datagrid-viewer textarea.form-control:invalid, datagrid-viewer textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .was-validated datagrid-viewer .custom-select:invalid, datagrid-viewer .custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated datagrid-viewer .custom-select:invalid:focus, datagrid-viewer .custom-select.is-invalid:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated datagrid-viewer .form-check-input:invalid ~ .form-check-label, datagrid-viewer .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }
  .was-validated datagrid-viewer .form-check-input:invalid ~ .invalid-feedback,
  .was-validated datagrid-viewer .form-check-input:invalid ~ .invalid-tooltip, datagrid-viewer .form-check-input.is-invalid ~ .invalid-feedback,
  datagrid-viewer .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .was-validated datagrid-viewer .custom-control-input:invalid ~ .custom-control-label, datagrid-viewer .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545; }
    .was-validated datagrid-viewer .custom-control-input:invalid ~ .custom-control-label::before, datagrid-viewer .custom-control-input.is-invalid ~ .custom-control-label::before {
      border-color: #dc3545; }
  .was-validated datagrid-viewer .custom-control-input:invalid:checked ~ .custom-control-label::before, datagrid-viewer .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d; }
  .was-validated datagrid-viewer .custom-control-input:invalid:focus ~ .custom-control-label::before, datagrid-viewer .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated datagrid-viewer .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, datagrid-viewer .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dc3545; }
  .was-validated datagrid-viewer .custom-file-input:invalid ~ .custom-file-label, datagrid-viewer .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545; }
  .was-validated datagrid-viewer .custom-file-input:invalid:focus ~ .custom-file-label, datagrid-viewer .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  datagrid-viewer .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
    datagrid-viewer .form-inline .form-check {
      width: 100%; }
    @media (min-width: 576px) {
      datagrid-viewer .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0; }
      datagrid-viewer .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0; }
      datagrid-viewer .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle; }
      datagrid-viewer .form-inline .form-control-plaintext {
        display: inline-block; }
      datagrid-viewer .form-inline .input-group,
      datagrid-viewer .form-inline .custom-select {
        width: auto; }
      datagrid-viewer .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0; }
      datagrid-viewer .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0; }
      datagrid-viewer .form-inline .custom-control {
        align-items: center;
        justify-content: center; }
      datagrid-viewer .form-inline .custom-control-label {
        margin-bottom: 0; } }
  datagrid-viewer .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .btn {
        transition: none; } }
    datagrid-viewer .btn:hover {
      color: #212529;
      text-decoration: none; }
    datagrid-viewer .btn:focus, datagrid-viewer .btn.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    datagrid-viewer .btn.disabled, datagrid-viewer .btn:disabled {
      opacity: 0.65; }
    datagrid-viewer .btn:not(:disabled):not(.disabled) {
      cursor: pointer; }
  datagrid-viewer a.btn.disabled,
  datagrid-viewer fieldset:disabled a.btn {
    pointer-events: none; }
  datagrid-viewer .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    datagrid-viewer .btn-primary:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc; }
    datagrid-viewer .btn-primary:focus, datagrid-viewer .btn-primary.focus {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
    datagrid-viewer .btn-primary.disabled, datagrid-viewer .btn-primary:disabled {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
    datagrid-viewer .btn-primary:not(:disabled):not(.disabled):active, datagrid-viewer .btn-primary:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf; }
      datagrid-viewer .btn-primary:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  datagrid-viewer .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    datagrid-viewer .btn-secondary:hover {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62; }
    datagrid-viewer .btn-secondary:focus, datagrid-viewer .btn-secondary.focus {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62;
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
    datagrid-viewer .btn-secondary.disabled, datagrid-viewer .btn-secondary:disabled {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
    datagrid-viewer .btn-secondary:not(:disabled):not(.disabled):active, datagrid-viewer .btn-secondary:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-secondary.dropdown-toggle {
      color: #fff;
      background-color: #545b62;
      border-color: #4e555b; }
      datagrid-viewer .btn-secondary:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-secondary:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  datagrid-viewer .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    datagrid-viewer .btn-success:hover {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34; }
    datagrid-viewer .btn-success:focus, datagrid-viewer .btn-success.focus {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34;
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
    datagrid-viewer .btn-success.disabled, datagrid-viewer .btn-success:disabled {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
    datagrid-viewer .btn-success:not(:disabled):not(.disabled):active, datagrid-viewer .btn-success:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-success.dropdown-toggle {
      color: #fff;
      background-color: #1e7e34;
      border-color: #1c7430; }
      datagrid-viewer .btn-success:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-success:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  datagrid-viewer .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    datagrid-viewer .btn-info:hover {
      color: #fff;
      background-color: #138496;
      border-color: #117a8b; }
    datagrid-viewer .btn-info:focus, datagrid-viewer .btn-info.focus {
      color: #fff;
      background-color: #138496;
      border-color: #117a8b;
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
    datagrid-viewer .btn-info.disabled, datagrid-viewer .btn-info:disabled {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8; }
    datagrid-viewer .btn-info:not(:disabled):not(.disabled):active, datagrid-viewer .btn-info:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-info.dropdown-toggle {
      color: #fff;
      background-color: #117a8b;
      border-color: #10707f; }
      datagrid-viewer .btn-info:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-info:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  datagrid-viewer .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    datagrid-viewer .btn-warning:hover {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00; }
    datagrid-viewer .btn-warning:focus, datagrid-viewer .btn-warning.focus {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00;
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
    datagrid-viewer .btn-warning.disabled, datagrid-viewer .btn-warning:disabled {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
    datagrid-viewer .btn-warning:not(:disabled):not(.disabled):active, datagrid-viewer .btn-warning:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-warning.dropdown-toggle {
      color: #212529;
      background-color: #d39e00;
      border-color: #c69500; }
      datagrid-viewer .btn-warning:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-warning:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  datagrid-viewer .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    datagrid-viewer .btn-danger:hover {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130; }
    datagrid-viewer .btn-danger:focus, datagrid-viewer .btn-danger.focus {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
    datagrid-viewer .btn-danger.disabled, datagrid-viewer .btn-danger:disabled {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545; }
    datagrid-viewer .btn-danger:not(:disabled):not(.disabled):active, datagrid-viewer .btn-danger:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-danger.dropdown-toggle {
      color: #fff;
      background-color: #bd2130;
      border-color: #b21f2d; }
      datagrid-viewer .btn-danger:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-danger:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  datagrid-viewer .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    datagrid-viewer .btn-light:hover {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5; }
    datagrid-viewer .btn-light:focus, datagrid-viewer .btn-light.focus {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5;
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
    datagrid-viewer .btn-light.disabled, datagrid-viewer .btn-light:disabled {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    datagrid-viewer .btn-light:not(:disabled):not(.disabled):active, datagrid-viewer .btn-light:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-light.dropdown-toggle {
      color: #212529;
      background-color: #dae0e5;
      border-color: #d3d9df; }
      datagrid-viewer .btn-light:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-light:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  datagrid-viewer .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    datagrid-viewer .btn-dark:hover {
      color: #fff;
      background-color: #23272b;
      border-color: #1d2124; }
    datagrid-viewer .btn-dark:focus, datagrid-viewer .btn-dark.focus {
      color: #fff;
      background-color: #23272b;
      border-color: #1d2124;
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
    datagrid-viewer .btn-dark.disabled, datagrid-viewer .btn-dark:disabled {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
    datagrid-viewer .btn-dark:not(:disabled):not(.disabled):active, datagrid-viewer .btn-dark:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-dark.dropdown-toggle {
      color: #fff;
      background-color: #1d2124;
      border-color: #171a1d; }
      datagrid-viewer .btn-dark:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-dark:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  datagrid-viewer .btn-outline-primary {
    color: #007bff;
    border-color: #007bff; }
    datagrid-viewer .btn-outline-primary:hover {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
    datagrid-viewer .btn-outline-primary:focus, datagrid-viewer .btn-outline-primary.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
    datagrid-viewer .btn-outline-primary.disabled, datagrid-viewer .btn-outline-primary:disabled {
      color: #007bff;
      background-color: transparent; }
    datagrid-viewer .btn-outline-primary:not(:disabled):not(.disabled):active, datagrid-viewer .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-outline-primary.dropdown-toggle {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
      datagrid-viewer .btn-outline-primary:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-outline-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  datagrid-viewer .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d; }
    datagrid-viewer .btn-outline-secondary:hover {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
    datagrid-viewer .btn-outline-secondary:focus, datagrid-viewer .btn-outline-secondary.focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
    datagrid-viewer .btn-outline-secondary.disabled, datagrid-viewer .btn-outline-secondary:disabled {
      color: #6c757d;
      background-color: transparent; }
    datagrid-viewer .btn-outline-secondary:not(:disabled):not(.disabled):active, datagrid-viewer .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-outline-secondary.dropdown-toggle {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
      datagrid-viewer .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-outline-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  datagrid-viewer .btn-outline-success {
    color: #28a745;
    border-color: #28a745; }
    datagrid-viewer .btn-outline-success:hover {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
    datagrid-viewer .btn-outline-success:focus, datagrid-viewer .btn-outline-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
    datagrid-viewer .btn-outline-success.disabled, datagrid-viewer .btn-outline-success:disabled {
      color: #28a745;
      background-color: transparent; }
    datagrid-viewer .btn-outline-success:not(:disabled):not(.disabled):active, datagrid-viewer .btn-outline-success:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-outline-success.dropdown-toggle {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
      datagrid-viewer .btn-outline-success:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-outline-success:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-outline-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  datagrid-viewer .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8; }
    datagrid-viewer .btn-outline-info:hover {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8; }
    datagrid-viewer .btn-outline-info:focus, datagrid-viewer .btn-outline-info.focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
    datagrid-viewer .btn-outline-info.disabled, datagrid-viewer .btn-outline-info:disabled {
      color: #17a2b8;
      background-color: transparent; }
    datagrid-viewer .btn-outline-info:not(:disabled):not(.disabled):active, datagrid-viewer .btn-outline-info:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-outline-info.dropdown-toggle {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8; }
      datagrid-viewer .btn-outline-info:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-outline-info:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-outline-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  datagrid-viewer .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107; }
    datagrid-viewer .btn-outline-warning:hover {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
    datagrid-viewer .btn-outline-warning:focus, datagrid-viewer .btn-outline-warning.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
    datagrid-viewer .btn-outline-warning.disabled, datagrid-viewer .btn-outline-warning:disabled {
      color: #ffc107;
      background-color: transparent; }
    datagrid-viewer .btn-outline-warning:not(:disabled):not(.disabled):active, datagrid-viewer .btn-outline-warning:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-outline-warning.dropdown-toggle {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
      datagrid-viewer .btn-outline-warning:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-outline-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  datagrid-viewer .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545; }
    datagrid-viewer .btn-outline-danger:hover {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545; }
    datagrid-viewer .btn-outline-danger:focus, datagrid-viewer .btn-outline-danger.focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
    datagrid-viewer .btn-outline-danger.disabled, datagrid-viewer .btn-outline-danger:disabled {
      color: #dc3545;
      background-color: transparent; }
    datagrid-viewer .btn-outline-danger:not(:disabled):not(.disabled):active, datagrid-viewer .btn-outline-danger:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-outline-danger.dropdown-toggle {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545; }
      datagrid-viewer .btn-outline-danger:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-outline-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  datagrid-viewer .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa; }
    datagrid-viewer .btn-outline-light:hover {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    datagrid-viewer .btn-outline-light:focus, datagrid-viewer .btn-outline-light.focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
    datagrid-viewer .btn-outline-light.disabled, datagrid-viewer .btn-outline-light:disabled {
      color: #f8f9fa;
      background-color: transparent; }
    datagrid-viewer .btn-outline-light:not(:disabled):not(.disabled):active, datagrid-viewer .btn-outline-light:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-outline-light.dropdown-toggle {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
      datagrid-viewer .btn-outline-light:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-outline-light:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-outline-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  datagrid-viewer .btn-outline-dark {
    color: #343a40;
    border-color: #343a40; }
    datagrid-viewer .btn-outline-dark:hover {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
    datagrid-viewer .btn-outline-dark:focus, datagrid-viewer .btn-outline-dark.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
    datagrid-viewer .btn-outline-dark.disabled, datagrid-viewer .btn-outline-dark:disabled {
      color: #343a40;
      background-color: transparent; }
    datagrid-viewer .btn-outline-dark:not(:disabled):not(.disabled):active, datagrid-viewer .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > datagrid-viewer .btn-outline-dark.dropdown-toggle {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
      datagrid-viewer .btn-outline-dark:not(:disabled):not(.disabled):active:focus, datagrid-viewer .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
      .show > datagrid-viewer .btn-outline-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  datagrid-viewer .btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none; }
    datagrid-viewer .btn-link:hover {
      color: #0056b3;
      text-decoration: underline; }
    datagrid-viewer .btn-link:focus, datagrid-viewer .btn-link.focus {
      text-decoration: underline; }
    datagrid-viewer .btn-link:disabled, datagrid-viewer .btn-link.disabled {
      color: #6c757d;
      pointer-events: none; }
  datagrid-viewer .btn-lg, datagrid-viewer .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  datagrid-viewer .btn-sm, datagrid-viewer .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  datagrid-viewer .btn-block {
    display: block;
    width: 100%; }
    datagrid-viewer .btn-block + .btn-block {
      margin-top: 0.5rem; }
  datagrid-viewer input[type="submit"].btn-block,
  datagrid-viewer input[type="reset"].btn-block,
  datagrid-viewer input[type="button"].btn-block {
    width: 100%; }
  datagrid-viewer .fade {
    transition: opacity 0.15s linear; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .fade {
        transition: none; } }
    datagrid-viewer .fade:not(.show) {
      opacity: 0; }
  datagrid-viewer .collapse:not(.show) {
    display: none; }
  datagrid-viewer .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .collapsing {
        transition: none; } }
  datagrid-viewer .dropup,
  datagrid-viewer .dropright,
  datagrid-viewer .dropdown,
  datagrid-viewer .dropleft {
    position: relative; }
  datagrid-viewer .dropdown-toggle {
    white-space: nowrap; }
    datagrid-viewer .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent; }
    datagrid-viewer .dropdown-toggle:empty::after {
      margin-left: 0; }
  datagrid-viewer .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
  datagrid-viewer .dropdown-menu-left {
    right: auto;
    left: 0; }
  datagrid-viewer .dropdown-menu-right {
    right: 0;
    left: auto; }
  @media (min-width: 576px) {
    datagrid-viewer .dropdown-menu-sm-left {
      right: auto;
      left: 0; }
    datagrid-viewer .dropdown-menu-sm-right {
      right: 0;
      left: auto; } }
  @media (min-width: 768px) {
    datagrid-viewer .dropdown-menu-md-left {
      right: auto;
      left: 0; }
    datagrid-viewer .dropdown-menu-md-right {
      right: 0;
      left: auto; } }
  @media (min-width: 992px) {
    datagrid-viewer .dropdown-menu-lg-left {
      right: auto;
      left: 0; }
    datagrid-viewer .dropdown-menu-lg-right {
      right: 0;
      left: auto; } }
  @media (min-width: 1200px) {
    datagrid-viewer .dropdown-menu-xl-left {
      right: auto;
      left: 0; }
    datagrid-viewer .dropdown-menu-xl-right {
      right: 0;
      left: auto; } }
  datagrid-viewer .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem; }
  datagrid-viewer .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  datagrid-viewer .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  datagrid-viewer .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem; }
  datagrid-viewer .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  datagrid-viewer .dropright .dropdown-toggle:empty::after {
    margin-left: 0; }
  datagrid-viewer .dropright .dropdown-toggle::after {
    vertical-align: 0; }
  datagrid-viewer .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem; }
  datagrid-viewer .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  datagrid-viewer .dropleft .dropdown-toggle::after {
    display: none; }
  datagrid-viewer .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  datagrid-viewer .dropleft .dropdown-toggle:empty::after {
    margin-left: 0; }
  datagrid-viewer .dropleft .dropdown-toggle::before {
    vertical-align: 0; }
  datagrid-viewer .dropdown-menu[x-placement^="top"], datagrid-viewer .dropdown-menu[x-placement^="right"], datagrid-viewer .dropdown-menu[x-placement^="bottom"], datagrid-viewer .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto; }
  datagrid-viewer .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef; }
  datagrid-viewer .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
    datagrid-viewer .dropdown-item:hover, datagrid-viewer .dropdown-item:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #f8f9fa; }
    datagrid-viewer .dropdown-item.active, datagrid-viewer .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: #007bff; }
    datagrid-viewer .dropdown-item.disabled, datagrid-viewer .dropdown-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: transparent; }
  datagrid-viewer .dropdown-menu.show {
    display: block; }
  datagrid-viewer .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap; }
  datagrid-viewer .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529; }
  datagrid-viewer .btn-group,
  datagrid-viewer .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; }
    datagrid-viewer .btn-group > .btn,
    datagrid-viewer .btn-group-vertical > .btn {
      position: relative;
      flex: 1 1 auto; }
      datagrid-viewer .btn-group > .btn:hover,
      datagrid-viewer .btn-group-vertical > .btn:hover {
        z-index: 1; }
      datagrid-viewer .btn-group > .btn:focus, datagrid-viewer .btn-group > .btn:active, datagrid-viewer .btn-group > .btn.active,
      datagrid-viewer .btn-group-vertical > .btn:focus,
      datagrid-viewer .btn-group-vertical > .btn:active,
      datagrid-viewer .btn-group-vertical > .btn.active {
        z-index: 1; }
  datagrid-viewer .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    datagrid-viewer .btn-toolbar .input-group {
      width: auto; }
  datagrid-viewer .btn-group > .btn:not(:first-child),
  datagrid-viewer .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  datagrid-viewer .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  datagrid-viewer .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  datagrid-viewer .btn-group > .btn:not(:first-child),
  datagrid-viewer .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  datagrid-viewer .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem; }
    datagrid-viewer .dropdown-toggle-split::after,
    .dropup datagrid-viewer .dropdown-toggle-split::after,
    .dropright datagrid-viewer .dropdown-toggle-split::after {
      margin-left: 0; }
    .dropleft datagrid-viewer .dropdown-toggle-split::before {
      margin-right: 0; }
  datagrid-viewer .btn-sm + .dropdown-toggle-split, datagrid-viewer .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem; }
  datagrid-viewer .btn-lg + .dropdown-toggle-split, datagrid-viewer .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  datagrid-viewer .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    datagrid-viewer .btn-group-vertical > .btn,
    datagrid-viewer .btn-group-vertical > .btn-group {
      width: 100%; }
    datagrid-viewer .btn-group-vertical > .btn:not(:first-child),
    datagrid-viewer .btn-group-vertical > .btn-group:not(:first-child) {
      margin-top: -1px; }
    datagrid-viewer .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
    datagrid-viewer .btn-group-vertical > .btn-group:not(:last-child) > .btn {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    datagrid-viewer .btn-group-vertical > .btn:not(:first-child),
    datagrid-viewer .btn-group-vertical > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  datagrid-viewer .btn-group-toggle > .btn,
  datagrid-viewer .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0; }
    datagrid-viewer .btn-group-toggle > .btn input[type="radio"],
    datagrid-viewer .btn-group-toggle > .btn input[type="checkbox"],
    datagrid-viewer .btn-group-toggle > .btn-group > .btn input[type="radio"],
    datagrid-viewer .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none; }
  datagrid-viewer .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
    datagrid-viewer .input-group > .form-control,
    datagrid-viewer .input-group > .form-control-plaintext,
    datagrid-viewer .input-group > .custom-select,
    datagrid-viewer .input-group > .custom-file {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0; }
      datagrid-viewer .input-group > .form-control + .form-control,
      datagrid-viewer .input-group > .form-control + .custom-select,
      datagrid-viewer .input-group > .form-control + .custom-file,
      datagrid-viewer .input-group > .form-control-plaintext + .form-control,
      datagrid-viewer .input-group > .form-control-plaintext + .custom-select,
      datagrid-viewer .input-group > .form-control-plaintext + .custom-file,
      datagrid-viewer .input-group > .custom-select + .form-control,
      datagrid-viewer .input-group > .custom-select + .custom-select,
      datagrid-viewer .input-group > .custom-select + .custom-file,
      datagrid-viewer .input-group > .custom-file + .form-control,
      datagrid-viewer .input-group > .custom-file + .custom-select,
      datagrid-viewer .input-group > .custom-file + .custom-file {
        margin-left: -1px; }
    datagrid-viewer .input-group > .form-control:focus,
    datagrid-viewer .input-group > .custom-select:focus,
    datagrid-viewer .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
      z-index: 3; }
    datagrid-viewer .input-group > .custom-file .custom-file-input:focus {
      z-index: 4; }
    datagrid-viewer .input-group > .form-control:not(:last-child),
    datagrid-viewer .input-group > .custom-select:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    datagrid-viewer .input-group > .form-control:not(:first-child),
    datagrid-viewer .input-group > .custom-select:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    datagrid-viewer .input-group > .custom-file {
      display: flex;
      align-items: center; }
      datagrid-viewer .input-group > .custom-file:not(:last-child) .custom-file-label,
      datagrid-viewer .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      datagrid-viewer .input-group > .custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
  datagrid-viewer .input-group-prepend,
  datagrid-viewer .input-group-append {
    display: flex; }
    datagrid-viewer .input-group-prepend .btn,
    datagrid-viewer .input-group-append .btn {
      position: relative;
      z-index: 2; }
      datagrid-viewer .input-group-prepend .btn:focus,
      datagrid-viewer .input-group-append .btn:focus {
        z-index: 3; }
    datagrid-viewer .input-group-prepend .btn + .btn,
    datagrid-viewer .input-group-prepend .btn + .input-group-text,
    datagrid-viewer .input-group-prepend .input-group-text + .input-group-text,
    datagrid-viewer .input-group-prepend .input-group-text + .btn,
    datagrid-viewer .input-group-append .btn + .btn,
    datagrid-viewer .input-group-append .btn + .input-group-text,
    datagrid-viewer .input-group-append .input-group-text + .input-group-text,
    datagrid-viewer .input-group-append .input-group-text + .btn {
      margin-left: -1px; }
  datagrid-viewer .input-group-prepend {
    margin-right: -1px; }
  datagrid-viewer .input-group-append {
    margin-left: -1px; }
  datagrid-viewer .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
    datagrid-viewer .input-group-text input[type="radio"],
    datagrid-viewer .input-group-text input[type="checkbox"] {
      margin-top: 0; }
  datagrid-viewer .input-group-lg > .form-control:not(textarea),
  datagrid-viewer .input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px); }
  datagrid-viewer .input-group-lg > .form-control,
  datagrid-viewer .input-group-lg > .custom-select,
  datagrid-viewer .input-group-lg > .input-group-prepend > .input-group-text,
  datagrid-viewer .input-group-lg > .input-group-append > .input-group-text,
  datagrid-viewer .input-group-lg > .input-group-prepend > .btn,
  datagrid-viewer .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  datagrid-viewer .input-group-sm > .form-control:not(textarea),
  datagrid-viewer .input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px); }
  datagrid-viewer .input-group-sm > .form-control,
  datagrid-viewer .input-group-sm > .custom-select,
  datagrid-viewer .input-group-sm > .input-group-prepend > .input-group-text,
  datagrid-viewer .input-group-sm > .input-group-append > .input-group-text,
  datagrid-viewer .input-group-sm > .input-group-prepend > .btn,
  datagrid-viewer .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  datagrid-viewer .input-group-lg > .custom-select,
  datagrid-viewer .input-group-sm > .custom-select {
    padding-right: 1.75rem; }
  datagrid-viewer .input-group > .input-group-prepend > .btn,
  datagrid-viewer .input-group > .input-group-prepend > .input-group-text,
  datagrid-viewer .input-group > .input-group-append:not(:last-child) > .btn,
  datagrid-viewer .input-group > .input-group-append:not(:last-child) > .input-group-text,
  datagrid-viewer .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  datagrid-viewer .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  datagrid-viewer .input-group > .input-group-append > .btn,
  datagrid-viewer .input-group > .input-group-append > .input-group-text,
  datagrid-viewer .input-group > .input-group-prepend:not(:first-child) > .btn,
  datagrid-viewer .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  datagrid-viewer .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  datagrid-viewer .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  datagrid-viewer .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem; }
  datagrid-viewer .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem; }
  datagrid-viewer .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0; }
    datagrid-viewer .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #007bff;
      background-color: #007bff; }
    datagrid-viewer .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    datagrid-viewer .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #80bdff; }
    datagrid-viewer .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      color: #fff;
      background-color: #b3d7ff;
      border-color: #b3d7ff; }
    datagrid-viewer .custom-control-input[disabled] ~ .custom-control-label, datagrid-viewer .custom-control-input:disabled ~ .custom-control-label {
      color: #6c757d; }
      datagrid-viewer .custom-control-input[disabled] ~ .custom-control-label::before, datagrid-viewer .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #e9ecef; }
  datagrid-viewer .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top; }
    datagrid-viewer .custom-control-label::before {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      background-color: #fff;
      border: #adb5bd solid 1px; }
    datagrid-viewer .custom-control-label::after {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: no-repeat 50% / 50% 50%; }
  datagrid-viewer .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem; }
  datagrid-viewer .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }
  datagrid-viewer .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff; }
  datagrid-viewer .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
  datagrid-viewer .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  datagrid-viewer .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  datagrid-viewer .custom-radio .custom-control-label::before {
    border-radius: 50%; }
  datagrid-viewer .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  datagrid-viewer .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  datagrid-viewer .custom-switch {
    padding-left: 2.25rem; }
    datagrid-viewer .custom-switch .custom-control-label::before {
      left: -2.25rem;
      width: 1.75rem;
      pointer-events: all;
      border-radius: 0.5rem; }
    datagrid-viewer .custom-switch .custom-control-label::after {
      top: calc(0.25rem + 2px);
      left: calc(-2.25rem + 2px);
      width: calc(1rem - 4px);
      height: calc(1rem - 4px);
      background-color: #adb5bd;
      border-radius: 0.5rem;
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        datagrid-viewer .custom-switch .custom-control-label::after {
          transition: none; } }
    datagrid-viewer .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #fff;
      transform: translateX(0.75rem); }
    datagrid-viewer .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5); }
  datagrid-viewer .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none; }
    datagrid-viewer .custom-select:focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      datagrid-viewer .custom-select:focus::-ms-value {
        color: #495057;
        background-color: #fff; }
    datagrid-viewer .custom-select[multiple], datagrid-viewer .custom-select[size]:not([size="1"]) {
      height: auto;
      padding-right: 0.75rem;
      background-image: none; }
    datagrid-viewer .custom-select:disabled {
      color: #6c757d;
      background-color: #e9ecef; }
    datagrid-viewer .custom-select::-ms-expand {
      display: none; }
    datagrid-viewer .custom-select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #495057; }
  datagrid-viewer .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem; }
  datagrid-viewer .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem; }
  datagrid-viewer .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0; }
  datagrid-viewer .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0; }
    datagrid-viewer .custom-file-input:focus ~ .custom-file-label {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    datagrid-viewer .custom-file-input[disabled] ~ .custom-file-label,
    datagrid-viewer .custom-file-input:disabled ~ .custom-file-label {
      background-color: #e9ecef; }
    datagrid-viewer .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: "Browse"; }
    datagrid-viewer .custom-file-input ~ .custom-file-label[data-browse]::after {
      content: attr(data-browse); }
  datagrid-viewer .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
    datagrid-viewer .custom-file-label::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      height: calc(1.5em + 0.75rem);
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      color: #495057;
      content: "Browse";
      background-color: #e9ecef;
      border-left: inherit;
      border-radius: 0 0.25rem 0.25rem 0; }
  datagrid-viewer .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none; }
    datagrid-viewer .custom-range:focus {
      outline: none; }
      datagrid-viewer .custom-range:focus::-webkit-slider-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      datagrid-viewer .custom-range:focus::-moz-range-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      datagrid-viewer .custom-range:focus::-ms-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    datagrid-viewer .custom-range::-moz-focus-outer {
      border: 0; }
    datagrid-viewer .custom-range::-webkit-slider-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: -0.25rem;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        datagrid-viewer .custom-range::-webkit-slider-thumb {
          transition: none; } }
      datagrid-viewer .custom-range::-webkit-slider-thumb:active {
        background-color: #b3d7ff; }
    datagrid-viewer .custom-range::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem; }
    datagrid-viewer .custom-range::-moz-range-thumb {
      width: 1rem;
      height: 1rem;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        datagrid-viewer .custom-range::-moz-range-thumb {
          transition: none; } }
      datagrid-viewer .custom-range::-moz-range-thumb:active {
        background-color: #b3d7ff; }
    datagrid-viewer .custom-range::-moz-range-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem; }
    datagrid-viewer .custom-range::-ms-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: 0;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        datagrid-viewer .custom-range::-ms-thumb {
          transition: none; } }
      datagrid-viewer .custom-range::-ms-thumb:active {
        background-color: #b3d7ff; }
    datagrid-viewer .custom-range::-ms-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: transparent;
      border-color: transparent;
      border-width: 0.5rem; }
    datagrid-viewer .custom-range::-ms-fill-lower {
      background-color: #dee2e6;
      border-radius: 1rem; }
    datagrid-viewer .custom-range::-ms-fill-upper {
      margin-right: 15px;
      background-color: #dee2e6;
      border-radius: 1rem; }
    datagrid-viewer .custom-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    datagrid-viewer .custom-range:disabled::-webkit-slider-runnable-track {
      cursor: default; }
    datagrid-viewer .custom-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }
    datagrid-viewer .custom-range:disabled::-moz-range-track {
      cursor: default; }
    datagrid-viewer .custom-range:disabled::-ms-thumb {
      background-color: #adb5bd; }
  datagrid-viewer .custom-control-label::before,
  datagrid-viewer .custom-file-label,
  datagrid-viewer .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .custom-control-label::before,
      datagrid-viewer .custom-file-label,
      datagrid-viewer .custom-select {
        transition: none; } }
  datagrid-viewer .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  datagrid-viewer .nav-link {
    display: block;
    padding: 0.5rem 1rem; }
    datagrid-viewer .nav-link:hover, datagrid-viewer .nav-link:focus {
      text-decoration: none; }
    datagrid-viewer .nav-link.disabled {
      color: #6c757d;
      pointer-events: none;
      cursor: default; }
  datagrid-viewer .nav-tabs {
    border-bottom: 1px solid #dee2e6; }
    datagrid-viewer .nav-tabs .nav-item {
      margin-bottom: -1px; }
    datagrid-viewer .nav-tabs .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
      datagrid-viewer .nav-tabs .nav-link:hover, datagrid-viewer .nav-tabs .nav-link:focus {
        border-color: #e9ecef #e9ecef #dee2e6; }
      datagrid-viewer .nav-tabs .nav-link.disabled {
        color: #6c757d;
        background-color: transparent;
        border-color: transparent; }
    datagrid-viewer .nav-tabs .nav-link.active,
    datagrid-viewer .nav-tabs .nav-item.show .nav-link {
      color: #495057;
      background-color: #fff;
      border-color: #dee2e6 #dee2e6 #fff; }
    datagrid-viewer .nav-tabs .dropdown-menu {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  datagrid-viewer .nav-pills .nav-link {
    border-radius: 0.25rem; }
  datagrid-viewer .nav-pills .nav-link.active,
  datagrid-viewer .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff; }
  datagrid-viewer .nav-fill > .nav-link,
  datagrid-viewer .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center; }
  datagrid-viewer .nav-justified > .nav-link,
  datagrid-viewer .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center; }
  datagrid-viewer .tab-content > .tab-pane {
    display: none; }
  datagrid-viewer .tab-content > .active {
    display: block; }
  datagrid-viewer .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
    datagrid-viewer .navbar .container,
    datagrid-viewer .navbar .container-fluid, datagrid-viewer .navbar .container-sm, datagrid-viewer .navbar .container-md, datagrid-viewer .navbar .container-lg, datagrid-viewer .navbar .container-xl {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; }
  datagrid-viewer .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }
    datagrid-viewer .navbar-brand:hover, datagrid-viewer .navbar-brand:focus {
      text-decoration: none; }
  datagrid-viewer .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    datagrid-viewer .navbar-nav .nav-link {
      padding-right: 0;
      padding-left: 0; }
    datagrid-viewer .navbar-nav .dropdown-menu {
      position: static;
      float: none; }
  datagrid-viewer .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  datagrid-viewer .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center; }
  datagrid-viewer .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
    datagrid-viewer .navbar-toggler:hover, datagrid-viewer .navbar-toggler:focus {
      text-decoration: none; }
  datagrid-viewer .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }
  @media (max-width: 575.98px) {
    datagrid-viewer .navbar-expand-sm > .container,
    datagrid-viewer .navbar-expand-sm > .container-fluid, datagrid-viewer .navbar-expand-sm > .container-sm, datagrid-viewer .navbar-expand-sm > .container-md, datagrid-viewer .navbar-expand-sm > .container-lg, datagrid-viewer .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 576px) {
    datagrid-viewer .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      datagrid-viewer .navbar-expand-sm .navbar-nav {
        flex-direction: row; }
        datagrid-viewer .navbar-expand-sm .navbar-nav .dropdown-menu {
          position: absolute; }
        datagrid-viewer .navbar-expand-sm .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      datagrid-viewer .navbar-expand-sm > .container,
      datagrid-viewer .navbar-expand-sm > .container-fluid, datagrid-viewer .navbar-expand-sm > .container-sm, datagrid-viewer .navbar-expand-sm > .container-md, datagrid-viewer .navbar-expand-sm > .container-lg, datagrid-viewer .navbar-expand-sm > .container-xl {
        flex-wrap: nowrap; }
      datagrid-viewer .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      datagrid-viewer .navbar-expand-sm .navbar-toggler {
        display: none; } }
  @media (max-width: 767.98px) {
    datagrid-viewer .navbar-expand-md > .container,
    datagrid-viewer .navbar-expand-md > .container-fluid, datagrid-viewer .navbar-expand-md > .container-sm, datagrid-viewer .navbar-expand-md > .container-md, datagrid-viewer .navbar-expand-md > .container-lg, datagrid-viewer .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 768px) {
    datagrid-viewer .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      datagrid-viewer .navbar-expand-md .navbar-nav {
        flex-direction: row; }
        datagrid-viewer .navbar-expand-md .navbar-nav .dropdown-menu {
          position: absolute; }
        datagrid-viewer .navbar-expand-md .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      datagrid-viewer .navbar-expand-md > .container,
      datagrid-viewer .navbar-expand-md > .container-fluid, datagrid-viewer .navbar-expand-md > .container-sm, datagrid-viewer .navbar-expand-md > .container-md, datagrid-viewer .navbar-expand-md > .container-lg, datagrid-viewer .navbar-expand-md > .container-xl {
        flex-wrap: nowrap; }
      datagrid-viewer .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      datagrid-viewer .navbar-expand-md .navbar-toggler {
        display: none; } }
  @media (max-width: 991.98px) {
    datagrid-viewer .navbar-expand-lg > .container,
    datagrid-viewer .navbar-expand-lg > .container-fluid, datagrid-viewer .navbar-expand-lg > .container-sm, datagrid-viewer .navbar-expand-lg > .container-md, datagrid-viewer .navbar-expand-lg > .container-lg, datagrid-viewer .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 992px) {
    datagrid-viewer .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      datagrid-viewer .navbar-expand-lg .navbar-nav {
        flex-direction: row; }
        datagrid-viewer .navbar-expand-lg .navbar-nav .dropdown-menu {
          position: absolute; }
        datagrid-viewer .navbar-expand-lg .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      datagrid-viewer .navbar-expand-lg > .container,
      datagrid-viewer .navbar-expand-lg > .container-fluid, datagrid-viewer .navbar-expand-lg > .container-sm, datagrid-viewer .navbar-expand-lg > .container-md, datagrid-viewer .navbar-expand-lg > .container-lg, datagrid-viewer .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap; }
      datagrid-viewer .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      datagrid-viewer .navbar-expand-lg .navbar-toggler {
        display: none; } }
  @media (max-width: 1199.98px) {
    datagrid-viewer .navbar-expand-xl > .container,
    datagrid-viewer .navbar-expand-xl > .container-fluid, datagrid-viewer .navbar-expand-xl > .container-sm, datagrid-viewer .navbar-expand-xl > .container-md, datagrid-viewer .navbar-expand-xl > .container-lg, datagrid-viewer .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 1200px) {
    datagrid-viewer .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      datagrid-viewer .navbar-expand-xl .navbar-nav {
        flex-direction: row; }
        datagrid-viewer .navbar-expand-xl .navbar-nav .dropdown-menu {
          position: absolute; }
        datagrid-viewer .navbar-expand-xl .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      datagrid-viewer .navbar-expand-xl > .container,
      datagrid-viewer .navbar-expand-xl > .container-fluid, datagrid-viewer .navbar-expand-xl > .container-sm, datagrid-viewer .navbar-expand-xl > .container-md, datagrid-viewer .navbar-expand-xl > .container-lg, datagrid-viewer .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap; }
      datagrid-viewer .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      datagrid-viewer .navbar-expand-xl .navbar-toggler {
        display: none; } }
  datagrid-viewer .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    datagrid-viewer .navbar-expand > .container,
    datagrid-viewer .navbar-expand > .container-fluid, datagrid-viewer .navbar-expand > .container-sm, datagrid-viewer .navbar-expand > .container-md, datagrid-viewer .navbar-expand > .container-lg, datagrid-viewer .navbar-expand > .container-xl {
      padding-right: 0;
      padding-left: 0; }
    datagrid-viewer .navbar-expand .navbar-nav {
      flex-direction: row; }
      datagrid-viewer .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute; }
      datagrid-viewer .navbar-expand .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    datagrid-viewer .navbar-expand > .container,
    datagrid-viewer .navbar-expand > .container-fluid, datagrid-viewer .navbar-expand > .container-sm, datagrid-viewer .navbar-expand > .container-md, datagrid-viewer .navbar-expand > .container-lg, datagrid-viewer .navbar-expand > .container-xl {
      flex-wrap: nowrap; }
    datagrid-viewer .navbar-expand .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    datagrid-viewer .navbar-expand .navbar-toggler {
      display: none; }
  datagrid-viewer .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
    datagrid-viewer .navbar-light .navbar-brand:hover, datagrid-viewer .navbar-light .navbar-brand:focus {
      color: rgba(0, 0, 0, 0.9); }
  datagrid-viewer .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5); }
    datagrid-viewer .navbar-light .navbar-nav .nav-link:hover, datagrid-viewer .navbar-light .navbar-nav .nav-link:focus {
      color: rgba(0, 0, 0, 0.7); }
    datagrid-viewer .navbar-light .navbar-nav .nav-link.disabled {
      color: rgba(0, 0, 0, 0.3); }
  datagrid-viewer .navbar-light .navbar-nav .show > .nav-link,
  datagrid-viewer .navbar-light .navbar-nav .active > .nav-link,
  datagrid-viewer .navbar-light .navbar-nav .nav-link.show,
  datagrid-viewer .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9); }
  datagrid-viewer .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1); }
  datagrid-viewer .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  datagrid-viewer .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5); }
    datagrid-viewer .navbar-light .navbar-text a {
      color: rgba(0, 0, 0, 0.9); }
      datagrid-viewer .navbar-light .navbar-text a:hover, datagrid-viewer .navbar-light .navbar-text a:focus {
        color: rgba(0, 0, 0, 0.9); }
  datagrid-viewer .navbar-dark .navbar-brand {
    color: #fff; }
    datagrid-viewer .navbar-dark .navbar-brand:hover, datagrid-viewer .navbar-dark .navbar-brand:focus {
      color: #fff; }
  datagrid-viewer .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5); }
    datagrid-viewer .navbar-dark .navbar-nav .nav-link:hover, datagrid-viewer .navbar-dark .navbar-nav .nav-link:focus {
      color: rgba(255, 255, 255, 0.75); }
    datagrid-viewer .navbar-dark .navbar-nav .nav-link.disabled {
      color: rgba(255, 255, 255, 0.25); }
  datagrid-viewer .navbar-dark .navbar-nav .show > .nav-link,
  datagrid-viewer .navbar-dark .navbar-nav .active > .nav-link,
  datagrid-viewer .navbar-dark .navbar-nav .nav-link.show,
  datagrid-viewer .navbar-dark .navbar-nav .nav-link.active {
    color: #fff; }
  datagrid-viewer .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1); }
  datagrid-viewer .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  datagrid-viewer .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5); }
    datagrid-viewer .navbar-dark .navbar-text a {
      color: #fff; }
      datagrid-viewer .navbar-dark .navbar-text a:hover, datagrid-viewer .navbar-dark .navbar-text a:focus {
        color: #fff; }
  datagrid-viewer .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem; }
    datagrid-viewer .card > hr {
      margin-right: 0;
      margin-left: 0; }
    datagrid-viewer .card > .list-group {
      border-top: inherit;
      border-bottom: inherit; }
      datagrid-viewer .card > .list-group:first-child {
        border-top-width: 0;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px); }
      datagrid-viewer .card > .list-group:last-child {
        border-bottom-width: 0;
        border-bottom-right-radius: calc(0.25rem - 1px);
        border-bottom-left-radius: calc(0.25rem - 1px); }
    datagrid-viewer .card > .card-header + .list-group,
    datagrid-viewer .card > .list-group + .card-footer {
      border-top: 0; }
  datagrid-viewer .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem; }
  datagrid-viewer .card-title {
    margin-bottom: 0.75rem; }
  datagrid-viewer .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0; }
  datagrid-viewer .card-text:last-child {
    margin-bottom: 0; }
  datagrid-viewer .card-link:hover {
    text-decoration: none; }
  datagrid-viewer .card-link + .card-link {
    margin-left: 1.25rem; }
  datagrid-viewer .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    datagrid-viewer .card-header:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  datagrid-viewer .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    datagrid-viewer .card-footer:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
  datagrid-viewer .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0; }
  datagrid-viewer .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
  datagrid-viewer .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.25rem - 1px); }
  datagrid-viewer .card-img,
  datagrid-viewer .card-img-top,
  datagrid-viewer .card-img-bottom {
    flex-shrink: 0;
    width: 100%; }
  datagrid-viewer .card-img,
  datagrid-viewer .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  datagrid-viewer .card-img,
  datagrid-viewer .card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  datagrid-viewer .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    datagrid-viewer .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      datagrid-viewer .card-deck .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }
  datagrid-viewer .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    datagrid-viewer .card-group {
      display: flex;
      flex-flow: row wrap; }
      datagrid-viewer .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        datagrid-viewer .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        datagrid-viewer .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          datagrid-viewer .card-group > .card:not(:last-child) .card-img-top,
          datagrid-viewer .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          datagrid-viewer .card-group > .card:not(:last-child) .card-img-bottom,
          datagrid-viewer .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        datagrid-viewer .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          datagrid-viewer .card-group > .card:not(:first-child) .card-img-top,
          datagrid-viewer .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          datagrid-viewer .card-group > .card:not(:first-child) .card-img-bottom,
          datagrid-viewer .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }
  datagrid-viewer .card-columns .card {
    margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    datagrid-viewer .card-columns {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1; }
      datagrid-viewer .card-columns .card {
        display: inline-block;
        width: 100%; } }
  datagrid-viewer .accordion {
    overflow-anchor: none; }
    datagrid-viewer .accordion > .card {
      overflow: hidden; }
      datagrid-viewer .accordion > .card:not(:last-of-type) {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      datagrid-viewer .accordion > .card:not(:first-of-type) {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
      datagrid-viewer .accordion > .card > .card-header {
        border-radius: 0;
        margin-bottom: -1px; }
  datagrid-viewer .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  datagrid-viewer .breadcrumb-item {
    display: flex; }
    datagrid-viewer .breadcrumb-item + .breadcrumb-item {
      padding-left: 0.5rem; }
      datagrid-viewer .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "/"; }
    datagrid-viewer .breadcrumb-item + .breadcrumb-item:hover::before {
      text-decoration: underline; }
    datagrid-viewer .breadcrumb-item + .breadcrumb-item:hover::before {
      text-decoration: none; }
    datagrid-viewer .breadcrumb-item.active {
      color: #6c757d; }
  datagrid-viewer .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem; }
  datagrid-viewer .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6; }
    datagrid-viewer .page-link:hover {
      z-index: 2;
      color: #0056b3;
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6; }
    datagrid-viewer .page-link:focus {
      z-index: 3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  datagrid-viewer .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  datagrid-viewer .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  datagrid-viewer .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  datagrid-viewer .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6; }
  datagrid-viewer .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5; }
  datagrid-viewer .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem; }
  datagrid-viewer .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  datagrid-viewer .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5; }
  datagrid-viewer .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem; }
  datagrid-viewer .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem; }
  datagrid-viewer .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .badge {
        transition: none; } }
    adatagrid-viewer .badge:hover, adatagrid-viewer .badge:focus {
      text-decoration: none; }
    datagrid-viewer .badge:empty {
      display: none; }
  datagrid-viewer .btn .badge {
    position: relative;
    top: -1px; }
  datagrid-viewer .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem; }
  datagrid-viewer .badge-primary {
    color: #fff;
    background-color: #007bff; }
    adatagrid-viewer .badge-primary:hover, adatagrid-viewer .badge-primary:focus {
      color: #fff;
      background-color: #0062cc; }
    adatagrid-viewer .badge-primary:focus, adatagrid-viewer .badge-primary.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  datagrid-viewer .badge-secondary {
    color: #fff;
    background-color: #6c757d; }
    adatagrid-viewer .badge-secondary:hover, adatagrid-viewer .badge-secondary:focus {
      color: #fff;
      background-color: #545b62; }
    adatagrid-viewer .badge-secondary:focus, adatagrid-viewer .badge-secondary.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  datagrid-viewer .badge-success {
    color: #fff;
    background-color: #28a745; }
    adatagrid-viewer .badge-success:hover, adatagrid-viewer .badge-success:focus {
      color: #fff;
      background-color: #1e7e34; }
    adatagrid-viewer .badge-success:focus, adatagrid-viewer .badge-success.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  datagrid-viewer .badge-info {
    color: #fff;
    background-color: #17a2b8; }
    adatagrid-viewer .badge-info:hover, adatagrid-viewer .badge-info:focus {
      color: #fff;
      background-color: #117a8b; }
    adatagrid-viewer .badge-info:focus, adatagrid-viewer .badge-info.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  datagrid-viewer .badge-warning {
    color: #212529;
    background-color: #ffc107; }
    adatagrid-viewer .badge-warning:hover, adatagrid-viewer .badge-warning:focus {
      color: #212529;
      background-color: #d39e00; }
    adatagrid-viewer .badge-warning:focus, adatagrid-viewer .badge-warning.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  datagrid-viewer .badge-danger {
    color: #fff;
    background-color: #dc3545; }
    adatagrid-viewer .badge-danger:hover, adatagrid-viewer .badge-danger:focus {
      color: #fff;
      background-color: #bd2130; }
    adatagrid-viewer .badge-danger:focus, adatagrid-viewer .badge-danger.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  datagrid-viewer .badge-light {
    color: #212529;
    background-color: #f8f9fa; }
    adatagrid-viewer .badge-light:hover, adatagrid-viewer .badge-light:focus {
      color: #212529;
      background-color: #dae0e5; }
    adatagrid-viewer .badge-light:focus, adatagrid-viewer .badge-light.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  datagrid-viewer .badge-dark {
    color: #fff;
    background-color: #343a40; }
    adatagrid-viewer .badge-dark:hover, adatagrid-viewer .badge-dark:focus {
      color: #fff;
      background-color: #1d2124; }
    adatagrid-viewer .badge-dark:focus, adatagrid-viewer .badge-dark.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  datagrid-viewer .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem; }
    @media (min-width: 576px) {
      datagrid-viewer .jumbotron {
        padding: 4rem 2rem; } }
  datagrid-viewer .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0; }
  datagrid-viewer .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
  datagrid-viewer .alert-heading {
    color: inherit; }
  datagrid-viewer .alert-link {
    font-weight: 700; }
  datagrid-viewer .alert-dismissible {
    padding-right: 4rem; }
    datagrid-viewer .alert-dismissible .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.75rem 1.25rem;
      color: inherit; }
  datagrid-viewer .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff; }
    datagrid-viewer .alert-primary hr {
      border-top-color: #9fcdff; }
    datagrid-viewer .alert-primary .alert-link {
      color: #002752; }
  datagrid-viewer .alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db; }
    datagrid-viewer .alert-secondary hr {
      border-top-color: #c8cbcf; }
    datagrid-viewer .alert-secondary .alert-link {
      color: #202326; }
  datagrid-viewer .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
    datagrid-viewer .alert-success hr {
      border-top-color: #b1dfbb; }
    datagrid-viewer .alert-success .alert-link {
      color: #0b2e13; }
  datagrid-viewer .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb; }
    datagrid-viewer .alert-info hr {
      border-top-color: #abdde5; }
    datagrid-viewer .alert-info .alert-link {
      color: #062c33; }
  datagrid-viewer .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba; }
    datagrid-viewer .alert-warning hr {
      border-top-color: #ffe8a1; }
    datagrid-viewer .alert-warning .alert-link {
      color: #533f03; }
  datagrid-viewer .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
    datagrid-viewer .alert-danger hr {
      border-top-color: #f1b0b7; }
    datagrid-viewer .alert-danger .alert-link {
      color: #491217; }
  datagrid-viewer .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe; }
    datagrid-viewer .alert-light hr {
      border-top-color: #ececf6; }
    datagrid-viewer .alert-light .alert-link {
      color: #686868; }
  datagrid-viewer .alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca; }
    datagrid-viewer .alert-dark hr {
      border-top-color: #b9bbbe; }
    datagrid-viewer .alert-dark .alert-link {
      color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
  datagrid-viewer .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  datagrid-viewer .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .progress-bar {
        transition: none; } }
  datagrid-viewer .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }
  datagrid-viewer .progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .progress-bar-animated {
        animation: none; } }
  datagrid-viewer .media {
    display: flex;
    align-items: flex-start; }
  datagrid-viewer .media-body {
    flex: 1; }
  datagrid-viewer .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem; }
  datagrid-viewer .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit; }
    datagrid-viewer .list-group-item-action:hover, datagrid-viewer .list-group-item-action:focus {
      z-index: 1;
      color: #495057;
      text-decoration: none;
      background-color: #f8f9fa; }
    datagrid-viewer .list-group-item-action:active {
      color: #212529;
      background-color: #e9ecef; }
  datagrid-viewer .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125); }
    datagrid-viewer .list-group-item:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit; }
    datagrid-viewer .list-group-item:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit; }
    datagrid-viewer .list-group-item.disabled, datagrid-viewer .list-group-item:disabled {
      color: #6c757d;
      pointer-events: none;
      background-color: #fff; }
    datagrid-viewer .list-group-item.active {
      z-index: 2;
      color: #fff;
      background-color: #007bff;
      border-color: #007bff; }
    datagrid-viewer .list-group-item + datagrid-viewer .list-group-item {
      border-top-width: 0; }
      datagrid-viewer .list-group-item + datagrid-viewer .list-group-item.active {
        margin-top: -1px;
        border-top-width: 1px; }
  datagrid-viewer .list-group-horizontal {
    flex-direction: row; }
    datagrid-viewer .list-group-horizontal > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    datagrid-viewer .list-group-horizontal > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    datagrid-viewer .list-group-horizontal > .list-group-item.active {
      margin-top: 0; }
    datagrid-viewer .list-group-horizontal > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      datagrid-viewer .list-group-horizontal > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; }
  @media (min-width: 576px) {
    datagrid-viewer .list-group-horizontal-sm {
      flex-direction: row; }
      datagrid-viewer .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      datagrid-viewer .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      datagrid-viewer .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0; }
      datagrid-viewer .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        datagrid-viewer .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 768px) {
    datagrid-viewer .list-group-horizontal-md {
      flex-direction: row; }
      datagrid-viewer .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      datagrid-viewer .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      datagrid-viewer .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0; }
      datagrid-viewer .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        datagrid-viewer .list-group-horizontal-md > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 992px) {
    datagrid-viewer .list-group-horizontal-lg {
      flex-direction: row; }
      datagrid-viewer .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      datagrid-viewer .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      datagrid-viewer .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0; }
      datagrid-viewer .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        datagrid-viewer .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 1200px) {
    datagrid-viewer .list-group-horizontal-xl {
      flex-direction: row; }
      datagrid-viewer .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      datagrid-viewer .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      datagrid-viewer .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0; }
      datagrid-viewer .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        datagrid-viewer .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  datagrid-viewer .list-group-flush {
    border-radius: 0; }
    datagrid-viewer .list-group-flush > .list-group-item {
      border-width: 0 0 1px; }
      datagrid-viewer .list-group-flush > .list-group-item:last-child {
        border-bottom-width: 0; }
  datagrid-viewer .list-group-item-primary {
    color: #004085;
    background-color: #b8daff; }
    datagrid-viewer .list-group-item-primary.list-group-item-action:hover, datagrid-viewer .list-group-item-primary.list-group-item-action:focus {
      color: #004085;
      background-color: #9fcdff; }
    datagrid-viewer .list-group-item-primary.list-group-item-action.active {
      color: #fff;
      background-color: #004085;
      border-color: #004085; }
  datagrid-viewer .list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db; }
    datagrid-viewer .list-group-item-secondary.list-group-item-action:hover, datagrid-viewer .list-group-item-secondary.list-group-item-action:focus {
      color: #383d41;
      background-color: #c8cbcf; }
    datagrid-viewer .list-group-item-secondary.list-group-item-action.active {
      color: #fff;
      background-color: #383d41;
      border-color: #383d41; }
  datagrid-viewer .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb; }
    datagrid-viewer .list-group-item-success.list-group-item-action:hover, datagrid-viewer .list-group-item-success.list-group-item-action:focus {
      color: #155724;
      background-color: #b1dfbb; }
    datagrid-viewer .list-group-item-success.list-group-item-action.active {
      color: #fff;
      background-color: #155724;
      border-color: #155724; }
  datagrid-viewer .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb; }
    datagrid-viewer .list-group-item-info.list-group-item-action:hover, datagrid-viewer .list-group-item-info.list-group-item-action:focus {
      color: #0c5460;
      background-color: #abdde5; }
    datagrid-viewer .list-group-item-info.list-group-item-action.active {
      color: #fff;
      background-color: #0c5460;
      border-color: #0c5460; }
  datagrid-viewer .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba; }
    datagrid-viewer .list-group-item-warning.list-group-item-action:hover, datagrid-viewer .list-group-item-warning.list-group-item-action:focus {
      color: #856404;
      background-color: #ffe8a1; }
    datagrid-viewer .list-group-item-warning.list-group-item-action.active {
      color: #fff;
      background-color: #856404;
      border-color: #856404; }
  datagrid-viewer .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb; }
    datagrid-viewer .list-group-item-danger.list-group-item-action:hover, datagrid-viewer .list-group-item-danger.list-group-item-action:focus {
      color: #721c24;
      background-color: #f1b0b7; }
    datagrid-viewer .list-group-item-danger.list-group-item-action.active {
      color: #fff;
      background-color: #721c24;
      border-color: #721c24; }
  datagrid-viewer .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe; }
    datagrid-viewer .list-group-item-light.list-group-item-action:hover, datagrid-viewer .list-group-item-light.list-group-item-action:focus {
      color: #818182;
      background-color: #ececf6; }
    datagrid-viewer .list-group-item-light.list-group-item-action.active {
      color: #fff;
      background-color: #818182;
      border-color: #818182; }
  datagrid-viewer .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca; }
    datagrid-viewer .list-group-item-dark.list-group-item-action:hover, datagrid-viewer .list-group-item-dark.list-group-item-action:focus {
      color: #1b1e21;
      background-color: #b9bbbe; }
    datagrid-viewer .list-group-item-dark.list-group-item-action.active {
      color: #fff;
      background-color: #1b1e21;
      border-color: #1b1e21; }
  datagrid-viewer .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5; }
    datagrid-viewer .close:hover {
      color: #000;
      text-decoration: none; }
    datagrid-viewer .close:not(:disabled):not(.disabled):hover, datagrid-viewer .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }
  datagrid-viewer button.close {
    padding: 0;
    background-color: transparent;
    border: 0; }
  datagrid-viewer a.close.disabled {
    pointer-events: none; }
  datagrid-viewer .toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    border-radius: 0.25rem; }
    datagrid-viewer .toast:not(:last-child) {
      margin-bottom: 0.75rem; }
    datagrid-viewer .toast.showing {
      opacity: 1; }
    datagrid-viewer .toast.show {
      display: block;
      opacity: 1; }
    datagrid-viewer .toast.hide {
      display: none; }
  datagrid-viewer .toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  datagrid-viewer .toast-body {
    padding: 0.75rem; }
  datagrid-viewer .modal-open {
    overflow: hidden; }
    datagrid-viewer .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto; }
  datagrid-viewer .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0; }
  datagrid-viewer .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
    .modal.fade datagrid-viewer .modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -50px); }
      @media (prefers-reduced-motion: reduce) {
        .modal.fade datagrid-viewer .modal-dialog {
          transition: none; } }
    .modal.show datagrid-viewer .modal-dialog {
      transform: none; }
    .modal.modal-static datagrid-viewer .modal-dialog {
      transform: scale(1.02); }
  datagrid-viewer .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem); }
    datagrid-viewer .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 1rem);
      overflow: hidden; }
    datagrid-viewer .modal-dialog-scrollable .modal-header,
    datagrid-viewer .modal-dialog-scrollable .modal-footer {
      flex-shrink: 0; }
    datagrid-viewer .modal-dialog-scrollable .modal-body {
      overflow-y: auto; }
  datagrid-viewer .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem); }
    datagrid-viewer .modal-dialog-centered::before {
      display: block;
      height: calc(100vh - 1rem);
      height: min-content;
      content: ""; }
    datagrid-viewer .modal-dialog-centered.modal-dialog-scrollable {
      flex-direction: column;
      justify-content: center;
      height: 100%; }
      datagrid-viewer .modal-dialog-centered.modal-dialog-scrollable .modal-content {
        max-height: none; }
      datagrid-viewer .modal-dialog-centered.modal-dialog-scrollable::before {
        content: none; }
  datagrid-viewer .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0; }
  datagrid-viewer .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
    datagrid-viewer .modal-backdrop.fade {
      opacity: 0; }
    datagrid-viewer .modal-backdrop.show {
      opacity: 0.5; }
  datagrid-viewer .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    datagrid-viewer .modal-header .close {
      padding: 1rem 1rem;
      margin: -1rem -1rem -1rem auto; }
  datagrid-viewer .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  datagrid-viewer .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem; }
  datagrid-viewer .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px); }
    datagrid-viewer .modal-footer > * {
      margin: 0.25rem; }
  datagrid-viewer .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  @media (min-width: 576px) {
    datagrid-viewer .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    datagrid-viewer .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem); }
      datagrid-viewer .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem); }
    datagrid-viewer .modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
      datagrid-viewer .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: min-content; }
    datagrid-viewer .modal-sm {
      max-width: 300px; } }
  @media (min-width: 992px) {
    datagrid-viewer .modal-lg,
    datagrid-viewer .modal-xl {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    datagrid-viewer .modal-xl {
      max-width: 1140px; } }
  datagrid-viewer .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0; }
    datagrid-viewer .tooltip.show {
      opacity: 0.9; }
    datagrid-viewer .tooltip .arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem; }
      datagrid-viewer .tooltip .arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid; }
  datagrid-viewer .bs-tooltip-top, datagrid-viewer .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0; }
    datagrid-viewer .bs-tooltip-top .arrow, datagrid-viewer .bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
      datagrid-viewer .bs-tooltip-top .arrow::before, datagrid-viewer .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #000; }
  datagrid-viewer .bs-tooltip-right, datagrid-viewer .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem; }
    datagrid-viewer .bs-tooltip-right .arrow, datagrid-viewer .bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0;
      width: 0.4rem;
      height: 0.8rem; }
      datagrid-viewer .bs-tooltip-right .arrow::before, datagrid-viewer .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: #000; }
  datagrid-viewer .bs-tooltip-bottom, datagrid-viewer .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0; }
    datagrid-viewer .bs-tooltip-bottom .arrow, datagrid-viewer .bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
      datagrid-viewer .bs-tooltip-bottom .arrow::before, datagrid-viewer .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: #000; }
  datagrid-viewer .bs-tooltip-left, datagrid-viewer .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem; }
    datagrid-viewer .bs-tooltip-left .arrow, datagrid-viewer .bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0;
      width: 0.4rem;
      height: 0.8rem; }
      datagrid-viewer .bs-tooltip-left .arrow::before, datagrid-viewer .bs-tooltip-auto[x-placement^="left"] .arrow::before {
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: #000; }
  datagrid-viewer .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem; }
  datagrid-viewer .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem; }
    datagrid-viewer .popover .arrow {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      margin: 0 0.3rem; }
      datagrid-viewer .popover .arrow::before, datagrid-viewer .popover .arrow::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid; }
  datagrid-viewer .bs-popover-top, datagrid-viewer .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem; }
    datagrid-viewer .bs-popover-top > .arrow, datagrid-viewer .bs-popover-auto[x-placement^="top"] > .arrow {
      bottom: calc(-0.5rem - 1px); }
      datagrid-viewer .bs-popover-top > .arrow::before, datagrid-viewer .bs-popover-auto[x-placement^="top"] > .arrow::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0, 0, 0, 0.25); }
      datagrid-viewer .bs-popover-top > .arrow::after, datagrid-viewer .bs-popover-auto[x-placement^="top"] > .arrow::after {
        bottom: 1px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #fff; }
  datagrid-viewer .bs-popover-right, datagrid-viewer .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem; }
    datagrid-viewer .bs-popover-right > .arrow, datagrid-viewer .bs-popover-auto[x-placement^="right"] > .arrow {
      left: calc(-0.5rem - 1px);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0; }
      datagrid-viewer .bs-popover-right > .arrow::before, datagrid-viewer .bs-popover-auto[x-placement^="right"] > .arrow::before {
        left: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0, 0, 0, 0.25); }
      datagrid-viewer .bs-popover-right > .arrow::after, datagrid-viewer .bs-popover-auto[x-placement^="right"] > .arrow::after {
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #fff; }
  datagrid-viewer .bs-popover-bottom, datagrid-viewer .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem; }
    datagrid-viewer .bs-popover-bottom > .arrow, datagrid-viewer .bs-popover-auto[x-placement^="bottom"] > .arrow {
      top: calc(-0.5rem - 1px); }
      datagrid-viewer .bs-popover-bottom > .arrow::before, datagrid-viewer .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25); }
      datagrid-viewer .bs-popover-bottom > .arrow::after, datagrid-viewer .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: #fff; }
    datagrid-viewer .bs-popover-bottom .popover-header::before, datagrid-viewer .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1rem;
      margin-left: -0.5rem;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  datagrid-viewer .bs-popover-left, datagrid-viewer .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem; }
    datagrid-viewer .bs-popover-left > .arrow, datagrid-viewer .bs-popover-auto[x-placement^="left"] > .arrow {
      right: calc(-0.5rem - 1px);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0; }
      datagrid-viewer .bs-popover-left > .arrow::before, datagrid-viewer .bs-popover-auto[x-placement^="left"] > .arrow::before {
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25); }
      datagrid-viewer .bs-popover-left > .arrow::after, datagrid-viewer .bs-popover-auto[x-placement^="left"] > .arrow::after {
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #fff; }
  datagrid-viewer .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    datagrid-viewer .popover-header:empty {
      display: none; }
  datagrid-viewer .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529; }
  datagrid-viewer .carousel {
    position: relative; }
  datagrid-viewer .carousel.pointer-event {
    touch-action: pan-y; }
  datagrid-viewer .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
    datagrid-viewer .carousel-inner::after {
      display: block;
      clear: both;
      content: ""; }
  datagrid-viewer .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .carousel-item {
        transition: none; } }
  datagrid-viewer .carousel-item.active,
  datagrid-viewer .carousel-item-next,
  datagrid-viewer .carousel-item-prev {
    display: block; }
  datagrid-viewer .carousel-item-next:not(.carousel-item-left),
  datagrid-viewer .active.carousel-item-right {
    transform: translateX(100%); }
  datagrid-viewer .carousel-item-prev:not(.carousel-item-right),
  datagrid-viewer .active.carousel-item-left {
    transform: translateX(-100%); }
  datagrid-viewer .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none; }
  datagrid-viewer .carousel-fade .carousel-item.active,
  datagrid-viewer .carousel-fade .carousel-item-next.carousel-item-left,
  datagrid-viewer .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1; }
  datagrid-viewer .carousel-fade .active.carousel-item-left,
  datagrid-viewer .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .carousel-fade .active.carousel-item-left,
      datagrid-viewer .carousel-fade .active.carousel-item-right {
        transition: none; } }
  datagrid-viewer .carousel-control-prev,
  datagrid-viewer .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease; }
    @media (prefers-reduced-motion: reduce) {
      datagrid-viewer .carousel-control-prev,
      datagrid-viewer .carousel-control-next {
        transition: none; } }
    datagrid-viewer .carousel-control-prev:hover, datagrid-viewer .carousel-control-prev:focus,
    datagrid-viewer .carousel-control-next:hover,
    datagrid-viewer .carousel-control-next:focus {
      color: #fff;
      text-decoration: none;
      outline: 0;
      opacity: 0.9; }
  datagrid-viewer .carousel-control-prev {
    left: 0; }
  datagrid-viewer .carousel-control-next {
    right: 0; }
  datagrid-viewer .carousel-control-prev-icon,
  datagrid-viewer .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%; }
  datagrid-viewer .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }
  datagrid-viewer .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }
  datagrid-viewer .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none; }
    datagrid-viewer .carousel-indicators li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: .5;
      transition: opacity 0.6s ease; }
      @media (prefers-reduced-motion: reduce) {
        datagrid-viewer .carousel-indicators li {
          transition: none; } }
    datagrid-viewer .carousel-indicators .active {
      opacity: 1; }
  datagrid-viewer .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }
  datagrid-viewer .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite; }
  datagrid-viewer .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }
  datagrid-viewer .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite; }
  datagrid-viewer .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }
  datagrid-viewer .align-baseline {
    vertical-align: baseline !important; }
  datagrid-viewer .align-top {
    vertical-align: top !important; }
  datagrid-viewer .align-middle {
    vertical-align: middle !important; }
  datagrid-viewer .align-bottom {
    vertical-align: bottom !important; }
  datagrid-viewer .align-text-bottom {
    vertical-align: text-bottom !important; }
  datagrid-viewer .align-text-top {
    vertical-align: text-top !important; }
  datagrid-viewer .bg-primary {
    background-color: #007bff !important; }
  datagrid-viewer a.bg-primary:hover, datagrid-viewer a.bg-primary:focus,
  datagrid-viewer button.bg-primary:hover,
  datagrid-viewer button.bg-primary:focus {
    background-color: #0062cc !important; }
  datagrid-viewer .bg-secondary {
    background-color: #6c757d !important; }
  datagrid-viewer a.bg-secondary:hover, datagrid-viewer a.bg-secondary:focus,
  datagrid-viewer button.bg-secondary:hover,
  datagrid-viewer button.bg-secondary:focus {
    background-color: #545b62 !important; }
  datagrid-viewer .bg-success {
    background-color: #28a745 !important; }
  datagrid-viewer a.bg-success:hover, datagrid-viewer a.bg-success:focus,
  datagrid-viewer button.bg-success:hover,
  datagrid-viewer button.bg-success:focus {
    background-color: #1e7e34 !important; }
  datagrid-viewer .bg-info {
    background-color: #17a2b8 !important; }
  datagrid-viewer a.bg-info:hover, datagrid-viewer a.bg-info:focus,
  datagrid-viewer button.bg-info:hover,
  datagrid-viewer button.bg-info:focus {
    background-color: #117a8b !important; }
  datagrid-viewer .bg-warning {
    background-color: #ffc107 !important; }
  datagrid-viewer a.bg-warning:hover, datagrid-viewer a.bg-warning:focus,
  datagrid-viewer button.bg-warning:hover,
  datagrid-viewer button.bg-warning:focus {
    background-color: #d39e00 !important; }
  datagrid-viewer .bg-danger {
    background-color: #dc3545 !important; }
  datagrid-viewer a.bg-danger:hover, datagrid-viewer a.bg-danger:focus,
  datagrid-viewer button.bg-danger:hover,
  datagrid-viewer button.bg-danger:focus {
    background-color: #bd2130 !important; }
  datagrid-viewer .bg-light {
    background-color: #f8f9fa !important; }
  datagrid-viewer a.bg-light:hover, datagrid-viewer a.bg-light:focus,
  datagrid-viewer button.bg-light:hover,
  datagrid-viewer button.bg-light:focus {
    background-color: #dae0e5 !important; }
  datagrid-viewer .bg-dark {
    background-color: #343a40 !important; }
  datagrid-viewer a.bg-dark:hover, datagrid-viewer a.bg-dark:focus,
  datagrid-viewer button.bg-dark:hover,
  datagrid-viewer button.bg-dark:focus {
    background-color: #1d2124 !important; }
  datagrid-viewer .bg-white {
    background-color: #fff !important; }
  datagrid-viewer .bg-transparent {
    background-color: transparent !important; }
  datagrid-viewer .border {
    border: 1px solid #dee2e6 !important; }
  datagrid-viewer .border-top {
    border-top: 1px solid #dee2e6 !important; }
  datagrid-viewer .border-right {
    border-right: 1px solid #dee2e6 !important; }
  datagrid-viewer .border-bottom {
    border-bottom: 1px solid #dee2e6 !important; }
  datagrid-viewer .border-left {
    border-left: 1px solid #dee2e6 !important; }
  datagrid-viewer .border-0 {
    border: 0 !important; }
  datagrid-viewer .border-top-0 {
    border-top: 0 !important; }
  datagrid-viewer .border-right-0 {
    border-right: 0 !important; }
  datagrid-viewer .border-bottom-0 {
    border-bottom: 0 !important; }
  datagrid-viewer .border-left-0 {
    border-left: 0 !important; }
  datagrid-viewer .border-primary {
    border-color: #007bff !important; }
  datagrid-viewer .border-secondary {
    border-color: #6c757d !important; }
  datagrid-viewer .border-success {
    border-color: #28a745 !important; }
  datagrid-viewer .border-info {
    border-color: #17a2b8 !important; }
  datagrid-viewer .border-warning {
    border-color: #ffc107 !important; }
  datagrid-viewer .border-danger {
    border-color: #dc3545 !important; }
  datagrid-viewer .border-light {
    border-color: #f8f9fa !important; }
  datagrid-viewer .border-dark {
    border-color: #343a40 !important; }
  datagrid-viewer .border-white {
    border-color: #fff !important; }
  datagrid-viewer .rounded-sm {
    border-radius: 0.2rem !important; }
  datagrid-viewer .rounded {
    border-radius: 0.25rem !important; }
  datagrid-viewer .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important; }
  datagrid-viewer .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important; }
  datagrid-viewer .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  datagrid-viewer .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  datagrid-viewer .rounded-lg {
    border-radius: 0.3rem !important; }
  datagrid-viewer .rounded-circle {
    border-radius: 50% !important; }
  datagrid-viewer .rounded-pill {
    border-radius: 50rem !important; }
  datagrid-viewer .rounded-0 {
    border-radius: 0 !important; }
  datagrid-viewer .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  datagrid-viewer .d-none {
    display: none !important; }
  datagrid-viewer .d-inline {
    display: inline !important; }
  datagrid-viewer .d-inline-block {
    display: inline-block !important; }
  datagrid-viewer .d-block {
    display: block !important; }
  datagrid-viewer .d-table {
    display: table !important; }
  datagrid-viewer .d-table-row {
    display: table-row !important; }
  datagrid-viewer .d-table-cell {
    display: table-cell !important; }
  datagrid-viewer .d-flex {
    display: flex !important; }
  datagrid-viewer .d-inline-flex {
    display: inline-flex !important; }
  @media (min-width: 576px) {
    datagrid-viewer .d-sm-none {
      display: none !important; }
    datagrid-viewer .d-sm-inline {
      display: inline !important; }
    datagrid-viewer .d-sm-inline-block {
      display: inline-block !important; }
    datagrid-viewer .d-sm-block {
      display: block !important; }
    datagrid-viewer .d-sm-table {
      display: table !important; }
    datagrid-viewer .d-sm-table-row {
      display: table-row !important; }
    datagrid-viewer .d-sm-table-cell {
      display: table-cell !important; }
    datagrid-viewer .d-sm-flex {
      display: flex !important; }
    datagrid-viewer .d-sm-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 768px) {
    datagrid-viewer .d-md-none {
      display: none !important; }
    datagrid-viewer .d-md-inline {
      display: inline !important; }
    datagrid-viewer .d-md-inline-block {
      display: inline-block !important; }
    datagrid-viewer .d-md-block {
      display: block !important; }
    datagrid-viewer .d-md-table {
      display: table !important; }
    datagrid-viewer .d-md-table-row {
      display: table-row !important; }
    datagrid-viewer .d-md-table-cell {
      display: table-cell !important; }
    datagrid-viewer .d-md-flex {
      display: flex !important; }
    datagrid-viewer .d-md-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 992px) {
    datagrid-viewer .d-lg-none {
      display: none !important; }
    datagrid-viewer .d-lg-inline {
      display: inline !important; }
    datagrid-viewer .d-lg-inline-block {
      display: inline-block !important; }
    datagrid-viewer .d-lg-block {
      display: block !important; }
    datagrid-viewer .d-lg-table {
      display: table !important; }
    datagrid-viewer .d-lg-table-row {
      display: table-row !important; }
    datagrid-viewer .d-lg-table-cell {
      display: table-cell !important; }
    datagrid-viewer .d-lg-flex {
      display: flex !important; }
    datagrid-viewer .d-lg-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 1200px) {
    datagrid-viewer .d-xl-none {
      display: none !important; }
    datagrid-viewer .d-xl-inline {
      display: inline !important; }
    datagrid-viewer .d-xl-inline-block {
      display: inline-block !important; }
    datagrid-viewer .d-xl-block {
      display: block !important; }
    datagrid-viewer .d-xl-table {
      display: table !important; }
    datagrid-viewer .d-xl-table-row {
      display: table-row !important; }
    datagrid-viewer .d-xl-table-cell {
      display: table-cell !important; }
    datagrid-viewer .d-xl-flex {
      display: flex !important; }
    datagrid-viewer .d-xl-inline-flex {
      display: inline-flex !important; } }
  @media print {
    datagrid-viewer .d-print-none {
      display: none !important; }
    datagrid-viewer .d-print-inline {
      display: inline !important; }
    datagrid-viewer .d-print-inline-block {
      display: inline-block !important; }
    datagrid-viewer .d-print-block {
      display: block !important; }
    datagrid-viewer .d-print-table {
      display: table !important; }
    datagrid-viewer .d-print-table-row {
      display: table-row !important; }
    datagrid-viewer .d-print-table-cell {
      display: table-cell !important; }
    datagrid-viewer .d-print-flex {
      display: flex !important; }
    datagrid-viewer .d-print-inline-flex {
      display: inline-flex !important; } }
  datagrid-viewer .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden; }
    datagrid-viewer .embed-responsive::before {
      display: block;
      content: ""; }
    datagrid-viewer .embed-responsive .embed-responsive-item,
    datagrid-viewer .embed-responsive iframe,
    datagrid-viewer .embed-responsive embed,
    datagrid-viewer .embed-responsive object,
    datagrid-viewer .embed-responsive video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; }
  datagrid-viewer .embed-responsive-21by9::before {
    padding-top: 42.85714%; }
  datagrid-viewer .embed-responsive-16by9::before {
    padding-top: 56.25%; }
  datagrid-viewer .embed-responsive-4by3::before {
    padding-top: 75%; }
  datagrid-viewer .embed-responsive-1by1::before {
    padding-top: 100%; }
  datagrid-viewer .flex-row {
    flex-direction: row !important; }
  datagrid-viewer .flex-column {
    flex-direction: column !important; }
  datagrid-viewer .flex-row-reverse {
    flex-direction: row-reverse !important; }
  datagrid-viewer .flex-column-reverse {
    flex-direction: column-reverse !important; }
  datagrid-viewer .flex-wrap {
    flex-wrap: wrap !important; }
  datagrid-viewer .flex-nowrap {
    flex-wrap: nowrap !important; }
  datagrid-viewer .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  datagrid-viewer .flex-fill {
    flex: 1 1 auto !important; }
  datagrid-viewer .flex-grow-0 {
    flex-grow: 0 !important; }
  datagrid-viewer .flex-grow-1 {
    flex-grow: 1 !important; }
  datagrid-viewer .flex-shrink-0 {
    flex-shrink: 0 !important; }
  datagrid-viewer .flex-shrink-1 {
    flex-shrink: 1 !important; }
  datagrid-viewer .justify-content-start {
    justify-content: flex-start !important; }
  datagrid-viewer .justify-content-end {
    justify-content: flex-end !important; }
  datagrid-viewer .justify-content-center {
    justify-content: center !important; }
  datagrid-viewer .justify-content-between {
    justify-content: space-between !important; }
  datagrid-viewer .justify-content-around {
    justify-content: space-around !important; }
  datagrid-viewer .align-items-start {
    align-items: flex-start !important; }
  datagrid-viewer .align-items-end {
    align-items: flex-end !important; }
  datagrid-viewer .align-items-center {
    align-items: center !important; }
  datagrid-viewer .align-items-baseline {
    align-items: baseline !important; }
  datagrid-viewer .align-items-stretch {
    align-items: stretch !important; }
  datagrid-viewer .align-content-start {
    align-content: flex-start !important; }
  datagrid-viewer .align-content-end {
    align-content: flex-end !important; }
  datagrid-viewer .align-content-center {
    align-content: center !important; }
  datagrid-viewer .align-content-between {
    align-content: space-between !important; }
  datagrid-viewer .align-content-around {
    align-content: space-around !important; }
  datagrid-viewer .align-content-stretch {
    align-content: stretch !important; }
  datagrid-viewer .align-self-auto {
    align-self: auto !important; }
  datagrid-viewer .align-self-start {
    align-self: flex-start !important; }
  datagrid-viewer .align-self-end {
    align-self: flex-end !important; }
  datagrid-viewer .align-self-center {
    align-self: center !important; }
  datagrid-viewer .align-self-baseline {
    align-self: baseline !important; }
  datagrid-viewer .align-self-stretch {
    align-self: stretch !important; }
  @media (min-width: 576px) {
    datagrid-viewer .flex-sm-row {
      flex-direction: row !important; }
    datagrid-viewer .flex-sm-column {
      flex-direction: column !important; }
    datagrid-viewer .flex-sm-row-reverse {
      flex-direction: row-reverse !important; }
    datagrid-viewer .flex-sm-column-reverse {
      flex-direction: column-reverse !important; }
    datagrid-viewer .flex-sm-wrap {
      flex-wrap: wrap !important; }
    datagrid-viewer .flex-sm-nowrap {
      flex-wrap: nowrap !important; }
    datagrid-viewer .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    datagrid-viewer .flex-sm-fill {
      flex: 1 1 auto !important; }
    datagrid-viewer .flex-sm-grow-0 {
      flex-grow: 0 !important; }
    datagrid-viewer .flex-sm-grow-1 {
      flex-grow: 1 !important; }
    datagrid-viewer .flex-sm-shrink-0 {
      flex-shrink: 0 !important; }
    datagrid-viewer .flex-sm-shrink-1 {
      flex-shrink: 1 !important; }
    datagrid-viewer .justify-content-sm-start {
      justify-content: flex-start !important; }
    datagrid-viewer .justify-content-sm-end {
      justify-content: flex-end !important; }
    datagrid-viewer .justify-content-sm-center {
      justify-content: center !important; }
    datagrid-viewer .justify-content-sm-between {
      justify-content: space-between !important; }
    datagrid-viewer .justify-content-sm-around {
      justify-content: space-around !important; }
    datagrid-viewer .align-items-sm-start {
      align-items: flex-start !important; }
    datagrid-viewer .align-items-sm-end {
      align-items: flex-end !important; }
    datagrid-viewer .align-items-sm-center {
      align-items: center !important; }
    datagrid-viewer .align-items-sm-baseline {
      align-items: baseline !important; }
    datagrid-viewer .align-items-sm-stretch {
      align-items: stretch !important; }
    datagrid-viewer .align-content-sm-start {
      align-content: flex-start !important; }
    datagrid-viewer .align-content-sm-end {
      align-content: flex-end !important; }
    datagrid-viewer .align-content-sm-center {
      align-content: center !important; }
    datagrid-viewer .align-content-sm-between {
      align-content: space-between !important; }
    datagrid-viewer .align-content-sm-around {
      align-content: space-around !important; }
    datagrid-viewer .align-content-sm-stretch {
      align-content: stretch !important; }
    datagrid-viewer .align-self-sm-auto {
      align-self: auto !important; }
    datagrid-viewer .align-self-sm-start {
      align-self: flex-start !important; }
    datagrid-viewer .align-self-sm-end {
      align-self: flex-end !important; }
    datagrid-viewer .align-self-sm-center {
      align-self: center !important; }
    datagrid-viewer .align-self-sm-baseline {
      align-self: baseline !important; }
    datagrid-viewer .align-self-sm-stretch {
      align-self: stretch !important; } }
  @media (min-width: 768px) {
    datagrid-viewer .flex-md-row {
      flex-direction: row !important; }
    datagrid-viewer .flex-md-column {
      flex-direction: column !important; }
    datagrid-viewer .flex-md-row-reverse {
      flex-direction: row-reverse !important; }
    datagrid-viewer .flex-md-column-reverse {
      flex-direction: column-reverse !important; }
    datagrid-viewer .flex-md-wrap {
      flex-wrap: wrap !important; }
    datagrid-viewer .flex-md-nowrap {
      flex-wrap: nowrap !important; }
    datagrid-viewer .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    datagrid-viewer .flex-md-fill {
      flex: 1 1 auto !important; }
    datagrid-viewer .flex-md-grow-0 {
      flex-grow: 0 !important; }
    datagrid-viewer .flex-md-grow-1 {
      flex-grow: 1 !important; }
    datagrid-viewer .flex-md-shrink-0 {
      flex-shrink: 0 !important; }
    datagrid-viewer .flex-md-shrink-1 {
      flex-shrink: 1 !important; }
    datagrid-viewer .justify-content-md-start {
      justify-content: flex-start !important; }
    datagrid-viewer .justify-content-md-end {
      justify-content: flex-end !important; }
    datagrid-viewer .justify-content-md-center {
      justify-content: center !important; }
    datagrid-viewer .justify-content-md-between {
      justify-content: space-between !important; }
    datagrid-viewer .justify-content-md-around {
      justify-content: space-around !important; }
    datagrid-viewer .align-items-md-start {
      align-items: flex-start !important; }
    datagrid-viewer .align-items-md-end {
      align-items: flex-end !important; }
    datagrid-viewer .align-items-md-center {
      align-items: center !important; }
    datagrid-viewer .align-items-md-baseline {
      align-items: baseline !important; }
    datagrid-viewer .align-items-md-stretch {
      align-items: stretch !important; }
    datagrid-viewer .align-content-md-start {
      align-content: flex-start !important; }
    datagrid-viewer .align-content-md-end {
      align-content: flex-end !important; }
    datagrid-viewer .align-content-md-center {
      align-content: center !important; }
    datagrid-viewer .align-content-md-between {
      align-content: space-between !important; }
    datagrid-viewer .align-content-md-around {
      align-content: space-around !important; }
    datagrid-viewer .align-content-md-stretch {
      align-content: stretch !important; }
    datagrid-viewer .align-self-md-auto {
      align-self: auto !important; }
    datagrid-viewer .align-self-md-start {
      align-self: flex-start !important; }
    datagrid-viewer .align-self-md-end {
      align-self: flex-end !important; }
    datagrid-viewer .align-self-md-center {
      align-self: center !important; }
    datagrid-viewer .align-self-md-baseline {
      align-self: baseline !important; }
    datagrid-viewer .align-self-md-stretch {
      align-self: stretch !important; } }
  @media (min-width: 992px) {
    datagrid-viewer .flex-lg-row {
      flex-direction: row !important; }
    datagrid-viewer .flex-lg-column {
      flex-direction: column !important; }
    datagrid-viewer .flex-lg-row-reverse {
      flex-direction: row-reverse !important; }
    datagrid-viewer .flex-lg-column-reverse {
      flex-direction: column-reverse !important; }
    datagrid-viewer .flex-lg-wrap {
      flex-wrap: wrap !important; }
    datagrid-viewer .flex-lg-nowrap {
      flex-wrap: nowrap !important; }
    datagrid-viewer .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    datagrid-viewer .flex-lg-fill {
      flex: 1 1 auto !important; }
    datagrid-viewer .flex-lg-grow-0 {
      flex-grow: 0 !important; }
    datagrid-viewer .flex-lg-grow-1 {
      flex-grow: 1 !important; }
    datagrid-viewer .flex-lg-shrink-0 {
      flex-shrink: 0 !important; }
    datagrid-viewer .flex-lg-shrink-1 {
      flex-shrink: 1 !important; }
    datagrid-viewer .justify-content-lg-start {
      justify-content: flex-start !important; }
    datagrid-viewer .justify-content-lg-end {
      justify-content: flex-end !important; }
    datagrid-viewer .justify-content-lg-center {
      justify-content: center !important; }
    datagrid-viewer .justify-content-lg-between {
      justify-content: space-between !important; }
    datagrid-viewer .justify-content-lg-around {
      justify-content: space-around !important; }
    datagrid-viewer .align-items-lg-start {
      align-items: flex-start !important; }
    datagrid-viewer .align-items-lg-end {
      align-items: flex-end !important; }
    datagrid-viewer .align-items-lg-center {
      align-items: center !important; }
    datagrid-viewer .align-items-lg-baseline {
      align-items: baseline !important; }
    datagrid-viewer .align-items-lg-stretch {
      align-items: stretch !important; }
    datagrid-viewer .align-content-lg-start {
      align-content: flex-start !important; }
    datagrid-viewer .align-content-lg-end {
      align-content: flex-end !important; }
    datagrid-viewer .align-content-lg-center {
      align-content: center !important; }
    datagrid-viewer .align-content-lg-between {
      align-content: space-between !important; }
    datagrid-viewer .align-content-lg-around {
      align-content: space-around !important; }
    datagrid-viewer .align-content-lg-stretch {
      align-content: stretch !important; }
    datagrid-viewer .align-self-lg-auto {
      align-self: auto !important; }
    datagrid-viewer .align-self-lg-start {
      align-self: flex-start !important; }
    datagrid-viewer .align-self-lg-end {
      align-self: flex-end !important; }
    datagrid-viewer .align-self-lg-center {
      align-self: center !important; }
    datagrid-viewer .align-self-lg-baseline {
      align-self: baseline !important; }
    datagrid-viewer .align-self-lg-stretch {
      align-self: stretch !important; } }
  @media (min-width: 1200px) {
    datagrid-viewer .flex-xl-row {
      flex-direction: row !important; }
    datagrid-viewer .flex-xl-column {
      flex-direction: column !important; }
    datagrid-viewer .flex-xl-row-reverse {
      flex-direction: row-reverse !important; }
    datagrid-viewer .flex-xl-column-reverse {
      flex-direction: column-reverse !important; }
    datagrid-viewer .flex-xl-wrap {
      flex-wrap: wrap !important; }
    datagrid-viewer .flex-xl-nowrap {
      flex-wrap: nowrap !important; }
    datagrid-viewer .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    datagrid-viewer .flex-xl-fill {
      flex: 1 1 auto !important; }
    datagrid-viewer .flex-xl-grow-0 {
      flex-grow: 0 !important; }
    datagrid-viewer .flex-xl-grow-1 {
      flex-grow: 1 !important; }
    datagrid-viewer .flex-xl-shrink-0 {
      flex-shrink: 0 !important; }
    datagrid-viewer .flex-xl-shrink-1 {
      flex-shrink: 1 !important; }
    datagrid-viewer .justify-content-xl-start {
      justify-content: flex-start !important; }
    datagrid-viewer .justify-content-xl-end {
      justify-content: flex-end !important; }
    datagrid-viewer .justify-content-xl-center {
      justify-content: center !important; }
    datagrid-viewer .justify-content-xl-between {
      justify-content: space-between !important; }
    datagrid-viewer .justify-content-xl-around {
      justify-content: space-around !important; }
    datagrid-viewer .align-items-xl-start {
      align-items: flex-start !important; }
    datagrid-viewer .align-items-xl-end {
      align-items: flex-end !important; }
    datagrid-viewer .align-items-xl-center {
      align-items: center !important; }
    datagrid-viewer .align-items-xl-baseline {
      align-items: baseline !important; }
    datagrid-viewer .align-items-xl-stretch {
      align-items: stretch !important; }
    datagrid-viewer .align-content-xl-start {
      align-content: flex-start !important; }
    datagrid-viewer .align-content-xl-end {
      align-content: flex-end !important; }
    datagrid-viewer .align-content-xl-center {
      align-content: center !important; }
    datagrid-viewer .align-content-xl-between {
      align-content: space-between !important; }
    datagrid-viewer .align-content-xl-around {
      align-content: space-around !important; }
    datagrid-viewer .align-content-xl-stretch {
      align-content: stretch !important; }
    datagrid-viewer .align-self-xl-auto {
      align-self: auto !important; }
    datagrid-viewer .align-self-xl-start {
      align-self: flex-start !important; }
    datagrid-viewer .align-self-xl-end {
      align-self: flex-end !important; }
    datagrid-viewer .align-self-xl-center {
      align-self: center !important; }
    datagrid-viewer .align-self-xl-baseline {
      align-self: baseline !important; }
    datagrid-viewer .align-self-xl-stretch {
      align-self: stretch !important; } }
  datagrid-viewer .float-left {
    float: left !important; }
  datagrid-viewer .float-right {
    float: right !important; }
  datagrid-viewer .float-none {
    float: none !important; }
  @media (min-width: 576px) {
    datagrid-viewer .float-sm-left {
      float: left !important; }
    datagrid-viewer .float-sm-right {
      float: right !important; }
    datagrid-viewer .float-sm-none {
      float: none !important; } }
  @media (min-width: 768px) {
    datagrid-viewer .float-md-left {
      float: left !important; }
    datagrid-viewer .float-md-right {
      float: right !important; }
    datagrid-viewer .float-md-none {
      float: none !important; } }
  @media (min-width: 992px) {
    datagrid-viewer .float-lg-left {
      float: left !important; }
    datagrid-viewer .float-lg-right {
      float: right !important; }
    datagrid-viewer .float-lg-none {
      float: none !important; } }
  @media (min-width: 1200px) {
    datagrid-viewer .float-xl-left {
      float: left !important; }
    datagrid-viewer .float-xl-right {
      float: right !important; }
    datagrid-viewer .float-xl-none {
      float: none !important; } }
  datagrid-viewer .user-select-all {
    user-select: all !important; }
  datagrid-viewer .user-select-auto {
    user-select: auto !important; }
  datagrid-viewer .user-select-none {
    user-select: none !important; }
  datagrid-viewer .overflow-auto {
    overflow: auto !important; }
  datagrid-viewer .overflow-hidden {
    overflow: hidden !important; }
  datagrid-viewer .position-static {
    position: static !important; }
  datagrid-viewer .position-relative {
    position: relative !important; }
  datagrid-viewer .position-absolute {
    position: absolute !important; }
  datagrid-viewer .position-fixed {
    position: fixed !important; }
  datagrid-viewer .position-sticky {
    position: sticky !important; }
  datagrid-viewer .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  datagrid-viewer .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  @supports (position: sticky) {
    datagrid-viewer .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  datagrid-viewer .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; }
  datagrid-viewer .sr-only-focusable:active, datagrid-viewer .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal; }
  datagrid-viewer .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }
  datagrid-viewer .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
  datagrid-viewer .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  datagrid-viewer .shadow-none {
    box-shadow: none !important; }
  datagrid-viewer .w-25 {
    width: 25% !important; }
  datagrid-viewer .w-50 {
    width: 50% !important; }
  datagrid-viewer .w-75 {
    width: 75% !important; }
  datagrid-viewer .w-100 {
    width: 100% !important; }
  datagrid-viewer .w-auto {
    width: auto !important; }
  datagrid-viewer .h-25 {
    height: 25% !important; }
  datagrid-viewer .h-50 {
    height: 50% !important; }
  datagrid-viewer .h-75 {
    height: 75% !important; }
  datagrid-viewer .h-100 {
    height: 100% !important; }
  datagrid-viewer .h-auto {
    height: auto !important; }
  datagrid-viewer .mw-100 {
    max-width: 100% !important; }
  datagrid-viewer .mh-100 {
    max-height: 100% !important; }
  datagrid-viewer .min-vw-100 {
    min-width: 100vw !important; }
  datagrid-viewer .min-vh-100 {
    min-height: 100vh !important; }
  datagrid-viewer .vw-100 {
    width: 100vw !important; }
  datagrid-viewer .vh-100 {
    height: 100vh !important; }
  datagrid-viewer .m-0 {
    margin: 0 !important; }
  datagrid-viewer .mt-0,
  datagrid-viewer .my-0 {
    margin-top: 0 !important; }
  datagrid-viewer .mr-0,
  datagrid-viewer .mx-0 {
    margin-right: 0 !important; }
  datagrid-viewer .mb-0,
  datagrid-viewer .my-0 {
    margin-bottom: 0 !important; }
  datagrid-viewer .ml-0,
  datagrid-viewer .mx-0 {
    margin-left: 0 !important; }
  datagrid-viewer .m-1 {
    margin: 0.25rem !important; }
  datagrid-viewer .mt-1,
  datagrid-viewer .my-1 {
    margin-top: 0.25rem !important; }
  datagrid-viewer .mr-1,
  datagrid-viewer .mx-1 {
    margin-right: 0.25rem !important; }
  datagrid-viewer .mb-1,
  datagrid-viewer .my-1 {
    margin-bottom: 0.25rem !important; }
  datagrid-viewer .ml-1,
  datagrid-viewer .mx-1 {
    margin-left: 0.25rem !important; }
  datagrid-viewer .m-2 {
    margin: 0.5rem !important; }
  datagrid-viewer .mt-2,
  datagrid-viewer .my-2 {
    margin-top: 0.5rem !important; }
  datagrid-viewer .mr-2,
  datagrid-viewer .mx-2 {
    margin-right: 0.5rem !important; }
  datagrid-viewer .mb-2,
  datagrid-viewer .my-2 {
    margin-bottom: 0.5rem !important; }
  datagrid-viewer .ml-2,
  datagrid-viewer .mx-2 {
    margin-left: 0.5rem !important; }
  datagrid-viewer .m-3 {
    margin: 1rem !important; }
  datagrid-viewer .mt-3,
  datagrid-viewer .my-3 {
    margin-top: 1rem !important; }
  datagrid-viewer .mr-3,
  datagrid-viewer .mx-3 {
    margin-right: 1rem !important; }
  datagrid-viewer .mb-3,
  datagrid-viewer .my-3 {
    margin-bottom: 1rem !important; }
  datagrid-viewer .ml-3,
  datagrid-viewer .mx-3 {
    margin-left: 1rem !important; }
  datagrid-viewer .m-4 {
    margin: 1.5rem !important; }
  datagrid-viewer .mt-4,
  datagrid-viewer .my-4 {
    margin-top: 1.5rem !important; }
  datagrid-viewer .mr-4,
  datagrid-viewer .mx-4 {
    margin-right: 1.5rem !important; }
  datagrid-viewer .mb-4,
  datagrid-viewer .my-4 {
    margin-bottom: 1.5rem !important; }
  datagrid-viewer .ml-4,
  datagrid-viewer .mx-4 {
    margin-left: 1.5rem !important; }
  datagrid-viewer .m-5 {
    margin: 3rem !important; }
  datagrid-viewer .mt-5,
  datagrid-viewer .my-5 {
    margin-top: 3rem !important; }
  datagrid-viewer .mr-5,
  datagrid-viewer .mx-5 {
    margin-right: 3rem !important; }
  datagrid-viewer .mb-5,
  datagrid-viewer .my-5 {
    margin-bottom: 3rem !important; }
  datagrid-viewer .ml-5,
  datagrid-viewer .mx-5 {
    margin-left: 3rem !important; }
  datagrid-viewer .p-0 {
    padding: 0 !important; }
  datagrid-viewer .pt-0,
  datagrid-viewer .py-0 {
    padding-top: 0 !important; }
  datagrid-viewer .pr-0,
  datagrid-viewer .px-0 {
    padding-right: 0 !important; }
  datagrid-viewer .pb-0,
  datagrid-viewer .py-0 {
    padding-bottom: 0 !important; }
  datagrid-viewer .pl-0,
  datagrid-viewer .px-0 {
    padding-left: 0 !important; }
  datagrid-viewer .p-1 {
    padding: 0.25rem !important; }
  datagrid-viewer .pt-1,
  datagrid-viewer .py-1 {
    padding-top: 0.25rem !important; }
  datagrid-viewer .pr-1,
  datagrid-viewer .px-1 {
    padding-right: 0.25rem !important; }
  datagrid-viewer .pb-1,
  datagrid-viewer .py-1 {
    padding-bottom: 0.25rem !important; }
  datagrid-viewer .pl-1,
  datagrid-viewer .px-1 {
    padding-left: 0.25rem !important; }
  datagrid-viewer .p-2 {
    padding: 0.5rem !important; }
  datagrid-viewer .pt-2,
  datagrid-viewer .py-2 {
    padding-top: 0.5rem !important; }
  datagrid-viewer .pr-2,
  datagrid-viewer .px-2 {
    padding-right: 0.5rem !important; }
  datagrid-viewer .pb-2,
  datagrid-viewer .py-2 {
    padding-bottom: 0.5rem !important; }
  datagrid-viewer .pl-2,
  datagrid-viewer .px-2 {
    padding-left: 0.5rem !important; }
  datagrid-viewer .p-3 {
    padding: 1rem !important; }
  datagrid-viewer .pt-3,
  datagrid-viewer .py-3 {
    padding-top: 1rem !important; }
  datagrid-viewer .pr-3,
  datagrid-viewer .px-3 {
    padding-right: 1rem !important; }
  datagrid-viewer .pb-3,
  datagrid-viewer .py-3 {
    padding-bottom: 1rem !important; }
  datagrid-viewer .pl-3,
  datagrid-viewer .px-3 {
    padding-left: 1rem !important; }
  datagrid-viewer .p-4 {
    padding: 1.5rem !important; }
  datagrid-viewer .pt-4,
  datagrid-viewer .py-4 {
    padding-top: 1.5rem !important; }
  datagrid-viewer .pr-4,
  datagrid-viewer .px-4 {
    padding-right: 1.5rem !important; }
  datagrid-viewer .pb-4,
  datagrid-viewer .py-4 {
    padding-bottom: 1.5rem !important; }
  datagrid-viewer .pl-4,
  datagrid-viewer .px-4 {
    padding-left: 1.5rem !important; }
  datagrid-viewer .p-5 {
    padding: 3rem !important; }
  datagrid-viewer .pt-5,
  datagrid-viewer .py-5 {
    padding-top: 3rem !important; }
  datagrid-viewer .pr-5,
  datagrid-viewer .px-5 {
    padding-right: 3rem !important; }
  datagrid-viewer .pb-5,
  datagrid-viewer .py-5 {
    padding-bottom: 3rem !important; }
  datagrid-viewer .pl-5,
  datagrid-viewer .px-5 {
    padding-left: 3rem !important; }
  datagrid-viewer .m-n1 {
    margin: -0.25rem !important; }
  datagrid-viewer .mt-n1,
  datagrid-viewer .my-n1 {
    margin-top: -0.25rem !important; }
  datagrid-viewer .mr-n1,
  datagrid-viewer .mx-n1 {
    margin-right: -0.25rem !important; }
  datagrid-viewer .mb-n1,
  datagrid-viewer .my-n1 {
    margin-bottom: -0.25rem !important; }
  datagrid-viewer .ml-n1,
  datagrid-viewer .mx-n1 {
    margin-left: -0.25rem !important; }
  datagrid-viewer .m-n2 {
    margin: -0.5rem !important; }
  datagrid-viewer .mt-n2,
  datagrid-viewer .my-n2 {
    margin-top: -0.5rem !important; }
  datagrid-viewer .mr-n2,
  datagrid-viewer .mx-n2 {
    margin-right: -0.5rem !important; }
  datagrid-viewer .mb-n2,
  datagrid-viewer .my-n2 {
    margin-bottom: -0.5rem !important; }
  datagrid-viewer .ml-n2,
  datagrid-viewer .mx-n2 {
    margin-left: -0.5rem !important; }
  datagrid-viewer .m-n3 {
    margin: -1rem !important; }
  datagrid-viewer .mt-n3,
  datagrid-viewer .my-n3 {
    margin-top: -1rem !important; }
  datagrid-viewer .mr-n3,
  datagrid-viewer .mx-n3 {
    margin-right: -1rem !important; }
  datagrid-viewer .mb-n3,
  datagrid-viewer .my-n3 {
    margin-bottom: -1rem !important; }
  datagrid-viewer .ml-n3,
  datagrid-viewer .mx-n3 {
    margin-left: -1rem !important; }
  datagrid-viewer .m-n4 {
    margin: -1.5rem !important; }
  datagrid-viewer .mt-n4,
  datagrid-viewer .my-n4 {
    margin-top: -1.5rem !important; }
  datagrid-viewer .mr-n4,
  datagrid-viewer .mx-n4 {
    margin-right: -1.5rem !important; }
  datagrid-viewer .mb-n4,
  datagrid-viewer .my-n4 {
    margin-bottom: -1.5rem !important; }
  datagrid-viewer .ml-n4,
  datagrid-viewer .mx-n4 {
    margin-left: -1.5rem !important; }
  datagrid-viewer .m-n5 {
    margin: -3rem !important; }
  datagrid-viewer .mt-n5,
  datagrid-viewer .my-n5 {
    margin-top: -3rem !important; }
  datagrid-viewer .mr-n5,
  datagrid-viewer .mx-n5 {
    margin-right: -3rem !important; }
  datagrid-viewer .mb-n5,
  datagrid-viewer .my-n5 {
    margin-bottom: -3rem !important; }
  datagrid-viewer .ml-n5,
  datagrid-viewer .mx-n5 {
    margin-left: -3rem !important; }
  datagrid-viewer .m-auto {
    margin: auto !important; }
  datagrid-viewer .mt-auto,
  datagrid-viewer .my-auto {
    margin-top: auto !important; }
  datagrid-viewer .mr-auto,
  datagrid-viewer .mx-auto {
    margin-right: auto !important; }
  datagrid-viewer .mb-auto,
  datagrid-viewer .my-auto {
    margin-bottom: auto !important; }
  datagrid-viewer .ml-auto,
  datagrid-viewer .mx-auto {
    margin-left: auto !important; }
  @media (min-width: 576px) {
    datagrid-viewer .m-sm-0 {
      margin: 0 !important; }
    datagrid-viewer .mt-sm-0,
    datagrid-viewer .my-sm-0 {
      margin-top: 0 !important; }
    datagrid-viewer .mr-sm-0,
    datagrid-viewer .mx-sm-0 {
      margin-right: 0 !important; }
    datagrid-viewer .mb-sm-0,
    datagrid-viewer .my-sm-0 {
      margin-bottom: 0 !important; }
    datagrid-viewer .ml-sm-0,
    datagrid-viewer .mx-sm-0 {
      margin-left: 0 !important; }
    datagrid-viewer .m-sm-1 {
      margin: 0.25rem !important; }
    datagrid-viewer .mt-sm-1,
    datagrid-viewer .my-sm-1 {
      margin-top: 0.25rem !important; }
    datagrid-viewer .mr-sm-1,
    datagrid-viewer .mx-sm-1 {
      margin-right: 0.25rem !important; }
    datagrid-viewer .mb-sm-1,
    datagrid-viewer .my-sm-1 {
      margin-bottom: 0.25rem !important; }
    datagrid-viewer .ml-sm-1,
    datagrid-viewer .mx-sm-1 {
      margin-left: 0.25rem !important; }
    datagrid-viewer .m-sm-2 {
      margin: 0.5rem !important; }
    datagrid-viewer .mt-sm-2,
    datagrid-viewer .my-sm-2 {
      margin-top: 0.5rem !important; }
    datagrid-viewer .mr-sm-2,
    datagrid-viewer .mx-sm-2 {
      margin-right: 0.5rem !important; }
    datagrid-viewer .mb-sm-2,
    datagrid-viewer .my-sm-2 {
      margin-bottom: 0.5rem !important; }
    datagrid-viewer .ml-sm-2,
    datagrid-viewer .mx-sm-2 {
      margin-left: 0.5rem !important; }
    datagrid-viewer .m-sm-3 {
      margin: 1rem !important; }
    datagrid-viewer .mt-sm-3,
    datagrid-viewer .my-sm-3 {
      margin-top: 1rem !important; }
    datagrid-viewer .mr-sm-3,
    datagrid-viewer .mx-sm-3 {
      margin-right: 1rem !important; }
    datagrid-viewer .mb-sm-3,
    datagrid-viewer .my-sm-3 {
      margin-bottom: 1rem !important; }
    datagrid-viewer .ml-sm-3,
    datagrid-viewer .mx-sm-3 {
      margin-left: 1rem !important; }
    datagrid-viewer .m-sm-4 {
      margin: 1.5rem !important; }
    datagrid-viewer .mt-sm-4,
    datagrid-viewer .my-sm-4 {
      margin-top: 1.5rem !important; }
    datagrid-viewer .mr-sm-4,
    datagrid-viewer .mx-sm-4 {
      margin-right: 1.5rem !important; }
    datagrid-viewer .mb-sm-4,
    datagrid-viewer .my-sm-4 {
      margin-bottom: 1.5rem !important; }
    datagrid-viewer .ml-sm-4,
    datagrid-viewer .mx-sm-4 {
      margin-left: 1.5rem !important; }
    datagrid-viewer .m-sm-5 {
      margin: 3rem !important; }
    datagrid-viewer .mt-sm-5,
    datagrid-viewer .my-sm-5 {
      margin-top: 3rem !important; }
    datagrid-viewer .mr-sm-5,
    datagrid-viewer .mx-sm-5 {
      margin-right: 3rem !important; }
    datagrid-viewer .mb-sm-5,
    datagrid-viewer .my-sm-5 {
      margin-bottom: 3rem !important; }
    datagrid-viewer .ml-sm-5,
    datagrid-viewer .mx-sm-5 {
      margin-left: 3rem !important; }
    datagrid-viewer .p-sm-0 {
      padding: 0 !important; }
    datagrid-viewer .pt-sm-0,
    datagrid-viewer .py-sm-0 {
      padding-top: 0 !important; }
    datagrid-viewer .pr-sm-0,
    datagrid-viewer .px-sm-0 {
      padding-right: 0 !important; }
    datagrid-viewer .pb-sm-0,
    datagrid-viewer .py-sm-0 {
      padding-bottom: 0 !important; }
    datagrid-viewer .pl-sm-0,
    datagrid-viewer .px-sm-0 {
      padding-left: 0 !important; }
    datagrid-viewer .p-sm-1 {
      padding: 0.25rem !important; }
    datagrid-viewer .pt-sm-1,
    datagrid-viewer .py-sm-1 {
      padding-top: 0.25rem !important; }
    datagrid-viewer .pr-sm-1,
    datagrid-viewer .px-sm-1 {
      padding-right: 0.25rem !important; }
    datagrid-viewer .pb-sm-1,
    datagrid-viewer .py-sm-1 {
      padding-bottom: 0.25rem !important; }
    datagrid-viewer .pl-sm-1,
    datagrid-viewer .px-sm-1 {
      padding-left: 0.25rem !important; }
    datagrid-viewer .p-sm-2 {
      padding: 0.5rem !important; }
    datagrid-viewer .pt-sm-2,
    datagrid-viewer .py-sm-2 {
      padding-top: 0.5rem !important; }
    datagrid-viewer .pr-sm-2,
    datagrid-viewer .px-sm-2 {
      padding-right: 0.5rem !important; }
    datagrid-viewer .pb-sm-2,
    datagrid-viewer .py-sm-2 {
      padding-bottom: 0.5rem !important; }
    datagrid-viewer .pl-sm-2,
    datagrid-viewer .px-sm-2 {
      padding-left: 0.5rem !important; }
    datagrid-viewer .p-sm-3 {
      padding: 1rem !important; }
    datagrid-viewer .pt-sm-3,
    datagrid-viewer .py-sm-3 {
      padding-top: 1rem !important; }
    datagrid-viewer .pr-sm-3,
    datagrid-viewer .px-sm-3 {
      padding-right: 1rem !important; }
    datagrid-viewer .pb-sm-3,
    datagrid-viewer .py-sm-3 {
      padding-bottom: 1rem !important; }
    datagrid-viewer .pl-sm-3,
    datagrid-viewer .px-sm-3 {
      padding-left: 1rem !important; }
    datagrid-viewer .p-sm-4 {
      padding: 1.5rem !important; }
    datagrid-viewer .pt-sm-4,
    datagrid-viewer .py-sm-4 {
      padding-top: 1.5rem !important; }
    datagrid-viewer .pr-sm-4,
    datagrid-viewer .px-sm-4 {
      padding-right: 1.5rem !important; }
    datagrid-viewer .pb-sm-4,
    datagrid-viewer .py-sm-4 {
      padding-bottom: 1.5rem !important; }
    datagrid-viewer .pl-sm-4,
    datagrid-viewer .px-sm-4 {
      padding-left: 1.5rem !important; }
    datagrid-viewer .p-sm-5 {
      padding: 3rem !important; }
    datagrid-viewer .pt-sm-5,
    datagrid-viewer .py-sm-5 {
      padding-top: 3rem !important; }
    datagrid-viewer .pr-sm-5,
    datagrid-viewer .px-sm-5 {
      padding-right: 3rem !important; }
    datagrid-viewer .pb-sm-5,
    datagrid-viewer .py-sm-5 {
      padding-bottom: 3rem !important; }
    datagrid-viewer .pl-sm-5,
    datagrid-viewer .px-sm-5 {
      padding-left: 3rem !important; }
    datagrid-viewer .m-sm-n1 {
      margin: -0.25rem !important; }
    datagrid-viewer .mt-sm-n1,
    datagrid-viewer .my-sm-n1 {
      margin-top: -0.25rem !important; }
    datagrid-viewer .mr-sm-n1,
    datagrid-viewer .mx-sm-n1 {
      margin-right: -0.25rem !important; }
    datagrid-viewer .mb-sm-n1,
    datagrid-viewer .my-sm-n1 {
      margin-bottom: -0.25rem !important; }
    datagrid-viewer .ml-sm-n1,
    datagrid-viewer .mx-sm-n1 {
      margin-left: -0.25rem !important; }
    datagrid-viewer .m-sm-n2 {
      margin: -0.5rem !important; }
    datagrid-viewer .mt-sm-n2,
    datagrid-viewer .my-sm-n2 {
      margin-top: -0.5rem !important; }
    datagrid-viewer .mr-sm-n2,
    datagrid-viewer .mx-sm-n2 {
      margin-right: -0.5rem !important; }
    datagrid-viewer .mb-sm-n2,
    datagrid-viewer .my-sm-n2 {
      margin-bottom: -0.5rem !important; }
    datagrid-viewer .ml-sm-n2,
    datagrid-viewer .mx-sm-n2 {
      margin-left: -0.5rem !important; }
    datagrid-viewer .m-sm-n3 {
      margin: -1rem !important; }
    datagrid-viewer .mt-sm-n3,
    datagrid-viewer .my-sm-n3 {
      margin-top: -1rem !important; }
    datagrid-viewer .mr-sm-n3,
    datagrid-viewer .mx-sm-n3 {
      margin-right: -1rem !important; }
    datagrid-viewer .mb-sm-n3,
    datagrid-viewer .my-sm-n3 {
      margin-bottom: -1rem !important; }
    datagrid-viewer .ml-sm-n3,
    datagrid-viewer .mx-sm-n3 {
      margin-left: -1rem !important; }
    datagrid-viewer .m-sm-n4 {
      margin: -1.5rem !important; }
    datagrid-viewer .mt-sm-n4,
    datagrid-viewer .my-sm-n4 {
      margin-top: -1.5rem !important; }
    datagrid-viewer .mr-sm-n4,
    datagrid-viewer .mx-sm-n4 {
      margin-right: -1.5rem !important; }
    datagrid-viewer .mb-sm-n4,
    datagrid-viewer .my-sm-n4 {
      margin-bottom: -1.5rem !important; }
    datagrid-viewer .ml-sm-n4,
    datagrid-viewer .mx-sm-n4 {
      margin-left: -1.5rem !important; }
    datagrid-viewer .m-sm-n5 {
      margin: -3rem !important; }
    datagrid-viewer .mt-sm-n5,
    datagrid-viewer .my-sm-n5 {
      margin-top: -3rem !important; }
    datagrid-viewer .mr-sm-n5,
    datagrid-viewer .mx-sm-n5 {
      margin-right: -3rem !important; }
    datagrid-viewer .mb-sm-n5,
    datagrid-viewer .my-sm-n5 {
      margin-bottom: -3rem !important; }
    datagrid-viewer .ml-sm-n5,
    datagrid-viewer .mx-sm-n5 {
      margin-left: -3rem !important; }
    datagrid-viewer .m-sm-auto {
      margin: auto !important; }
    datagrid-viewer .mt-sm-auto,
    datagrid-viewer .my-sm-auto {
      margin-top: auto !important; }
    datagrid-viewer .mr-sm-auto,
    datagrid-viewer .mx-sm-auto {
      margin-right: auto !important; }
    datagrid-viewer .mb-sm-auto,
    datagrid-viewer .my-sm-auto {
      margin-bottom: auto !important; }
    datagrid-viewer .ml-sm-auto,
    datagrid-viewer .mx-sm-auto {
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    datagrid-viewer .m-md-0 {
      margin: 0 !important; }
    datagrid-viewer .mt-md-0,
    datagrid-viewer .my-md-0 {
      margin-top: 0 !important; }
    datagrid-viewer .mr-md-0,
    datagrid-viewer .mx-md-0 {
      margin-right: 0 !important; }
    datagrid-viewer .mb-md-0,
    datagrid-viewer .my-md-0 {
      margin-bottom: 0 !important; }
    datagrid-viewer .ml-md-0,
    datagrid-viewer .mx-md-0 {
      margin-left: 0 !important; }
    datagrid-viewer .m-md-1 {
      margin: 0.25rem !important; }
    datagrid-viewer .mt-md-1,
    datagrid-viewer .my-md-1 {
      margin-top: 0.25rem !important; }
    datagrid-viewer .mr-md-1,
    datagrid-viewer .mx-md-1 {
      margin-right: 0.25rem !important; }
    datagrid-viewer .mb-md-1,
    datagrid-viewer .my-md-1 {
      margin-bottom: 0.25rem !important; }
    datagrid-viewer .ml-md-1,
    datagrid-viewer .mx-md-1 {
      margin-left: 0.25rem !important; }
    datagrid-viewer .m-md-2 {
      margin: 0.5rem !important; }
    datagrid-viewer .mt-md-2,
    datagrid-viewer .my-md-2 {
      margin-top: 0.5rem !important; }
    datagrid-viewer .mr-md-2,
    datagrid-viewer .mx-md-2 {
      margin-right: 0.5rem !important; }
    datagrid-viewer .mb-md-2,
    datagrid-viewer .my-md-2 {
      margin-bottom: 0.5rem !important; }
    datagrid-viewer .ml-md-2,
    datagrid-viewer .mx-md-2 {
      margin-left: 0.5rem !important; }
    datagrid-viewer .m-md-3 {
      margin: 1rem !important; }
    datagrid-viewer .mt-md-3,
    datagrid-viewer .my-md-3 {
      margin-top: 1rem !important; }
    datagrid-viewer .mr-md-3,
    datagrid-viewer .mx-md-3 {
      margin-right: 1rem !important; }
    datagrid-viewer .mb-md-3,
    datagrid-viewer .my-md-3 {
      margin-bottom: 1rem !important; }
    datagrid-viewer .ml-md-3,
    datagrid-viewer .mx-md-3 {
      margin-left: 1rem !important; }
    datagrid-viewer .m-md-4 {
      margin: 1.5rem !important; }
    datagrid-viewer .mt-md-4,
    datagrid-viewer .my-md-4 {
      margin-top: 1.5rem !important; }
    datagrid-viewer .mr-md-4,
    datagrid-viewer .mx-md-4 {
      margin-right: 1.5rem !important; }
    datagrid-viewer .mb-md-4,
    datagrid-viewer .my-md-4 {
      margin-bottom: 1.5rem !important; }
    datagrid-viewer .ml-md-4,
    datagrid-viewer .mx-md-4 {
      margin-left: 1.5rem !important; }
    datagrid-viewer .m-md-5 {
      margin: 3rem !important; }
    datagrid-viewer .mt-md-5,
    datagrid-viewer .my-md-5 {
      margin-top: 3rem !important; }
    datagrid-viewer .mr-md-5,
    datagrid-viewer .mx-md-5 {
      margin-right: 3rem !important; }
    datagrid-viewer .mb-md-5,
    datagrid-viewer .my-md-5 {
      margin-bottom: 3rem !important; }
    datagrid-viewer .ml-md-5,
    datagrid-viewer .mx-md-5 {
      margin-left: 3rem !important; }
    datagrid-viewer .p-md-0 {
      padding: 0 !important; }
    datagrid-viewer .pt-md-0,
    datagrid-viewer .py-md-0 {
      padding-top: 0 !important; }
    datagrid-viewer .pr-md-0,
    datagrid-viewer .px-md-0 {
      padding-right: 0 !important; }
    datagrid-viewer .pb-md-0,
    datagrid-viewer .py-md-0 {
      padding-bottom: 0 !important; }
    datagrid-viewer .pl-md-0,
    datagrid-viewer .px-md-0 {
      padding-left: 0 !important; }
    datagrid-viewer .p-md-1 {
      padding: 0.25rem !important; }
    datagrid-viewer .pt-md-1,
    datagrid-viewer .py-md-1 {
      padding-top: 0.25rem !important; }
    datagrid-viewer .pr-md-1,
    datagrid-viewer .px-md-1 {
      padding-right: 0.25rem !important; }
    datagrid-viewer .pb-md-1,
    datagrid-viewer .py-md-1 {
      padding-bottom: 0.25rem !important; }
    datagrid-viewer .pl-md-1,
    datagrid-viewer .px-md-1 {
      padding-left: 0.25rem !important; }
    datagrid-viewer .p-md-2 {
      padding: 0.5rem !important; }
    datagrid-viewer .pt-md-2,
    datagrid-viewer .py-md-2 {
      padding-top: 0.5rem !important; }
    datagrid-viewer .pr-md-2,
    datagrid-viewer .px-md-2 {
      padding-right: 0.5rem !important; }
    datagrid-viewer .pb-md-2,
    datagrid-viewer .py-md-2 {
      padding-bottom: 0.5rem !important; }
    datagrid-viewer .pl-md-2,
    datagrid-viewer .px-md-2 {
      padding-left: 0.5rem !important; }
    datagrid-viewer .p-md-3 {
      padding: 1rem !important; }
    datagrid-viewer .pt-md-3,
    datagrid-viewer .py-md-3 {
      padding-top: 1rem !important; }
    datagrid-viewer .pr-md-3,
    datagrid-viewer .px-md-3 {
      padding-right: 1rem !important; }
    datagrid-viewer .pb-md-3,
    datagrid-viewer .py-md-3 {
      padding-bottom: 1rem !important; }
    datagrid-viewer .pl-md-3,
    datagrid-viewer .px-md-3 {
      padding-left: 1rem !important; }
    datagrid-viewer .p-md-4 {
      padding: 1.5rem !important; }
    datagrid-viewer .pt-md-4,
    datagrid-viewer .py-md-4 {
      padding-top: 1.5rem !important; }
    datagrid-viewer .pr-md-4,
    datagrid-viewer .px-md-4 {
      padding-right: 1.5rem !important; }
    datagrid-viewer .pb-md-4,
    datagrid-viewer .py-md-4 {
      padding-bottom: 1.5rem !important; }
    datagrid-viewer .pl-md-4,
    datagrid-viewer .px-md-4 {
      padding-left: 1.5rem !important; }
    datagrid-viewer .p-md-5 {
      padding: 3rem !important; }
    datagrid-viewer .pt-md-5,
    datagrid-viewer .py-md-5 {
      padding-top: 3rem !important; }
    datagrid-viewer .pr-md-5,
    datagrid-viewer .px-md-5 {
      padding-right: 3rem !important; }
    datagrid-viewer .pb-md-5,
    datagrid-viewer .py-md-5 {
      padding-bottom: 3rem !important; }
    datagrid-viewer .pl-md-5,
    datagrid-viewer .px-md-5 {
      padding-left: 3rem !important; }
    datagrid-viewer .m-md-n1 {
      margin: -0.25rem !important; }
    datagrid-viewer .mt-md-n1,
    datagrid-viewer .my-md-n1 {
      margin-top: -0.25rem !important; }
    datagrid-viewer .mr-md-n1,
    datagrid-viewer .mx-md-n1 {
      margin-right: -0.25rem !important; }
    datagrid-viewer .mb-md-n1,
    datagrid-viewer .my-md-n1 {
      margin-bottom: -0.25rem !important; }
    datagrid-viewer .ml-md-n1,
    datagrid-viewer .mx-md-n1 {
      margin-left: -0.25rem !important; }
    datagrid-viewer .m-md-n2 {
      margin: -0.5rem !important; }
    datagrid-viewer .mt-md-n2,
    datagrid-viewer .my-md-n2 {
      margin-top: -0.5rem !important; }
    datagrid-viewer .mr-md-n2,
    datagrid-viewer .mx-md-n2 {
      margin-right: -0.5rem !important; }
    datagrid-viewer .mb-md-n2,
    datagrid-viewer .my-md-n2 {
      margin-bottom: -0.5rem !important; }
    datagrid-viewer .ml-md-n2,
    datagrid-viewer .mx-md-n2 {
      margin-left: -0.5rem !important; }
    datagrid-viewer .m-md-n3 {
      margin: -1rem !important; }
    datagrid-viewer .mt-md-n3,
    datagrid-viewer .my-md-n3 {
      margin-top: -1rem !important; }
    datagrid-viewer .mr-md-n3,
    datagrid-viewer .mx-md-n3 {
      margin-right: -1rem !important; }
    datagrid-viewer .mb-md-n3,
    datagrid-viewer .my-md-n3 {
      margin-bottom: -1rem !important; }
    datagrid-viewer .ml-md-n3,
    datagrid-viewer .mx-md-n3 {
      margin-left: -1rem !important; }
    datagrid-viewer .m-md-n4 {
      margin: -1.5rem !important; }
    datagrid-viewer .mt-md-n4,
    datagrid-viewer .my-md-n4 {
      margin-top: -1.5rem !important; }
    datagrid-viewer .mr-md-n4,
    datagrid-viewer .mx-md-n4 {
      margin-right: -1.5rem !important; }
    datagrid-viewer .mb-md-n4,
    datagrid-viewer .my-md-n4 {
      margin-bottom: -1.5rem !important; }
    datagrid-viewer .ml-md-n4,
    datagrid-viewer .mx-md-n4 {
      margin-left: -1.5rem !important; }
    datagrid-viewer .m-md-n5 {
      margin: -3rem !important; }
    datagrid-viewer .mt-md-n5,
    datagrid-viewer .my-md-n5 {
      margin-top: -3rem !important; }
    datagrid-viewer .mr-md-n5,
    datagrid-viewer .mx-md-n5 {
      margin-right: -3rem !important; }
    datagrid-viewer .mb-md-n5,
    datagrid-viewer .my-md-n5 {
      margin-bottom: -3rem !important; }
    datagrid-viewer .ml-md-n5,
    datagrid-viewer .mx-md-n5 {
      margin-left: -3rem !important; }
    datagrid-viewer .m-md-auto {
      margin: auto !important; }
    datagrid-viewer .mt-md-auto,
    datagrid-viewer .my-md-auto {
      margin-top: auto !important; }
    datagrid-viewer .mr-md-auto,
    datagrid-viewer .mx-md-auto {
      margin-right: auto !important; }
    datagrid-viewer .mb-md-auto,
    datagrid-viewer .my-md-auto {
      margin-bottom: auto !important; }
    datagrid-viewer .ml-md-auto,
    datagrid-viewer .mx-md-auto {
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    datagrid-viewer .m-lg-0 {
      margin: 0 !important; }
    datagrid-viewer .mt-lg-0,
    datagrid-viewer .my-lg-0 {
      margin-top: 0 !important; }
    datagrid-viewer .mr-lg-0,
    datagrid-viewer .mx-lg-0 {
      margin-right: 0 !important; }
    datagrid-viewer .mb-lg-0,
    datagrid-viewer .my-lg-0 {
      margin-bottom: 0 !important; }
    datagrid-viewer .ml-lg-0,
    datagrid-viewer .mx-lg-0 {
      margin-left: 0 !important; }
    datagrid-viewer .m-lg-1 {
      margin: 0.25rem !important; }
    datagrid-viewer .mt-lg-1,
    datagrid-viewer .my-lg-1 {
      margin-top: 0.25rem !important; }
    datagrid-viewer .mr-lg-1,
    datagrid-viewer .mx-lg-1 {
      margin-right: 0.25rem !important; }
    datagrid-viewer .mb-lg-1,
    datagrid-viewer .my-lg-1 {
      margin-bottom: 0.25rem !important; }
    datagrid-viewer .ml-lg-1,
    datagrid-viewer .mx-lg-1 {
      margin-left: 0.25rem !important; }
    datagrid-viewer .m-lg-2 {
      margin: 0.5rem !important; }
    datagrid-viewer .mt-lg-2,
    datagrid-viewer .my-lg-2 {
      margin-top: 0.5rem !important; }
    datagrid-viewer .mr-lg-2,
    datagrid-viewer .mx-lg-2 {
      margin-right: 0.5rem !important; }
    datagrid-viewer .mb-lg-2,
    datagrid-viewer .my-lg-2 {
      margin-bottom: 0.5rem !important; }
    datagrid-viewer .ml-lg-2,
    datagrid-viewer .mx-lg-2 {
      margin-left: 0.5rem !important; }
    datagrid-viewer .m-lg-3 {
      margin: 1rem !important; }
    datagrid-viewer .mt-lg-3,
    datagrid-viewer .my-lg-3 {
      margin-top: 1rem !important; }
    datagrid-viewer .mr-lg-3,
    datagrid-viewer .mx-lg-3 {
      margin-right: 1rem !important; }
    datagrid-viewer .mb-lg-3,
    datagrid-viewer .my-lg-3 {
      margin-bottom: 1rem !important; }
    datagrid-viewer .ml-lg-3,
    datagrid-viewer .mx-lg-3 {
      margin-left: 1rem !important; }
    datagrid-viewer .m-lg-4 {
      margin: 1.5rem !important; }
    datagrid-viewer .mt-lg-4,
    datagrid-viewer .my-lg-4 {
      margin-top: 1.5rem !important; }
    datagrid-viewer .mr-lg-4,
    datagrid-viewer .mx-lg-4 {
      margin-right: 1.5rem !important; }
    datagrid-viewer .mb-lg-4,
    datagrid-viewer .my-lg-4 {
      margin-bottom: 1.5rem !important; }
    datagrid-viewer .ml-lg-4,
    datagrid-viewer .mx-lg-4 {
      margin-left: 1.5rem !important; }
    datagrid-viewer .m-lg-5 {
      margin: 3rem !important; }
    datagrid-viewer .mt-lg-5,
    datagrid-viewer .my-lg-5 {
      margin-top: 3rem !important; }
    datagrid-viewer .mr-lg-5,
    datagrid-viewer .mx-lg-5 {
      margin-right: 3rem !important; }
    datagrid-viewer .mb-lg-5,
    datagrid-viewer .my-lg-5 {
      margin-bottom: 3rem !important; }
    datagrid-viewer .ml-lg-5,
    datagrid-viewer .mx-lg-5 {
      margin-left: 3rem !important; }
    datagrid-viewer .p-lg-0 {
      padding: 0 !important; }
    datagrid-viewer .pt-lg-0,
    datagrid-viewer .py-lg-0 {
      padding-top: 0 !important; }
    datagrid-viewer .pr-lg-0,
    datagrid-viewer .px-lg-0 {
      padding-right: 0 !important; }
    datagrid-viewer .pb-lg-0,
    datagrid-viewer .py-lg-0 {
      padding-bottom: 0 !important; }
    datagrid-viewer .pl-lg-0,
    datagrid-viewer .px-lg-0 {
      padding-left: 0 !important; }
    datagrid-viewer .p-lg-1 {
      padding: 0.25rem !important; }
    datagrid-viewer .pt-lg-1,
    datagrid-viewer .py-lg-1 {
      padding-top: 0.25rem !important; }
    datagrid-viewer .pr-lg-1,
    datagrid-viewer .px-lg-1 {
      padding-right: 0.25rem !important; }
    datagrid-viewer .pb-lg-1,
    datagrid-viewer .py-lg-1 {
      padding-bottom: 0.25rem !important; }
    datagrid-viewer .pl-lg-1,
    datagrid-viewer .px-lg-1 {
      padding-left: 0.25rem !important; }
    datagrid-viewer .p-lg-2 {
      padding: 0.5rem !important; }
    datagrid-viewer .pt-lg-2,
    datagrid-viewer .py-lg-2 {
      padding-top: 0.5rem !important; }
    datagrid-viewer .pr-lg-2,
    datagrid-viewer .px-lg-2 {
      padding-right: 0.5rem !important; }
    datagrid-viewer .pb-lg-2,
    datagrid-viewer .py-lg-2 {
      padding-bottom: 0.5rem !important; }
    datagrid-viewer .pl-lg-2,
    datagrid-viewer .px-lg-2 {
      padding-left: 0.5rem !important; }
    datagrid-viewer .p-lg-3 {
      padding: 1rem !important; }
    datagrid-viewer .pt-lg-3,
    datagrid-viewer .py-lg-3 {
      padding-top: 1rem !important; }
    datagrid-viewer .pr-lg-3,
    datagrid-viewer .px-lg-3 {
      padding-right: 1rem !important; }
    datagrid-viewer .pb-lg-3,
    datagrid-viewer .py-lg-3 {
      padding-bottom: 1rem !important; }
    datagrid-viewer .pl-lg-3,
    datagrid-viewer .px-lg-3 {
      padding-left: 1rem !important; }
    datagrid-viewer .p-lg-4 {
      padding: 1.5rem !important; }
    datagrid-viewer .pt-lg-4,
    datagrid-viewer .py-lg-4 {
      padding-top: 1.5rem !important; }
    datagrid-viewer .pr-lg-4,
    datagrid-viewer .px-lg-4 {
      padding-right: 1.5rem !important; }
    datagrid-viewer .pb-lg-4,
    datagrid-viewer .py-lg-4 {
      padding-bottom: 1.5rem !important; }
    datagrid-viewer .pl-lg-4,
    datagrid-viewer .px-lg-4 {
      padding-left: 1.5rem !important; }
    datagrid-viewer .p-lg-5 {
      padding: 3rem !important; }
    datagrid-viewer .pt-lg-5,
    datagrid-viewer .py-lg-5 {
      padding-top: 3rem !important; }
    datagrid-viewer .pr-lg-5,
    datagrid-viewer .px-lg-5 {
      padding-right: 3rem !important; }
    datagrid-viewer .pb-lg-5,
    datagrid-viewer .py-lg-5 {
      padding-bottom: 3rem !important; }
    datagrid-viewer .pl-lg-5,
    datagrid-viewer .px-lg-5 {
      padding-left: 3rem !important; }
    datagrid-viewer .m-lg-n1 {
      margin: -0.25rem !important; }
    datagrid-viewer .mt-lg-n1,
    datagrid-viewer .my-lg-n1 {
      margin-top: -0.25rem !important; }
    datagrid-viewer .mr-lg-n1,
    datagrid-viewer .mx-lg-n1 {
      margin-right: -0.25rem !important; }
    datagrid-viewer .mb-lg-n1,
    datagrid-viewer .my-lg-n1 {
      margin-bottom: -0.25rem !important; }
    datagrid-viewer .ml-lg-n1,
    datagrid-viewer .mx-lg-n1 {
      margin-left: -0.25rem !important; }
    datagrid-viewer .m-lg-n2 {
      margin: -0.5rem !important; }
    datagrid-viewer .mt-lg-n2,
    datagrid-viewer .my-lg-n2 {
      margin-top: -0.5rem !important; }
    datagrid-viewer .mr-lg-n2,
    datagrid-viewer .mx-lg-n2 {
      margin-right: -0.5rem !important; }
    datagrid-viewer .mb-lg-n2,
    datagrid-viewer .my-lg-n2 {
      margin-bottom: -0.5rem !important; }
    datagrid-viewer .ml-lg-n2,
    datagrid-viewer .mx-lg-n2 {
      margin-left: -0.5rem !important; }
    datagrid-viewer .m-lg-n3 {
      margin: -1rem !important; }
    datagrid-viewer .mt-lg-n3,
    datagrid-viewer .my-lg-n3 {
      margin-top: -1rem !important; }
    datagrid-viewer .mr-lg-n3,
    datagrid-viewer .mx-lg-n3 {
      margin-right: -1rem !important; }
    datagrid-viewer .mb-lg-n3,
    datagrid-viewer .my-lg-n3 {
      margin-bottom: -1rem !important; }
    datagrid-viewer .ml-lg-n3,
    datagrid-viewer .mx-lg-n3 {
      margin-left: -1rem !important; }
    datagrid-viewer .m-lg-n4 {
      margin: -1.5rem !important; }
    datagrid-viewer .mt-lg-n4,
    datagrid-viewer .my-lg-n4 {
      margin-top: -1.5rem !important; }
    datagrid-viewer .mr-lg-n4,
    datagrid-viewer .mx-lg-n4 {
      margin-right: -1.5rem !important; }
    datagrid-viewer .mb-lg-n4,
    datagrid-viewer .my-lg-n4 {
      margin-bottom: -1.5rem !important; }
    datagrid-viewer .ml-lg-n4,
    datagrid-viewer .mx-lg-n4 {
      margin-left: -1.5rem !important; }
    datagrid-viewer .m-lg-n5 {
      margin: -3rem !important; }
    datagrid-viewer .mt-lg-n5,
    datagrid-viewer .my-lg-n5 {
      margin-top: -3rem !important; }
    datagrid-viewer .mr-lg-n5,
    datagrid-viewer .mx-lg-n5 {
      margin-right: -3rem !important; }
    datagrid-viewer .mb-lg-n5,
    datagrid-viewer .my-lg-n5 {
      margin-bottom: -3rem !important; }
    datagrid-viewer .ml-lg-n5,
    datagrid-viewer .mx-lg-n5 {
      margin-left: -3rem !important; }
    datagrid-viewer .m-lg-auto {
      margin: auto !important; }
    datagrid-viewer .mt-lg-auto,
    datagrid-viewer .my-lg-auto {
      margin-top: auto !important; }
    datagrid-viewer .mr-lg-auto,
    datagrid-viewer .mx-lg-auto {
      margin-right: auto !important; }
    datagrid-viewer .mb-lg-auto,
    datagrid-viewer .my-lg-auto {
      margin-bottom: auto !important; }
    datagrid-viewer .ml-lg-auto,
    datagrid-viewer .mx-lg-auto {
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    datagrid-viewer .m-xl-0 {
      margin: 0 !important; }
    datagrid-viewer .mt-xl-0,
    datagrid-viewer .my-xl-0 {
      margin-top: 0 !important; }
    datagrid-viewer .mr-xl-0,
    datagrid-viewer .mx-xl-0 {
      margin-right: 0 !important; }
    datagrid-viewer .mb-xl-0,
    datagrid-viewer .my-xl-0 {
      margin-bottom: 0 !important; }
    datagrid-viewer .ml-xl-0,
    datagrid-viewer .mx-xl-0 {
      margin-left: 0 !important; }
    datagrid-viewer .m-xl-1 {
      margin: 0.25rem !important; }
    datagrid-viewer .mt-xl-1,
    datagrid-viewer .my-xl-1 {
      margin-top: 0.25rem !important; }
    datagrid-viewer .mr-xl-1,
    datagrid-viewer .mx-xl-1 {
      margin-right: 0.25rem !important; }
    datagrid-viewer .mb-xl-1,
    datagrid-viewer .my-xl-1 {
      margin-bottom: 0.25rem !important; }
    datagrid-viewer .ml-xl-1,
    datagrid-viewer .mx-xl-1 {
      margin-left: 0.25rem !important; }
    datagrid-viewer .m-xl-2 {
      margin: 0.5rem !important; }
    datagrid-viewer .mt-xl-2,
    datagrid-viewer .my-xl-2 {
      margin-top: 0.5rem !important; }
    datagrid-viewer .mr-xl-2,
    datagrid-viewer .mx-xl-2 {
      margin-right: 0.5rem !important; }
    datagrid-viewer .mb-xl-2,
    datagrid-viewer .my-xl-2 {
      margin-bottom: 0.5rem !important; }
    datagrid-viewer .ml-xl-2,
    datagrid-viewer .mx-xl-2 {
      margin-left: 0.5rem !important; }
    datagrid-viewer .m-xl-3 {
      margin: 1rem !important; }
    datagrid-viewer .mt-xl-3,
    datagrid-viewer .my-xl-3 {
      margin-top: 1rem !important; }
    datagrid-viewer .mr-xl-3,
    datagrid-viewer .mx-xl-3 {
      margin-right: 1rem !important; }
    datagrid-viewer .mb-xl-3,
    datagrid-viewer .my-xl-3 {
      margin-bottom: 1rem !important; }
    datagrid-viewer .ml-xl-3,
    datagrid-viewer .mx-xl-3 {
      margin-left: 1rem !important; }
    datagrid-viewer .m-xl-4 {
      margin: 1.5rem !important; }
    datagrid-viewer .mt-xl-4,
    datagrid-viewer .my-xl-4 {
      margin-top: 1.5rem !important; }
    datagrid-viewer .mr-xl-4,
    datagrid-viewer .mx-xl-4 {
      margin-right: 1.5rem !important; }
    datagrid-viewer .mb-xl-4,
    datagrid-viewer .my-xl-4 {
      margin-bottom: 1.5rem !important; }
    datagrid-viewer .ml-xl-4,
    datagrid-viewer .mx-xl-4 {
      margin-left: 1.5rem !important; }
    datagrid-viewer .m-xl-5 {
      margin: 3rem !important; }
    datagrid-viewer .mt-xl-5,
    datagrid-viewer .my-xl-5 {
      margin-top: 3rem !important; }
    datagrid-viewer .mr-xl-5,
    datagrid-viewer .mx-xl-5 {
      margin-right: 3rem !important; }
    datagrid-viewer .mb-xl-5,
    datagrid-viewer .my-xl-5 {
      margin-bottom: 3rem !important; }
    datagrid-viewer .ml-xl-5,
    datagrid-viewer .mx-xl-5 {
      margin-left: 3rem !important; }
    datagrid-viewer .p-xl-0 {
      padding: 0 !important; }
    datagrid-viewer .pt-xl-0,
    datagrid-viewer .py-xl-0 {
      padding-top: 0 !important; }
    datagrid-viewer .pr-xl-0,
    datagrid-viewer .px-xl-0 {
      padding-right: 0 !important; }
    datagrid-viewer .pb-xl-0,
    datagrid-viewer .py-xl-0 {
      padding-bottom: 0 !important; }
    datagrid-viewer .pl-xl-0,
    datagrid-viewer .px-xl-0 {
      padding-left: 0 !important; }
    datagrid-viewer .p-xl-1 {
      padding: 0.25rem !important; }
    datagrid-viewer .pt-xl-1,
    datagrid-viewer .py-xl-1 {
      padding-top: 0.25rem !important; }
    datagrid-viewer .pr-xl-1,
    datagrid-viewer .px-xl-1 {
      padding-right: 0.25rem !important; }
    datagrid-viewer .pb-xl-1,
    datagrid-viewer .py-xl-1 {
      padding-bottom: 0.25rem !important; }
    datagrid-viewer .pl-xl-1,
    datagrid-viewer .px-xl-1 {
      padding-left: 0.25rem !important; }
    datagrid-viewer .p-xl-2 {
      padding: 0.5rem !important; }
    datagrid-viewer .pt-xl-2,
    datagrid-viewer .py-xl-2 {
      padding-top: 0.5rem !important; }
    datagrid-viewer .pr-xl-2,
    datagrid-viewer .px-xl-2 {
      padding-right: 0.5rem !important; }
    datagrid-viewer .pb-xl-2,
    datagrid-viewer .py-xl-2 {
      padding-bottom: 0.5rem !important; }
    datagrid-viewer .pl-xl-2,
    datagrid-viewer .px-xl-2 {
      padding-left: 0.5rem !important; }
    datagrid-viewer .p-xl-3 {
      padding: 1rem !important; }
    datagrid-viewer .pt-xl-3,
    datagrid-viewer .py-xl-3 {
      padding-top: 1rem !important; }
    datagrid-viewer .pr-xl-3,
    datagrid-viewer .px-xl-3 {
      padding-right: 1rem !important; }
    datagrid-viewer .pb-xl-3,
    datagrid-viewer .py-xl-3 {
      padding-bottom: 1rem !important; }
    datagrid-viewer .pl-xl-3,
    datagrid-viewer .px-xl-3 {
      padding-left: 1rem !important; }
    datagrid-viewer .p-xl-4 {
      padding: 1.5rem !important; }
    datagrid-viewer .pt-xl-4,
    datagrid-viewer .py-xl-4 {
      padding-top: 1.5rem !important; }
    datagrid-viewer .pr-xl-4,
    datagrid-viewer .px-xl-4 {
      padding-right: 1.5rem !important; }
    datagrid-viewer .pb-xl-4,
    datagrid-viewer .py-xl-4 {
      padding-bottom: 1.5rem !important; }
    datagrid-viewer .pl-xl-4,
    datagrid-viewer .px-xl-4 {
      padding-left: 1.5rem !important; }
    datagrid-viewer .p-xl-5 {
      padding: 3rem !important; }
    datagrid-viewer .pt-xl-5,
    datagrid-viewer .py-xl-5 {
      padding-top: 3rem !important; }
    datagrid-viewer .pr-xl-5,
    datagrid-viewer .px-xl-5 {
      padding-right: 3rem !important; }
    datagrid-viewer .pb-xl-5,
    datagrid-viewer .py-xl-5 {
      padding-bottom: 3rem !important; }
    datagrid-viewer .pl-xl-5,
    datagrid-viewer .px-xl-5 {
      padding-left: 3rem !important; }
    datagrid-viewer .m-xl-n1 {
      margin: -0.25rem !important; }
    datagrid-viewer .mt-xl-n1,
    datagrid-viewer .my-xl-n1 {
      margin-top: -0.25rem !important; }
    datagrid-viewer .mr-xl-n1,
    datagrid-viewer .mx-xl-n1 {
      margin-right: -0.25rem !important; }
    datagrid-viewer .mb-xl-n1,
    datagrid-viewer .my-xl-n1 {
      margin-bottom: -0.25rem !important; }
    datagrid-viewer .ml-xl-n1,
    datagrid-viewer .mx-xl-n1 {
      margin-left: -0.25rem !important; }
    datagrid-viewer .m-xl-n2 {
      margin: -0.5rem !important; }
    datagrid-viewer .mt-xl-n2,
    datagrid-viewer .my-xl-n2 {
      margin-top: -0.5rem !important; }
    datagrid-viewer .mr-xl-n2,
    datagrid-viewer .mx-xl-n2 {
      margin-right: -0.5rem !important; }
    datagrid-viewer .mb-xl-n2,
    datagrid-viewer .my-xl-n2 {
      margin-bottom: -0.5rem !important; }
    datagrid-viewer .ml-xl-n2,
    datagrid-viewer .mx-xl-n2 {
      margin-left: -0.5rem !important; }
    datagrid-viewer .m-xl-n3 {
      margin: -1rem !important; }
    datagrid-viewer .mt-xl-n3,
    datagrid-viewer .my-xl-n3 {
      margin-top: -1rem !important; }
    datagrid-viewer .mr-xl-n3,
    datagrid-viewer .mx-xl-n3 {
      margin-right: -1rem !important; }
    datagrid-viewer .mb-xl-n3,
    datagrid-viewer .my-xl-n3 {
      margin-bottom: -1rem !important; }
    datagrid-viewer .ml-xl-n3,
    datagrid-viewer .mx-xl-n3 {
      margin-left: -1rem !important; }
    datagrid-viewer .m-xl-n4 {
      margin: -1.5rem !important; }
    datagrid-viewer .mt-xl-n4,
    datagrid-viewer .my-xl-n4 {
      margin-top: -1.5rem !important; }
    datagrid-viewer .mr-xl-n4,
    datagrid-viewer .mx-xl-n4 {
      margin-right: -1.5rem !important; }
    datagrid-viewer .mb-xl-n4,
    datagrid-viewer .my-xl-n4 {
      margin-bottom: -1.5rem !important; }
    datagrid-viewer .ml-xl-n4,
    datagrid-viewer .mx-xl-n4 {
      margin-left: -1.5rem !important; }
    datagrid-viewer .m-xl-n5 {
      margin: -3rem !important; }
    datagrid-viewer .mt-xl-n5,
    datagrid-viewer .my-xl-n5 {
      margin-top: -3rem !important; }
    datagrid-viewer .mr-xl-n5,
    datagrid-viewer .mx-xl-n5 {
      margin-right: -3rem !important; }
    datagrid-viewer .mb-xl-n5,
    datagrid-viewer .my-xl-n5 {
      margin-bottom: -3rem !important; }
    datagrid-viewer .ml-xl-n5,
    datagrid-viewer .mx-xl-n5 {
      margin-left: -3rem !important; }
    datagrid-viewer .m-xl-auto {
      margin: auto !important; }
    datagrid-viewer .mt-xl-auto,
    datagrid-viewer .my-xl-auto {
      margin-top: auto !important; }
    datagrid-viewer .mr-xl-auto,
    datagrid-viewer .mx-xl-auto {
      margin-right: auto !important; }
    datagrid-viewer .mb-xl-auto,
    datagrid-viewer .my-xl-auto {
      margin-bottom: auto !important; }
    datagrid-viewer .ml-xl-auto,
    datagrid-viewer .mx-xl-auto {
      margin-left: auto !important; } }
  datagrid-viewer .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0); }
  datagrid-viewer .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }
  datagrid-viewer .text-justify {
    text-align: justify !important; }
  datagrid-viewer .text-wrap {
    white-space: normal !important; }
  datagrid-viewer .text-nowrap {
    white-space: nowrap !important; }
  datagrid-viewer .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  datagrid-viewer .text-left {
    text-align: left !important; }
  datagrid-viewer .text-right {
    text-align: right !important; }
  datagrid-viewer .text-center {
    text-align: center !important; }
  @media (min-width: 576px) {
    datagrid-viewer .text-sm-left {
      text-align: left !important; }
    datagrid-viewer .text-sm-right {
      text-align: right !important; }
    datagrid-viewer .text-sm-center {
      text-align: center !important; } }
  @media (min-width: 768px) {
    datagrid-viewer .text-md-left {
      text-align: left !important; }
    datagrid-viewer .text-md-right {
      text-align: right !important; }
    datagrid-viewer .text-md-center {
      text-align: center !important; } }
  @media (min-width: 992px) {
    datagrid-viewer .text-lg-left {
      text-align: left !important; }
    datagrid-viewer .text-lg-right {
      text-align: right !important; }
    datagrid-viewer .text-lg-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    datagrid-viewer .text-xl-left {
      text-align: left !important; }
    datagrid-viewer .text-xl-right {
      text-align: right !important; }
    datagrid-viewer .text-xl-center {
      text-align: center !important; } }
  datagrid-viewer .text-lowercase {
    text-transform: lowercase !important; }
  datagrid-viewer .text-uppercase {
    text-transform: uppercase !important; }
  datagrid-viewer .text-capitalize {
    text-transform: capitalize !important; }
  datagrid-viewer .font-weight-light {
    font-weight: 300 !important; }
  datagrid-viewer .font-weight-lighter {
    font-weight: lighter !important; }
  datagrid-viewer .font-weight-normal {
    font-weight: 400 !important; }
  datagrid-viewer .font-weight-bold {
    font-weight: 700 !important; }
  datagrid-viewer .font-weight-bolder {
    font-weight: bolder !important; }
  datagrid-viewer .font-italic {
    font-style: italic !important; }
  datagrid-viewer .text-white {
    color: #fff !important; }
  datagrid-viewer .text-primary {
    color: #007bff !important; }
  datagrid-viewer a.text-primary:hover, datagrid-viewer a.text-primary:focus {
    color: #0056b3 !important; }
  datagrid-viewer .text-secondary {
    color: #6c757d !important; }
  datagrid-viewer a.text-secondary:hover, datagrid-viewer a.text-secondary:focus {
    color: #494f54 !important; }
  datagrid-viewer .text-success {
    color: #28a745 !important; }
  datagrid-viewer a.text-success:hover, datagrid-viewer a.text-success:focus {
    color: #19692c !important; }
  datagrid-viewer .text-info {
    color: #17a2b8 !important; }
  datagrid-viewer a.text-info:hover, datagrid-viewer a.text-info:focus {
    color: #0f6674 !important; }
  datagrid-viewer .text-warning {
    color: #ffc107 !important; }
  datagrid-viewer a.text-warning:hover, datagrid-viewer a.text-warning:focus {
    color: #ba8b00 !important; }
  datagrid-viewer .text-danger {
    color: #dc3545 !important; }
  datagrid-viewer a.text-danger:hover, datagrid-viewer a.text-danger:focus {
    color: #a71d2a !important; }
  datagrid-viewer .text-light {
    color: #f8f9fa !important; }
  datagrid-viewer a.text-light:hover, datagrid-viewer a.text-light:focus {
    color: #cbd3da !important; }
  datagrid-viewer .text-dark {
    color: #343a40 !important; }
  datagrid-viewer a.text-dark:hover, datagrid-viewer a.text-dark:focus {
    color: #121416 !important; }
  datagrid-viewer .text-body {
    color: #212529 !important; }
  datagrid-viewer .text-muted {
    color: #6c757d !important; }
  datagrid-viewer .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important; }
  datagrid-viewer .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }
  datagrid-viewer .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }
  datagrid-viewer .text-decoration-none {
    text-decoration: none !important; }
  datagrid-viewer .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important; }
  datagrid-viewer .text-reset {
    color: inherit !important; }
  datagrid-viewer .visible {
    visibility: visible !important; }
  datagrid-viewer .invisible {
    visibility: hidden !important; }
  @media print {
    datagrid-viewer *,
    datagrid-viewer *::before,
    datagrid-viewer *::after {
      text-shadow: none !important;
      box-shadow: none !important; }
    datagrid-viewer a:not(.btn) {
      text-decoration: underline; }
    datagrid-viewer abbr[title]::after {
      content: " (" attr(title) ")"; }
    datagrid-viewer pre {
      white-space: pre-wrap !important; }
    datagrid-viewer pre,
    datagrid-viewer blockquote {
      border: 1px solid #adb5bd;
      page-break-inside: avoid; }
    datagrid-viewer thead {
      display: table-header-group; }
    datagrid-viewer tr,
    datagrid-viewer img {
      page-break-inside: avoid; }
    datagrid-viewer p,
    datagrid-viewer h2,
    datagrid-viewer h3 {
      orphans: 3;
      widows: 3; }
    datagrid-viewer h2,
    datagrid-viewer h3 {
      page-break-after: avoid; }
    @page {
      datagrid-viewer {
        size: a3; } }
    datagrid-viewer body {
      min-width: 992px !important; }
    datagrid-viewer .container {
      min-width: 992px !important; }
    datagrid-viewer .navbar {
      display: none; }
    datagrid-viewer .badge {
      border: 1px solid #000; }
    datagrid-viewer .table {
      border-collapse: collapse !important; }
      datagrid-viewer .table td,
      datagrid-viewer .table th {
        background-color: #fff !important; }
    datagrid-viewer .table-bordered th,
    datagrid-viewer .table-bordered td {
      border: 1px solid #dee2e6 !important; }
    datagrid-viewer .table-dark {
      color: inherit; }
      datagrid-viewer .table-dark th,
      datagrid-viewer .table-dark td,
      datagrid-viewer .table-dark thead th,
      datagrid-viewer .table-dark tbody + tbody {
        border-color: #dee2e6; }
    datagrid-viewer .table .thead-dark th {
      color: inherit;
      border-color: #dee2e6; } }
  datagrid-viewer .ngx-datatable {
    display: block;
    overflow: hidden;
    justify-content: center;
    position: relative;
    transform: translate3d(0, 0, 0);
    /**
   * Vertical Scrolling Adjustments
   */
    /**
   * Horizontal Scrolling Adjustments
   */
    /**
   * Fixed Header Height Adjustments
   */
    /**
   * Fixed row height adjustments
   */
    /**
   * Shared Styles
   */
    /**
   * Header Styles
   */
    /**
   * Body Styles
   */
    /**
   * Footer Styles
   */ }
    datagrid-viewer .ngx-datatable [hidden] {
      display: none !important; }
    datagrid-viewer .ngx-datatable *,
    datagrid-viewer .ngx-datatable *:before,
    datagrid-viewer .ngx-datatable *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
    datagrid-viewer .ngx-datatable.scroll-vertical .datatable-body {
      overflow-y: auto; }
    datagrid-viewer .ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
      position: absolute; }
    datagrid-viewer .ngx-datatable.scroll-horz .datatable-body {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
    datagrid-viewer .ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
      white-space: nowrap; }
      datagrid-viewer .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    datagrid-viewer .ngx-datatable.fixed-row .datatable-scroll {
      white-space: nowrap; }
      datagrid-viewer .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
        white-space: nowrap; }
        datagrid-viewer .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
        datagrid-viewer .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
    datagrid-viewer .ngx-datatable .datatable-body-row,
    datagrid-viewer .ngx-datatable .datatable-row-center,
    datagrid-viewer .ngx-datatable .datatable-header-inner {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: row;
      -webkit-flex-flow: row;
      -moz-flex-flow: row;
      -ms-flex-flow: row;
      -o-flex-flow: row;
      flex-flow: row; }
    datagrid-viewer .ngx-datatable .datatable-body-cell,
    datagrid-viewer .ngx-datatable .datatable-header-cell {
      overflow-x: hidden;
      vertical-align: top;
      display: inline-block;
      line-height: 1.625; }
      datagrid-viewer .ngx-datatable .datatable-body-cell:focus,
      datagrid-viewer .ngx-datatable .datatable-header-cell:focus {
        outline: none; }
    datagrid-viewer .ngx-datatable .datatable-row-left,
    datagrid-viewer .ngx-datatable .datatable-row-right {
      z-index: 9; }
    datagrid-viewer .ngx-datatable .datatable-row-left,
    datagrid-viewer .ngx-datatable .datatable-row-center,
    datagrid-viewer .ngx-datatable .datatable-row-group,
    datagrid-viewer .ngx-datatable .datatable-row-right {
      position: relative; }
    datagrid-viewer .ngx-datatable .datatable-header {
      display: block;
      overflow: hidden; }
      datagrid-viewer .ngx-datatable .datatable-header .datatable-header-inner {
        align-items: stretch;
        -webkit-align-items: stretch; }
      datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell {
        position: relative;
        display: inline-block; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
          cursor: pointer; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
          cursor: move; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
          line-height: 100%;
          vertical-align: middle;
          display: inline-block;
          cursor: pointer; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
          display: inline-block;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 5px;
          padding: 0 4px;
          visibility: hidden; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
          cursor: ew-resize; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
          visibility: visible; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
          visibility: visible; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
          position: absolute;
          top: 0;
          bottom: 0; }
          datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
            right: 0; }
          datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
            left: 0; }
        datagrid-viewer .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
          height: inherit; }
    datagrid-viewer .ngx-datatable .datatable-body {
      position: relative;
      z-index: 10;
      display: block; }
      datagrid-viewer .ngx-datatable .datatable-body .datatable-scroll {
        display: inline-block; }
      datagrid-viewer .ngx-datatable .datatable-body .datatable-row-detail {
        overflow-y: hidden; }
      datagrid-viewer .ngx-datatable .datatable-body .datatable-row-wrapper {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column; }
      datagrid-viewer .ngx-datatable .datatable-body .datatable-body-row {
        outline: none; }
        datagrid-viewer .ngx-datatable .datatable-body .datatable-body-row > div {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex; }
    datagrid-viewer .ngx-datatable .datatable-footer {
      display: block;
      width: 100%;
      overflow: auto; }
      datagrid-viewer .ngx-datatable .datatable-footer .datatable-footer-inner {
        display: flex;
        align-items: center;
        width: 100%; }
      datagrid-viewer .ngx-datatable .datatable-footer .selected-count .page-count {
        flex: 1 1 40%; }
      datagrid-viewer .ngx-datatable .datatable-footer .selected-count .datatable-pager {
        flex: 1 1 60%; }
      datagrid-viewer .ngx-datatable .datatable-footer .page-count {
        flex: 1 1 20%; }
      datagrid-viewer .ngx-datatable .datatable-footer .datatable-pager {
        flex: 1 1 80%;
        text-align: right; }
        datagrid-viewer .ngx-datatable .datatable-footer .datatable-pager .pager,
        datagrid-viewer .ngx-datatable .datatable-footer .datatable-pager .pager li {
          padding: 0;
          margin: 0;
          display: inline-block;
          list-style: none; }
        datagrid-viewer .ngx-datatable .datatable-footer .datatable-pager .pager li,
        datagrid-viewer .ngx-datatable .datatable-footer .datatable-pager .pager li a {
          outline: none; }
        datagrid-viewer .ngx-datatable .datatable-footer .datatable-pager .pager li a {
          cursor: pointer;
          display: inline-block; }
        datagrid-viewer .ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
          cursor: not-allowed; }
  datagrid-viewer .ngx-datatable.bootstrap {
    box-shadow: none;
    font-size: 13px; }
    datagrid-viewer .ngx-datatable.bootstrap .datatable-header {
      height: unset !important; }
      datagrid-viewer .ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
        vertical-align: bottom;
        padding: 0.75rem;
        border-bottom: 1px solid #d1d4d7; }
        datagrid-viewer .ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
          line-height: 24px; }
    datagrid-viewer .ngx-datatable.bootstrap .datatable-body .datatable-body-row {
      vertical-align: top;
      border-top: 1px solid #d1d4d7; }
      datagrid-viewer .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
        background-color: rgba(0, 0, 0, 0.05); }
      datagrid-viewer .ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
        background-color: #1483ff;
        color: #fff; }
      datagrid-viewer .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
        padding: 0.75rem;
        text-align: left;
        vertical-align: top; }
    datagrid-viewer .ngx-datatable.bootstrap .datatable-body .empty-row {
      position: relative;
      padding: 0.75rem 1.25rem;
      margin-bottom: 0; }
    datagrid-viewer .ngx-datatable.bootstrap .datatable-footer {
      background: #424242;
      color: #ededed;
      margin-top: -1px; }
      datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0 1.2rem; }
      datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager {
        margin: 0 10px;
        vertical-align: top; }
        datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
          margin: 10px 0px; }
          datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
            background-color: #545454;
            font-weight: bold; }
        datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
          height: 22px;
          min-width: 24px;
          line-height: 22px;
          padding: 0;
          border-radius: 3px;
          margin: 0 3px;
          text-align: center;
          vertical-align: top;
          text-decoration: none;
          vertical-align: bottom;
          color: #ededed; }
        datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left,
        datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip,
        datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right,
        datagrid-viewer .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
          font-size: 18px;
          line-height: 27px;
          padding: 0 3px; }
    datagrid-viewer .ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row .datatable-body-cell {
      font-weight: bold; }
  datagrid-viewer * button {
    font-size: 14px; }
  datagrid-viewer html {
    height: 100%;
    box-sizing: border-box;
    background-color: rgba(204, 204, 204, 0.219);
    max-height: 100vh; }
  datagrid-viewer *,
  datagrid-viewer *:before,
  datagrid-viewer *:after {
    box-sizing: inherit; }
  datagrid-viewer html,
  datagrid-viewer body {
    margin: 0;
    display: block;
    height: 100%;
    font-family: "Helvetica Neue", Arial, sans-serif;
    max-height: 100vh;
    overflow: auto; }
  datagrid-viewer .panel-open .fb-row {
    border: 0;
    padding: 0;
    margin: 0; }
  datagrid-viewer .resize-handle {
    border-right: 1px solid #ccc;
    opacity: 0.5;
    display: block !important;
    visibility: initial !important; }
  datagrid-viewer .ngx-datatable.bootstrap .datatable-body .datatable-body-row:hover {
    background: #ccc; }
    datagrid-viewer .ngx-datatable.bootstrap .datatable-body .datatable-body-row:hover.active {
      background: #6693c6; }
  datagrid-viewer .pager li a[role="button"],
  datagrid-viewer .pager li[role="button"] a {
    background: none !important;
    outline: none !important;
    border: none !important; }
    datagrid-viewer .pager li a[role="button"]:focus,
    datagrid-viewer .pager li[role="button"] a:focus {
      background: none !important;
      outline: none !important;
      border: none !important; }
  datagrid-viewer .grid-renderer {
    margin: 5px; }
    datagrid-viewer .grid-renderer .controlBox {
      width: 100%;
      display: flex;
      flex: 0 50%;
      flex-flow: row;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 5px; }
      datagrid-viewer .grid-renderer .controlBox .searchBox {
        align-self: flex-end;
        display: inline-flex;
        padding: 0 5px 0 5px; }
        datagrid-viewer .grid-renderer .controlBox .searchBox input {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          datagrid-viewer .grid-renderer .controlBox .searchBox input:focus {
            outline: none;
            box-shadow: none;
            border-color: #ced4da; }
        datagrid-viewer .grid-renderer .controlBox .searchBox .input-group-append button {
          margin-left: 3px; }
          datagrid-viewer .grid-renderer .controlBox .searchBox .input-group-append button.classic {
            border-color: #ced4da !important;
            margin-left: -3px !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-left: 0 !important;
            background: transparent !important; }
            datagrid-viewer .grid-renderer .controlBox .searchBox .input-group-append button.classic:hover {
              border-color: #cccccc !important;
              color: black !important; }
      datagrid-viewer .grid-renderer .controlBox .buttonsBox {
        align-self: flex-start;
        display: inline-flex;
        padding: 0 5px 0 5px; }
        datagrid-viewer .grid-renderer .controlBox .buttonsBox button {
          margin-right: 5px; }
    datagrid-viewer .grid-renderer .page-size-container {
      width: 200px;
      display: inline-block; }
      datagrid-viewer .grid-renderer .page-size-container select {
        color: black !important; }
    datagrid-viewer .grid-renderer .page-count {
      display: inline-block;
      position: relative; }
    datagrid-viewer .grid-renderer .vertical-separator-container {
      position: relative;
      height: 50%;
      width: 25px;
      display: inline-block; }
    datagrid-viewer .grid-renderer .vertical-separator {
      background: #cccccc;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 30%;
      bottom: 25%; }
    datagrid-viewer .grid-renderer .button-container {
      display: inline-block;
      margin-right: 3px; }
    datagrid-viewer .grid-renderer .icon {
      position: absolute; }
    datagrid-viewer .grid-renderer .datatable-icon-down {
      top: 0px; }
    datagrid-viewer .grid-renderer .datatable-icon-up {
      top: 40px; }
    datagrid-viewer .grid-renderer .dragFromLeft .icon {
      left: -13px; }
  datagrid-viewer .controlBox .btn {
    border: 1px solid !important; }

.query-container {
  margin: 10px; }

.query-group {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  padding: 0; }
  .query-group .child-group {
    padding: 2px 0 0 20px;
    width: 100%; }
    .query-group .child-group .shroud {
      position: relative;
      border-top: 1px solid rgba(204, 204, 204, 0.507);
      border-bottom: 1px solid rgba(204, 204, 204, 0.507);
      padding-left: 5px; }
      .query-group .child-group .shroud .border-left {
        position: absolute;
        background-color: rgba(204, 204, 204, 0.507);
        top: -1px;
        left: -15px;
        bottom: -1px;
        width: 15px; }

.query .form-controls {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding: 5px 0;
  position: relative;
  width: 100%; }
  .query .form-controls > * {
    padding: 0 0 0 2px;
    margin: 0 2px;
    flex: 1 1 auto; }
    .query .form-controls > *:first-child {
      margin-left: 20px; }

.query .addon {
  position: absolute;
  z-index: 101;
  top: 5px;
  left: -5px; }
  .query .addon i {
    padding: 0 2px; }

.buttons {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px; }

button {
  margin: 0 3px; }

.scrollbarH .datatable-body {
  overflow-x: auto; }

.scrollbarV .datatable-body {
  overflow-y: auto; }
