@media screen and (min-width: 768px) {
  .registration-form {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  .registration-form > form {
    flex: 1 1 auto;
    padding: 1.25rem;
    min-height: 200px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}


.registration-form {
  margin-bottom: 20px;
  .col {
    min-width: inherit !important;
    display: inline-block;
  }
  .section {
    padding: 15px;
    border: 1px solid #ddd;
    margin: 0 0 5px;
    background-color: #f7f7f7;
  }

  .title {
    padding: 0;
    margin: -5px 0 15px;
    display: block;
    position: relative;
    color: #90a4ae;
    border-bottom: 1px solid #90a4ae;
  }
}
