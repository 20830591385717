@for $i from 1 through 5 {
  .cmx-#{$i} {
    margin-right: (1px * $i);
    margin-left: (1px * $i);
  }
  .cmy-#{$i} {
    margin-top: (1px * $i);
    margin-bottom: (1px * $i);
  }
  .cmr-#{$i} {
    margin-right: (1px * $i);
  }
  .cml-#{$i} {
    margin-left: (1px * $i);
  }
  .cmb-#{$i} {
    margin-bottom: (1px * $i);
  }
  .cmt-#{$i} {
    margin-top: (1px * $i);
  }
  .cm-#{$i} {
    margin: (1px * $i);
  }
  .cpx-#{$i} {
    padding-right: (1px * $i);
    padding-left: (1px * $i);
  }
  .cpy-#{$i} {
    padding-top: (1px * $i);
    padding-bottom: (1px * $i);
  }
  .cpr-#{$i} {
    padding-right: (1px * $i);
  }
  .cpl-#{$i} {
    padding-left: (1px * $i);
  }
  .cpb-#{$i} {
    padding-bottom: (1px * $i);
  }
  .cpt-#{$i} {
    padding-top: (1px * $i);
  }
  .cp-#{$i} {
    padding: (1px * $i);
  }
  .mx-#{$i} {
    margin-right: (1px * $i) !important;
    margin-left: (1px * $i) !important;
  }
  .my-#{$i} {
    margin-top: (1px * $i) !important;
    margin-bottom: (1px * $i) !important;
  }
  .mr-#{$i} {
    margin-right: (1px * $i) !important;
  }
  .ml-#{$i} {
    margin-left: (1px * $i) !important;
  }
  .mb-#{$i} {
    margin-bottom: (1px * $i) !important;
  }
  .mt-#{$i} {
    margin-top: (1px * $i) !important;
  }
  .m-#{$i} {
    margin: (1px * $i) !important;
  }
  .px-#{$i} {
    padding-right: (1px * $i) !important;
    padding-left: (1px * $i) !important;
  }
  .py-#{$i} {
    padding-top: (1px * $i) !important;
    padding-bottom: (1px * $i) !important;
  }
  .pr-#{$i} {
    padding-right: (1px * $i) !important;
  }
  .pl-#{$i} {
    padding-left: (1px * $i) !important;
  }
  .pb-#{$i} {
    padding-bottom: (1px * $i) !important;
  }
  .pt-#{$i} {
    padding-top: (1px * $i) !important;
  }
  .p-#{$i} {
    padding: (1px * $i) !important;
  }
}